import { axiosInstance } from '../config'

//添加地址
export const getdoctorlist = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/docterlist', data)
}

//修改问诊设置
export const changeset = (data) => {
    return axiosInstance.post('/api/hosp/wzdocter/consultation-service', data)
}

// 查询医生接诊次数
export const getDocjznum = (docterId) => {
    return axiosInstance.get(`/api/hosp/wzpatient/get-docjznum?docterId=${docterId}`)
}
// 查询医生当日接诊次数	
export const getDayDocjznum = (docterId) => {
    return axiosInstance.get(`/api/hosp/wzpatient/get-day-docjznum?docterId=${docterId}`)
}
//修改ca状态
export const updateCa = (data) => {
    return axiosInstance.post('/api/hosp/scca/update-ca', data)
}
// 获取其他签名
export const getSignature = (data) => {
    return axiosInstance.post('/faas/hosp/scca/get-signature', data)
}
//获取ca签名
export const caWrite = (data) => {
    return axiosInstance.post('/api/hosp/Consultation/ca-write', data)

}
// 查询药师审方数量
export const getCount = (data) => {
    return axiosInstance.post('/api/hosp/wzpharmacist/get-count', data)
}