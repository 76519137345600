
import { axiosInstance } from '../config'

//获取科室列表
export const getdepartmemntlist = (data) => {
    return axiosInstance.post('/api/hosp/Consultation/department-list', data)
}
// 查询一级科室	
export const queryDepartment = (data) => {
    return axiosInstance.post('/api/hosp/doctor_manage/query-department', data)
}
// 查询二级科室	
export const ejDepartment = (data) => {
    return axiosInstance.post('/api/hosp/doctor_manage/ej-department', data)

}