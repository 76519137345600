import { get, post,axiosInstance } from '../config'
import { supervise_data,supervise_url,postSupervise,} from '../config3'
//药师入驻申请
export const uploaddoctor = (data) => {
    return axiosInstance.post('/api/hosp/wzpharmacist/qualifications', data)
}
//图片上传
export const uploadpic = (data) => {
    return axiosInstance.post('/api/hosp/upload/picture', data)
}
//ca认证
export const caAuthentication = (data) => {
    return axiosInstance.post('/faas/hosp/scca/authentication', data)
}
// 云证书查询	/faas/hosp/scca/testca
export const testca = (data) => {
    return axiosInstance.post('/faas/hosp/scca/testca', data)
}
//删除签章重新签名
export const delResign = (data) => {
    return axiosInstance.post('/faas/hosp/scca/del', data)
}
//请求回调接口
export const cacallback = (data) => {
    return axiosInstance.post('/faas/hosp/scca/callback', data)
}
//查询ca通过时间
export const caPasstime = (docterId) => {
    return axiosInstance.get(`/api/hosp/scca/ca-pass-time?docterId=${docterId}`)
}
export const getToken = () => postSupervise('/wjw/third/oauth/getAccessToken', {clientId:supervise_data.clientId,appSecret:supervise_data.appSecret,});
export const addToken = (params) => post('/api/hosp/supervision-platform/add-token', params);
export const tokenSupervision = (params) => get('/api/hosp/supervision-platform/get-token', params);
//网络诊疗服务数据上传
export const uploadFurtherConsult = (params) => postSupervise('https://202.61.88.184:19200/wjw/upload/uploadFurtherConsult', params);
//电子处方服务数据上传
export const getPrescribingDate = (params) =>  get('/api/hosp/supervision-platform/get-prescribing-date', params);
export const uploadRecipe = (params) => postSupervise('https://202.61.88.184:19200/wjw/upload/uploadRecipe', params);
export const updateEPrescribing = (params) =>  post('/api/hosp/supervision-platform/update-e_prescribing', params);
//更新上传处方状态
export const updateEprescribing = (data) => {
    return axiosInstance.post('/api/hosp/supervision-platform/update-e_prescribing', data)
}


// 新增药店药师签名
export const addPhaMenber = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/add-phamenber', data)
}

//药店药师入驻申请
export const uploadphator = (data) => {
    return axiosInstance.post('/api/hosp/wzpharmacist/pha-settle-apply', data)
}

// 药店药师签名新增
export const addPhaSign = (data) => {
    return axiosInstance.post('/api/hosp/wzpharmacist/add-pha-sign', data)
}

// 药店药师签名修改
export const updatePhaSign = (data) => {
    return axiosInstance.post('/api/hosp/wzpharmacist/update-pha-sign', data)
}