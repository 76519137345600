import React, { Component } from 'react'
import "./style.less"
import { Tabs } from "antd-mobile-v5"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/doctor/delete.png"
import Nodata from '../../../component/Nodata'
import {allEprescribing, deletecf} from '../../../api/hospitalapi/mine'
import {getuserinfo} from "../../../api/hospitalapi/login";
import {Modal} from "antd-mobile";
const alert = Modal.alert
export default class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            yslist: [],
            phonelist:[],
            docter: '',
            docterId:localStorage.getItem("docterId")
        }
    }
    componentDidMount = async () => {
        const { docterId } = this.state
        if (docterId) {
            this.allHistorylist()
        }
    }
    allHistorylist=async()=>{
        const { docterId } = this.state
        const res = await allEprescribing({
            docterId
        })

        if (res&&res.data&&res.data[0]){
            this.setState({
                yslist: res.data[0]
            })
        }
    }
    gobank=()=>{
        window.history.back()
    }
    toDelete=async (prescribingId)=>{
        alert('确认删除吗？', '', [
            { text: '取消', onPress: () => console.log('cancel') },
            {
                text: '确认', onPress: () => {
                    deletecf({prescribingId}).then(res=>{
                        if (res&&res.code==1){
                            this.allHistorylist()
                        }
                    })
                }
            },
        ])

    }
    render() {
        const { yslist,phonelist } = this.state
        return (
            <div className='recordbox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={this.gobank} /><h1>接诊记录</h1>
                </div>
                <div className='box-content'>
                    <Tabs>
                        <Tabs.Tab title='图文' key='pic'>
                            {yslist.length?yslist.map(item=>(<div className='content-item'>
                                <div>
                                    <p>就诊人：</p><p><span>{item.patientName}</span><span style={{marginLeft:'10px'}}>{item.patientSex}</span><span style={{marginLeft:'10px'}}>{item.patientAge}岁</span></p>
                                </div>
                                <div>
                                    <p>主诉病情：</p><p><span>{item.patientIllness}</span></p>
                                </div>
                                <div>
                                    <p>病情时间：</p><p><span>1天</span></p>
                                </div>
                                <div>
                                    <p>结束时间：</p><p><span>{item.createTime}</span></p>
                                </div>
                                <img src={icon2} alt="" className='item-delete' onClick={()=>this.toDelete(item.prescribingId)} />
                            </div>)):<Nodata type='接诊'/>}
                        </Tabs.Tab>
                        <Tabs.Tab title='电话' key='phone'>
                            {phonelist.length?phonelist.map(item=>(<div className='content-item'>
                                <div>
                                    <p>就诊人：</p><p><span>用户13721</span><span>女</span><span>25岁</span></p>
                                </div>
                                <div>
                                    <p>主诉病情：</p><p><span>肚子疼</span></p>
                                </div>
                                <div>
                                    <p>病情时间：</p><p><span>1天</span></p>
                                </div>
                                <div>
                                    <p>结束时间：</p><p><span>2020年12月31日  19:28:27</span></p>
                                </div>
                                <img src={icon2} alt="" className='item-delete' />
                            </div>)):<Nodata type='接诊'/>}
                        </Tabs.Tab>
                    </Tabs>
                </div>
            </div>
        )
    }
}
