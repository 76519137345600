import React, { Component } from 'react'
import { Modal,  } from "antd-mobile-v5"
import { Toast } from "antd-mobile"
import "./style.less"
import icon1 from "../../static/img/fanhuihei.png"

export default class index extends Component {
    constructor() {
        super()
        this.state = {

        }
    }
    componentDidMount = () => {

    }
    render() {
        return (
            <div className='setbox' style={{backgroundColor:'#fff'}}>
                <div className='box-top box-top1'>
                    <img src={icon1} alt="" onClick={() => window.history.back()} />   软件使用许可协议
                </div>
                {/*<h2 className='risk-title'>平台隐私政策</h2>*/}
                <p className='risk-body'>
                    <p>本软件许可使用协议（以下称"本协议"）由您与云益健互联网医院有限公司（以下称“我们”）共同签署。</p>
                    <p>在使用“药师端”软件（以下称许可软件）之前，请仔细阅读本协议。一旦您下载安装使用许可软件，即表示您同意接受本协议所有条款和条件的约束。如您不同意本协议条款和条件，请勿使用许可软件，并请销毁所有许可软件副本。</p>
                    <p>我们有权根据实际运营的情况对本协议不时进行调整，并以平台公告或电子邮件等书面形式通知您。修改后的协议于公示或邮件送达时生效。协议条款修改后，您继续使用许可软件的，即视为您已阅读并接受修改后的协议。</p>
                    <p>一、定义</p>
                    <p>1.药师端软件：是指由云益健互联网医院开发的，供您从公众号平台（以下称公众号）进入，并仅限在手机等手持移动终端（以下称移动终端）中安装、使用的软件系统。</p>
                    <p>2.软件服务：由云益健互联网医院有限公司为您提供的服务。您可以通过许可软件在手持移动终端使用健康服务。</p>
                    <p>二、授权范围</p>
                    <p>1.您仅可在移动终端中使用许可软件。未经“我们”书面同意，您不得将许可软件安装在其他终端设备上，该等设备包括但不限于台式电脑、手提电脑、移动终端外的手持移动终端、电视机及机顶盒。</p>
                    <p>2.您可以为非商业目的在一台移动终端上安装、使用许可软件，但您不得为商业运营目的安装、使用、运行许可软件，也不得对许可软件或者许可软件运行过程中释放在任何移动终端中的数据及许可软件运行过程中移动终端与服务器端的交互数据进行复制、更改、修改、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经授权的第三方工具/服务接入许可软件和相关系统。</p>
                    <p>3.我们有权在任何时候自行决定对许可软件及其相关功能进行变更、升级、修改或转移，并有权在许可软件系统中开发新的功能或其它服务。除非附有独立的协议，否则上述所有新的功能、软件服务的提供仍适用本协议。</p>
                    <p>4.您需要凭您在注册药师端账号时的手机进行登录。您应妥善保护您的验证码，并对其使用或遗失自行承担责任。如果您的账号和密码存在未获授权的使用，或者发生其他任何安全问题时，应立即通知我们。我们对因上述情形产生的后果不承担任何责任。</p>
                    <p>5.许可软件由您进入公众号注册，您应遵守软件公司对您使用许可软件方式与限制的约定，一旦软件公司确认您违反该约定的，我们有权终止对您的使用许可。</p>
                    <p>6.我们基于本协议许可而非向您销售许可软件，您仅可依照本协议约定使用许可软件。我们保留未明示授予的一切权利。</p>
                    <p>三、使用规范</p>
                    <p>1.您不得通过以下方式使用许可软件：</p>
                    <p>1)从事违法法律法规政策、破坏公序良俗、损害公共利益的行为。</p>
                    <p>2)对许可软件的相关信息擅自出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点，借助许可软件发展与之有关的衍生产品、作品、服务、插件、外挂、兼容、互联等。</p>
                    <p>3)通过非由云益健互联网医院及其关联公司开发、授权或认可的第三方兼容软件、系统登录或使用许可软件，或针对许可软件使用非及其关联公司开发、授权或认证的插件和外挂。</p>
                    <p>4)删除许可软件及其他副本上关于版权的信息、内容。修改、删除或避开应用产品中我们为保护知识产权而设置的任何技术措施。</p>
                    <p>5)未经云益健互联网医院的书面同意，擅自将许可软件出租、出借或再许可给第三方使用，或在获得许可软件的升级版本的许可使用后，同时使用多个版本的许可使用版本，或分开转让。</p>
                    <p>6)复制、反汇编、修改许可软件或其任何部分或制造其衍生作品。</p>
                    <p>7)进行任何危害信息网络安全的行为，包括使用许可软件时以任何方式损坏或破坏许可软件或使其不能运行或超负荷或干扰第三方对许可软件的使用；未经允许进入他人计算机系统并删除、修改、增加存储信息；故意传播恶意程序或病毒以及其他破坏、干扰正常网络信息服务的行为。</p>
                    <p>8)利用许可软件发表、传送、传播、储存侵害他人知识产权、商业秘密权等合法权利的内容，或从事欺诈、盗用他人账户、资金等违法犯罪活动。</p>
                    <p>9)通过修改或伪造许可软件运行中的指令、数据、数据包，增加、删减、变动许可软件的功能或运行效果，或将具有上述用途的软件通过信息网络向公众传播或者运营。</p>
                    <p>10)其他以任何不合法的方式、为任何不合法的目的、或以任何与本协议不一致的方式使用许可软件。</p>
                    <p>2.您了解并同意</p>
                    <p>1)云益健互联网医院有权对您是否涉嫌违反上述使用规范做出单方认定，并根据单方认定结果中止、终止对您的使用许可或采取其他依约可采取的限制措施，且无须征得您的同意或提前通知予您。</p>
                    <p>2)对于您使用许可软件时发布的涉嫌违法或涉嫌侵犯他人合法权利或违反本协议的信息，云益健互联网医院有权不经通知您即予以删除。</p>
                    <p>3)对于您涉嫌违反上述使用规范的行为对任意第三方造成损害的，您均应当以自己的名义独立承担所有的法律责任，并应确保云益健互联网医院免于因此产生损失或增加费用。</p>
                    <p>4)如您涉嫌违反有关法律或者本协议之规定，使云益健互联网受任何损失，或受到任何第三方的索赔，或受到任何行政管理部门的处罚，您应当赔偿云益健互联网医院因此造成的损失及（或）发生的费用，包括合理的律师费用、调查取证费用。</p>
                    <p>四、第三方软件或服务</p>
                    <p>1.许可软件可能使用或包含了由第三方提供的软件或服务（以下称该等服务），该等服务是为了向您提供便利而设置，是取得该第三方的合法授权的。本协议并不授予您使用该等服务的任何权利，也不对该等服务提供任何形式的保证。</p>
                    <p>2.您使用该等服务时，应另行与该第三方达成服务协议，支付相应费用并承担可能的风险。云益健互联网医院不对该等服务提供任何形式的客户支持，如果您需要获取支持，请直接与该第三方联系。因您使用该等服务引发的任何纠纷，由您与该第三方负责解决，云益健互联网医院不承担任何责任。</p>
                    <p>3.许可软件仅在当前使用或包含该等服务，云益健互联网医院并不保证许可软件将会永久地使用或包含该等服务，也不保证将来不会使用或包含该第三方的同类型或不同类型的软件或服务或其他第三方的软件或服务，一旦云益健互联网医院在许可软件中使用或包含前述软件或服务，相应的软件或服务同样适用本条约定。</p>
                    <p>4.您在使用许可软件的同时该等服务的，您即授权云益健互联网医院将您使用许可软件的信息传递给该第三方，或从该第三方获取您注册或使用该等服务时提供或产生的信息。</p>
                    <p>5.您同意，你是否遵循与该第三方约定使用该等服务，是您是否善意履行本协议的条件之一，如果该第三方确认您违反了您与其之间关于使用该等服务的协议约定，云益健互联网医院有权不需就相关确认与您核实即中止、终止对你的使用许可或采取其他云益健互联网医院可对您采取的限制措施，且无需承担任何责任。</p>
                    <p>五、许可使用费</p>
                    <p>1.云益健互联网医院将根据市场与技术的发展，向您提供与许可软件的各种增值服务。就许可软件及/或增值服务，云益健互联网医院保留收取许可使用费的权利。在开始收费前，云益健互联网医院将以适当的方式通知您具体收费标准，包括但不限于在云益健互联网医院官网（www.yyj-health.com）上公示。您有权选择接受或拒绝收费，如果您选择拒绝的，应立即停止使用许可软件及/或增值服务。</p>
                    <p>2.您在云益健互联网医院通知前免费的试用许可软件，不应视为云益健互联网医院放弃收费的权利。云益健互联网医院有权通知您后收取相应费用。</p>
                    <p>六、隐私政策与数据</p>
                    <p>1.您同意，本协议所指个人隐私信息是指那些能够对您的身份进行有效辨识或涉及到您个人通信的信息，包括您的姓名、身份证号、手机号码、IP地址、UDID等，但对于您使用许可软件的状态、设备号等一些反映在云益健互联网医院服务器端的信息不属此列。</p>
                    <p>2.许可软件不含有任何破坏您苹果、安卓终端数据和获取您隐私信息的恶意代码，不会监控您使用苹果或安卓终端的行为或泄漏您的个人隐私信息。</p>
                    <p>3.对于您使用许可软件所提供、形成的您的个人隐私信息，会予以保密。除非本协议另有约定，云益健互联网医院将不会在不通知的情况下透露您的个人隐私信息。</p>
                    <p>4.为以下目的，您授权云益健互联网医院披露您的个人隐私信息：</p>
                    <p>a)遵守法律法规或遵照司法部门对产品所要求的法律程序；</p>
                    <p>b)保护和维护云益健互联网医院及其关联公司的合法权益；</p>
                    <p>c)在紧急情况下保护云益健互联网医院及其用户或社会大众的安全。</p>
                    <p>5.本协议终止后，云益健互联网医院有权继续保留您的信息，但云益健互联网医没有义务以任何形式向您提供该等信息。</p>
                    <p>七、特别授权</p>
                    <p>您完全理解并不可撤销地授予云益健互联网医院及其关联公司下列权利：</p>
                    <p>1.对于使用许可软件时提供的资料及数据信息，您授予云益健互联网医院及其关联公司独家的、全球通用的、永久的、免费的许可使用权利(并有权在多个层面对该权利进行再授权)。此外，云益健互联网医院及其关联公司有权(全部或部份地)使用、复制、修订、改写、发布、翻译、分发、执行和展示您的全部资料数据或制作其派生作品，并以现在已知或日后开发的任何形式、媒体或技术，将上述信息纳入其它作品内。</p>
                    <p>2.一旦您向云益健互联网医院作出任何形式的承诺，且相关公司已确认您违反了该承诺，则云益健互联网医院有权立即按您的承诺或协议约定的方式对您的使用许可及其他云益健互联网医院可控制的权益采取限制措施，包括中止或终止对您的使用许可，并公示相关公司确认的您的违约情况。您了解并同意，云益健互联网医院无须就相关确认与您核对事实，或另行征得您的同意，且云益健互联网医院无须就此限制措施或公示行为向您承担任何的责任。</p>
                    <p>3.一旦您违反本协议，或与云益健互联网医院签订的其他协议的约定，云益健互联网医院有权以任何方式通知云益健互联网医院关联公司，要求其对您的权益采取限制措施，包括但不限于要求关联公司中止、终止对您提供部分或全部服务，且在其经营或实际控制的任何网站公示您的违约情况。</p>
                    <p>4.许可软件在必要时有权使用您的移动终端处理器和无线网络等资源，用作容许其它许可软件使用者与您联络等目的。此项同意可能会影响您的使用感受和带来不可预知的风险，您同意自行承担该等风险。</p>
                    <p>八、无担保和责任限制</p>
                    <p>1.您明确同意，您需独自承担使用许可软件产生的全部风险。</p>
                    <p>2.除法律法规有明确规定外，许可软件“按现状”和“可得到”的状态提供，云益健互联网医院对许可软件不作任何明示或暗示的保证，包括但不限于许可软件的适用性、没有错误或疏漏、持续性、准确性、可靠性、适用于某一特定用途。同时，云益健互联网医院也不对许可软件所涉及的技术及信息的有效性、准确性、正确性、可靠性、质量、稳定、完整和及时性作出任何承诺和保证。</p>
                    <p>3.云益健互联网医院不就电讯系统或手机网络的中断或无法运作、技术故障、计算机错误或病毒、信息损坏或丢失或其它在云益健互联网医院合理控制范围之外的原因而产生的其他任何性质的破坏而向您或任何第三方承担赔偿责任。</p>
                    <p>4.使用许可软件涉及到无线网络服务，会受到各个环节不稳定因素的影响，包括但不限于因不可抗力、黑客攻击、系统不稳定、您所处位置、您的移动终端处于关闭状态、信息内容非法以及其他任何网络、技术、通信线路、信息安全管理措施等原因造成许可软件无法正常使用，您同意自行承担以上风险，云益健互联网医院不承担任何责任。</p>
                    <p>5.在任何情况下，由于您因下述任一情况所引起或与此有关的任何人身伤害或任何附带的、特别的、间接的或后果性的损害赔偿，包括但不限于利润损失、资料损失、业务中断的损害赔偿或任何其它商业损害赔偿或损失，无论因何原因产生，无论是否知悉该等损失可能发生，云益健互联网医院均无须为此承担任何责任：</p>
                    <p>1)使用或未能使用许可软件；</p>
                    <p>2)第三方未经批准的使用许可软件或更改您的数据；</p>
                    <p>3)使用许可软件进行的行为产生的费用及损失；</p>
                    <p>4)您对许可软件的误解；</p>
                    <p>5)任何非因云益健互联网医院的原因而引起的与许可软件有关的其它损失。</p>
                    <p>6.您同意，如因云益健互联网医院原因，造成许可软件不正常中断或其它技术故障，您所可能获得的最高赔偿额为不超过本协议项下云益健互联网医院已实际收取的相关软件使用许可费总额。</p>
                    <p>7.非经云益健互联网医院或云益健互联网医院授权开发并正式发布的其它任何由许可软件衍生的软件均属非法，下载、安装、使用此类软件，可能导致不可预知的风险，由此产生的一切法律责任与纠纷与云益健互联网医院无关，云益健互联网医院并有权中止、终止使用许可和其他一切服务。</p>
                    <p>8.您与其他使用许可软件的用户之间通过许可软件进行时，因您受误导或欺骗而导致或可能导致的任何心理、生理上的伤害以及经济上的损失，应由过错方依法承担所有责任，一概与云益健互联网医院无关。</p>
                    <p>九、知识产权</p>
                    <p>1.云益健互联网医院拥有许可软件的著作权、商业秘密以及其他相关的知识产权，包括与许可软件有关的各种文档资料。</p>
                    <p>2.许可软件的相关标识属于云益健互联网医院及其关联公司的知识产权，并受到相关法律法规的保护。未经云益健互联网医院明确授权，您不得复制、模仿、使用或发布上述标识，也不得修改或删除应用产品中体现云益健互联网医院及其关联公司的任何标识或身份信息。</p>
                    <p>3.未经云益健互联网医院及其关联公司事先书面同意，您不得为任何营利性或非营利性的目的自行实施、利用、转让或许可任何第三方实施、利用、转让上述知识产权。</p>
                    <p>十、协议终止和违约责任</p>
                    <p>1.如您违反本协议中的任何条款，本协议将自动终止，云益健互联网医院无需另行通知您。</p>
                    <p>2.您违反云益健互联网医院或其关联公司网站的条款、协议、规则、通告等相关规定，而被上述任一网站终止提供服务的，云益健互联网医院有权终止本协议。</p>
                    <p>3.在本协议终止时，您应停止对许可软件的任何使用行为，并销毁许可软件的全部副本。</p>
                    <p>4.如您违反本协议规定的任何条款，给云益健互联网医院或其他用户造成损失，您必须承担全部的赔偿责任。如云益健互联网医院承担了上述责任，则您同意赔偿云益健互联网医院的相关支出和损失，包括合理的律师费用。</p>
                    <p>十一、管辖法律和可分割性</p>
                    <p>1.本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律，如无相关法律规定的，则应参照通用国际商业惯例和（或）行业惯例。</p>
                    <p>2.因本协议产生的任何争议，您应与云益健互联网医院以友好协商的方式予以解决；经协商无法最终解决的，应向云益健互联网医院所在地有管辖权的人民法院提请裁决。</p>
                    <p>3.本协议任何条款被有管辖权的人民法院裁定为无效，不应影响其他条款或其任何部分的效力，您与云益健互联网医院仍应善意履行。</p>
                    <p>十二、其他</p>
                    <p>1.云益健互联网医院有权将本协议项下的权利义务转让给第三方，而无需征得您的同意。</p>
                    <p>2.本协议构成双方有关许可软件许可使用的全部协议，并取代之前与此相关所有谅解。</p>
                    <p>3.任何对本协议的修订，均应以书面方式，并获得您与云益健互联网医院的一致同意。</p>
                    <p>4.本协议的所有标题仅仅是为了醒目及阅读方便，本身并没有实际涵义，不能作为解释本协议涵义的依据。</p>
                </p>
            </div>
        )
    }
}