
import React, {useState, useEffect, Component} from 'react'

import Head from '../../../component/Head'
import close from '../../../static/img/close1.png'
import './style.less'
import { aftersaleList,aftersaleMedList,stateList} from '../../../api/request/aftersale'
import { oderdetailRequest,oderlistRequest} from '../../../api/request/order'
import {getuserinfo} from "../../../api/hospitalapi/login";
import {jumpUrl} from "../../../publicurl";
import {dealImage, dealParams, getBase64, imgToCanvas, rotateBase64Img} from "../../../api/config";
import {cacallback, delResign,updatePhaSign} from "../../../api/hospitalapi/upload";
import {Toast} from "antd-mobile";
import {zipImg} from "../../../component/zipImage";
import {uploadImg} from "../../../api/request/upload";
import {updateDocterAutograph} from "../../../api/hospitalapi/mine";
import icon1 from "../../../static/img/yy/qianming.png";
import SignatureCanvas from "react-signature-canvas";
import icon2 from "../../../static/img/yy/over.png";
import icon3 from "../../../static/img/yy/del.png";

export default class index extends Component {
    constructor() {
        super()
        this.state = {
            trimmedDataURL: null,

            docterId:localStorage.getItem("pharmacistId"),
            docterPhone : localStorage.getItem("pharmacistPhone"),
            img:'',
            docter:'',
            caIspass:2
        }
    }
    componentDidMount = async () => {
        const {docterPhone, caPassTime} = this.state
        const res = await getuserinfo({
            docterPhone,
        })

        if (res && res.data&& res.data[0].length) {
            this.setState({
                docter: res.data[0][0],
            })
        }
    }
    trim = () => {
        const {docter, caPassTime,docterId} = this.state
        let aaa = this.sigCanvas.isEmpty();
        let img0=''
        let img1=''
        if (aaa){
            Toast.info('请先签名')
        }else {
            this.setState({
                trimmedDataURL: this.sigCanvas.getTrimmedCanvas().toDataURL("image/png")
            }, () => {
                let res0 = this.sigCanvas.getTrimmedCanvas().toDataURL("image/png")

                //顺时针旋转90度
                let res1 = rotateBase64Img(res0, 90, callback)

                function callback(val) {
                    img1 = val
                }

                setTimeout(() => {
                    let a = dealImage(img1, callback1)


                    function callback1(val) {
                        //base64转png图片
                        let res2 = imgToCanvas(val)
                        let file = res2
                        // if (file.type=="image/png"||file.type=="image/jpeg"||file.type=="image/jpg") {
                        zipImg(file).then(res => {
             
                            let formData = new FormData()
                            formData.append('file', res)
                       
                            uploadImg(formData).then(res1 => {
                       
                                if (res1 && res1.code == 2) {
                                    img0 = res1.data

                                }
                                // Toast.hide()
                            }).catch(err => {
                                // Toast.hide()
                            })
                        })
                    }
                }, 500)
                setTimeout(() => {
     

                    if (img0) {
                        updatePhaSign({
                            pharmacist_img: img0,
                            docter_id:docterId,
                        }).then(res=>{
         
                            if(res && res.data && res.code == 1){
                                Toast.info('签名修改成功')
                                this.props.history.push('/mine')
                            }
                        })
                    

                    }
                }, 1000)


            });
        }
    };
    render() {
        let { trimmedDataURL } = this.state;
        return (
            <div className='electronicsignature'>
                <div className='electronicsignature-top'>
                    手写签名
                </div>
                <div className='electronicsignature-body'>
                    <div className='electronicsignature-body-left'>
                        <img src={icon1}/>
                    </div>
                    <div className='electronicsignature-body-center'>
                        <SignatureCanvas
                            penColor="#000"  // 笔刷颜色
                            minWidth={5} // 笔刷粗细
                            maxWidth={7}
                            ref={(ref) => { this.sigCanvas = ref }} //用于调用插件的API
                            canvasProps={{width: window.outerWidth*.7,
                                height: window.outerHeight*.7, style:{position:'absolute',top:'18vh',left:'15vw',backgroundColor:'#F3F3F3', },
                                className: 'sigCanvas'}} />
                    </div>
                    <div className='electronicsignature-body-right'>
                        <img src={icon2} onClick={this.trim}/>
                        <img src={icon3} onClick={()=>this.sigCanvas.clear()} />
                    </div>
                </div>
                <img id="img" src={trimmedDataURL}/>
            </div>
        )
    }
}
