
import React, { Component } from 'react';
import icon1 from "../../static/img/yy/fanhuihei.png"
import icon2 from "../../static/img/head.png"
import { Steps, Button, Space,} from "antd-mobile-v5"
import FootNav from '../../component/FootNav'
import {getuserinfo,getdoctorsh} from "../../api/hospitalapi/login"
import {
    CheckCircleFill,
    ClockCircleFill,
    HandPayCircleOutline,
  } from 'antd-mobile-icons'
import "./style2.less"
const { Step } = Steps
export default class yyhome2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docterExamine: "",
            realNameExamine: "",
            status: "",
            docter: {}
        }
    }

    //获取用户信息(审核状态)
    componentDidMount = async () => {
        const docterPhone = localStorage.getItem("docterPhone")
        const docterId = localStorage.getItem("docterId")
        const res = await getuserinfo({
            docterPhone
        })
        // console.log(res);
        const res2 = await getdoctorsh(docterId)
        // console.log(res2);
        this.setState({
            docterExamine: res.data[0][0].docterExamine,
            realNameExamine: res.data[0][0].realNameExamine,
            status: res2.data[0][0].docter_status,
            docter: res.data[0][0]
        })
    }

    render() {
        const {docterExamine, realNameExamine, status, docter} = this.state
        return (
            <div className='homebox2'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={this.gobank}/><h1>欢迎入驻</h1>
                </div>
                <div className='box-title'>
                    <img src={!docter ? icon2 : docter.docter_avatar ? docter.docter_avatar : icon2}
                         alt=""/><span>{!docter ? "暂无" : docter.docter_name ? docter.docter_name : "暂无"}</span>
                </div>
                <div className='box-content'>
                    <h1 className='content-tips'>入驻流程</h1>
                    <p className='content-tips2'>按以下流程入驻成功后，即可接诊</p>
                    <p className='content-tips2'>您提交的信息和资料，将用于身份、医疗资质等核验</p>
                    <Steps
                        direction='vertical'
                        current={1}
                        style={{
                            '--title-font-size': '15px',
                            '--description-font-size': '15px',
                            '--indicator-margin-right': '12px',
                            '--icon-size': '22px',
                        }}
                    >
                        <Step
                            title='填写基本信息'
                            // description='这里是一些描述'
                            icon={<CheckCircleFill/>}
                        />
                        <Step
                            title='2、入驻平台审核'
                            description={
                                <Space block direction='vertical'>
                                    <div>{status != "1" ? "您需要等待工作人员的审核" : "审核通过"} {status == "0" ?
                                        <span style={{color: "#FF6A00"}}>资料审核中</span> : status == "-1" ?
                                            <span style={{color: "#FF6A00"}}>资料有误已被驳回</span> : null}</div>
                                </Space>
                            }
                            icon={status == "1" ? <CheckCircleFill/> : <ClockCircleFill/>}
                        />
                        <Step
                            title='3、人脸实名认证'
                            description={
                                <Space block direction='vertical'>
                                    <div> {status == "1" ? <Button>去认证</Button> : "您需要补充资料才能进一步完成"}</div>
                                </Space>
                            }
                            icon={status == "1" ? <CheckCircleFill/> : <ClockCircleFill/>}
                        />
                        <Step
                            title='4、入驻成功'
                            description={
                                <Space block direction='vertical'>
                                    <div>可以开通服务</div>
                                </Space>
                            }
                            icon={status == "1" ? <CheckCircleFill/> : <ClockCircleFill/>}
                        />
                    </Steps>
                </div>
                <FootNav/>
            </div>
        )
    }
}
