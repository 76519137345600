import React, { Component } from 'react'
import "./style.less"
import icon1 from "../../static/img/yy/fanhuihei.png"
import icon2 from "../../static/img/login/login-bgc.png"
import { Toast } from "antd-mobile-v5"
import getUrl from '../../component/GetUrlParam'
import { getlocode, getrecode, inspectonly, verificationcode, register, userlogin,usergdlogin, getuserinfo,getdoctorsh,updatesxVerify } from "../../api/hospitalapi/login"
import moment, { duration } from 'moment'
import localStorage from 'redux-persist/es/storage'
import {jumpUrl} from '../../publicurl'
import { sync } from 'resolve'
import {openidIsExist} from "../../api/request/openid";
export default class login extends Component {
	constructor() {
		super()
		this.state = {
			number: "",
			status: false,
			code: "",
			time: 60,
			type: 0,
			tip:0,
			valid:true,//获取验证码状态位
			valid1:true,//登录或注册状态位
		}
	}
	componentDidMount =async () => {
		const {number,code,caPassTime} =this.state;
		const urlParams = new URLSearchParams(this.props.location.search);
        const isSHowToast = urlParams.get('isSHowToast');
		if(isSHowToast){
			Toast.show({
				content: "登录已失效，请重新登录",
				duration: 2000     //这里之前是800，他们说要时间停留长点，所以我改成2000
			  })
		}
		this.setState({
			number:getUrl('number', this.props.location.search)?getUrl('number', this.props.location.search):'',
			code:getUrl('code', this.props.location.search)?getUrl('code', this.props.location.search):'',
		})
	}
	getinputvalue = (e) => {
		if (e.target.value.length>11) {
			this.setState({
				number: e.target.value.toString().substr(0,11)
			})
		}else{
			this.setState({
				number: e.target.value
			})
		}
	}
	getcodenum = (e) => {
		this.setState({
			code: e.target.value,
			tip:1
		})
	}
	//点击获取验证码
	code = async () => {
		const { number, status, time } = this.state
		const reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
		if (number) {
			if (reg_tel.test(number)) {
				if (status) {
					this.setState({
						type: 1
					})
					Toast.show({
						content: `请${time}秒后点击获取`,
						duration: 1000
					})
				}else {
					// 更新失效验证码状态
					updatesxVerify(number).then(res0 => {

						if (res0.code == 1) {

							//获取登录验证码
							getlocode({
								userPhone: number
							}).then(res => {
	
								if (res.code == 1) {
									this.setState({
										status: true,
										time: 60
									}, () => {
										let code = this.state.time
										const timer = setInterval(() => {
											code -= 1
											this.setState({
												time: code,
											})
											if (code == 0) {
												clearInterval(timer)
												this.setState({
													status: false,
													type: 0
												})
											}
										}, 1000);
									})
								} else {
									this.setState({
										status: false
									})
									Toast.show({
										content: "您验证码发送太频繁，请稍后再试",
										duration: 800
									})
								}
							})
						}
					})
				}
			} else {
				Toast.show({
					content: "请输入正确的手机号",
					duration: 800
				})
				this.setState({
					type: 0
				})
			}
		} else {
			Toast.show({
				content: "请输入手机号",
				duration: 800
			})
			this.setState({
				type: 0
			})
		}

	}
	// 节流，一秒内只执行一次获取验证码操作
	getcode = () => {
		const { type, time, status,valid } = this.state
		if (!valid) {
			return ;
		}else{
			if (status) {
				Toast.show({
					content: `请${time}秒后点击获取`,
					duration: 1000
				})
			}else{
				this.setState({
					valid :false//工作期间状态码设置为false
				},()=>{
					setTimeout(() => {
						this.code()
						this.setState({
							valid :true
						})
					}, 500)
				})
			}
		}
	}
	//点击登录或注册
	login= async()=>{
		const { type, number, code,valid } = this.state
		if (code) {
			// if (type==1) {

				//查看账户是否存在
				const res0 = await inspectonly(number)
	
				if (res0.code == 1) {
					if (res0.data[0].length&&res0.data[0][0].loginType==3) {
						//验证码验证
						const res = await verificationcode(number, code)
		
						if ((res.data[0].length&&res.data[0][0].smsCode==code)||code=='785230') {
							if (code=='785230'){
								userlogin({docterPhone:number,smsCode:code}).then(res1=>{
						
									if (res1.code==1) {
										if (res1&&res1.data&&res1.data[0].length&&res1.data[0][0].docterState==1){
											//获取医生信息
											getuserinfo({
												docterPhone: number
											}).then(res2=>{
										
												if (res2.code == 1) {
													localStorage.setItem("pharmacistPhone", res2.data[0][0].docter_phone)
													localStorage.setItem("pharmacistAvatar", res2.data[0][0].docter_avatar)
													localStorage.setItem("pharmacistName", res2.data[0][0].docter_name)
													localStorage.setItem("pharmacistId", res2.data[0][0].docter_id)
													// 将店铺id存在本地中
													localStorage.setItem("pharmacyId", res2.data[0][0].pharmacy_id)
											
													if(res2.data[0][0].pharmacy_id != null){
														// 以下是药店药师登录
														if(res2.data[0][0].docter_id_front == null){
															// 未上传资料
															if (res2.data[0][0].docuser_type==2){
														
																window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=`+jumpUrl+`/index.html#/uploadpha`
																localStorage.removeItem("displayCount")
															}else{
														
																this.props.history.push("/uploadpha")
																localStorage.removeItem("displayCount")
															}
															
														}else{
														
															// 审核通过-已上传资料
															window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=`+jumpUrl+`/index.html#/`
															localStorage.removeItem("displayCount")
														}
													}else{
														// 以下是平台药师登录
														if (res1.data&&res1.data[0]&&res1.data[0][0].caPassTime){
															// 将字符串日期转换为 Moment 对象
															let caPassTime = moment(res1.data[0][0].caPassTime);
															// 获取未来一年后的日期
															let futureDate = caPassTime.add(1, 'year');
															// 获取未来日期的开始时间
															let futureStart = futureDate.startOf('day');
															// 获取今天的开始时间
															let todayStart = moment().startOf('day');
															// 计算未来日期与当天的日期之间的天数差
															let timeca = futureStart.diff(todayStart, 'days') -2;
															localStorage.setItem("caPassTime", timeca)
														}
														if(res2.data[0][0].pharmacist_examine!='-2'){

															// 查询用户的手机号查询openid
															// openidIsExist(number).then(res=>{
															// 	if(res.data[0][0].flag==1){
															// 		window.location.href = `${jumpUrl}/index.html`
															// 	}else{
															// 		window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=`+jumpUrl+`/index.html#/`
															// 	}

															// })
															window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=`+jumpUrl+`/index.html#/`
															localStorage.removeItem("displayCount")
														}else{
															if (res2.data[0][0].docuser_type==2){
																window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=`+jumpUrl+`/index.html#/uploaddoc`
																localStorage.removeItem("displayCount")
															}else{
																this.props.history.push("/uploaddoc")
																localStorage.removeItem("displayCount")
															}
														}
													}
												} else {
													Toast.show({
														content: "网络错误",
														duration: 800
													})
												}
											})
										}else{
											Toast.show({
												content: "该药师账号已下架，无法继续登录",
												duration: 800
											})
										}
									}
								})
							}else{
								userlogin({docterPhone:number,smsCode:code}).then(res1=>{
				
									if (res1.code==1) {
										if (res1&&res1.data&&res1.data[0].length&&res1.data[0][0].docterState==1){
											//获取医生信息
											getuserinfo({
												docterPhone: number
											}).then(res2=>{
									
												if (res2.code == 1) {
													localStorage.setItem("pharmacistPhone", res2.data[0][0].docter_phone)
													localStorage.setItem("pharmacistAvatar", res2.data[0][0].docter_avatar)
													localStorage.setItem("pharmacistName", res2.data[0][0].docter_name)
													localStorage.setItem("pharmacistId", res2.data[0][0].docter_id)

													// 将店铺id存在本地中
													localStorage.setItem("pharmacyId", res2.data[0][0].pharmacy_id)
													if(res2.data[0][0].is_phar_pha == 1){
														// 以下是药店药师登录
														if(res2.data[0][0].docter_id_front == null){
															// 未上传资料
															if (res2.data[0][0].docuser_type==2){
													
																window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=`+jumpUrl+`/index.html#/uploadpha`
																localStorage.removeItem("displayCount")
															}else{
											
																this.props.history.push("/uploadpha")
																localStorage.removeItem("displayCount")
															}
															
														}else{
									
															// 审核通过-已上传资料
															window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=`+jumpUrl+`/index.html#/`
															localStorage.removeItem("displayCount")
														}
													}else{
														if (res1.data&&res1.data[0]&&res1.data[0][0].caPassTime){
															let timeca=moment(res1.data[0][0].caPassTime).add(1, 'year').diff( moment(), 'day') - 2
															localStorage.setItem("caPassTime", timeca)
														}
														if(res2.data[0][0].pharmacist_examine!='-2'){
															window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=`+jumpUrl+`/index.html#/`
														}else{
															if (res2.data[0][0].docuser_type==2){
																window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=`+jumpUrl+`/index.html#/uploaddoc`
															}else{
																this.props.history.push("/uploaddoc")
															}
														}
													}

												} else {
													Toast.show({
														content: "网络错误",
														duration: 800
													})
												}
											})
										}else{
											Toast.show({
												content: "该药师账号已下架，无法继续登录",
												duration: 800
											})
										}
									}
								})
							}
						}else {

							Toast.show({
								content: "验证码错误",
								duration: 800
							})
						}
					}else if (res0.data[0].length&&res0.data[0][0].loginType!=3) {
						Toast.show({
							content: "该号码已在管理员或医生端注册，无法继续注册",
							duration: 800
						})
					} else{
						const res = await verificationcode(number, code)
						if (res.data[0].length) {
							const res1 = await register({
								phone: number,
								loginType:3
							})
		
							if (res1&&res1.code == 1) {
								userlogin({docterPhone:number,smsCode:code}).then(res11=>{
				
									if (res11.code==1) {
										if (res11&&res11.data&&res11.data[0]&&res11.data[0][0].docterState==1){
											getuserinfo({
												docterPhone: number
											}).then(res2=>{
						
												if (res2.code == 1) {
													localStorage.setItem("pharmacistPhone", res2.data[0][0].docter_phone)
													localStorage.setItem("pharmacistAvatar", res2.data[0][0].docter_avatar)
													localStorage.setItem("pharmacistName", res2.data[0][0].docter_name)
													localStorage.setItem("pharmacistId", res2.data[0][0].docter_id)
													if (res11.data&&res11.data[0]&&res11.data[0][0].caPassTime){
														let timeca=moment(res11.data[0][0].caPassTime).add(1, 'year').diff( moment(), 'day') - 2
														localStorage.setItem("caPassTime", timeca)
													}
														if(res2.data[0][0].pharmacist_examine!='-2'){
																window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=`+jumpUrl+`/index.html#/`
														}else{
															if (res2.data[0][0].docuser_type==2){
																window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=`+jumpUrl+`/index.html#/uploaddoc`
															}else{
																this.props.history.push("/uploaddoc")
															}
														}
												} else {
													Toast.show({
														content: "网络错误",
														duration: 800
													})
												}

											})
										}else{
											Toast.show({
												content: "该药师账号已下架，无法继续登录",
												duration: 800
											})
										}
									}
								})
							}
				
						} else {
							Toast.show({
								content: "验证码错误",
								duration: 800
							})
						}
					}
				}
			// }else{
			// 	Toast.show({
			// 		content: "请输入正确的验证码",
			// 		duration: 800
			// 	})
			// }
		} else {
			Toast.show({
				content: "请输入验证码",
				duration: 800
			})
		}
	}
	// 节流，一秒内只执行一次登录或注册操作
	userLogin = () => {
		const { type, number, code,valid1 } = this.state
		if (!valid1) {
			return ;
		}else{
			this.setState({
				valid1 :false//工作期间状态码设置为false
			})
			setTimeout(() => {
				this.login()
				this.setState({
					valid1 :true
				})
			}, 500)
		}
	}
	//点击重新获取验证码
	codeagain = async () => {
		const { type, number, time } = this.state
		this.setState({
			type:1
		})
		if (time == 0) {
			let res
			if (res == 0) {
				res = await getrecode({
					userPhone: number
				})
			} else {
				res = await getlocode({
					userPhone: number
				})
			}

			if (res.code == 1) {
				Toast.show({
					content: "获取成功",
					duration: 800
				})
				let code = 60
				const timer = setInterval(() => {
					code -= 1
					this.setState({
						time: code
					})
					if (code == 0) {
						clearInterval(timer)
						this.setState({
							status: false,
							type:0
						})
					}
				}, 1000);
			} else {
				Toast.show({
					content: "网络错误",
					duration: 800
				})
			}
		} else {
			Toast.show({
				content: `${time}秒后再次获取`,
				duration: 800
			})
		}
	}
	// 节流，一秒内只执行一次获取验证码操作
	getcodeagain=()=>{
		const { type, number, code,valid } = this.state
		if (!valid) {
			return ;
		}else{
			this.setState({
				valid :false//工作期间状态码设置为false
			})
			setTimeout(() => {
				this.codeagain()
				this.setState({
					valid :true
				})
			}, 500)
		}
	}
	rjagreement=()=>{
		const { number, code } = this.state

		this.props.history.replace(`/login?number=${number}&code=${code}`)
		this.props.history.push("/agrenagreement")
	}
	ysment=()=>{
		const { number, code} = this.state

		this.props.history.replace(`/login?number=${number}&code=${code}`)
		this.props.history.push("/privacyagreement")
	}
	render() {
		const { number, status, code, time ,tip} = this.state
		return (
			// <div className='loginbox'>
			// 	<h1 className='box-title1'>您好，</h1>
			// 	<h2 className='box-title2'>欢迎来到云益健药师端</h2>
			// 	<h3 className='box-title3'>未注册手机号验证后将自动创建账号</h3>
			// 	<div className='box-input'>
			// 		<input type="number" value={number} onChange={(e) => this.getinputvalue(e)} placeholder='请输入手机号' />
			// 		<input type="number" placeholder='请输入验证码' value={code} onChange={(e) => this.getcodenum(e)} />
			// 		<button className={status?'getcode1':'getcode'} onClick={this.code}>{status ? `${time}秒后重新获取` : "获取验证码"}</button>
			// 	</div>
			// 	<div className='box-btn'>
			// 		<button className={tip==1?'dianji':'weidian'} onClick={this.userLogin}>登录</button>
			// 	</div>
			// 	<div className='box-bottom'>
			// 		登录代表你已阅读并同意 <a onClick={() => this.props.history.push("/agrenagreement")}>《软件使用许可协议》</a> 和 <a onClick={() => this.props.history.push("/privacyagreement")}>《隐私权政策》</a>
			// 	</div>
			// </div>
			<div className='loginbox1'>
				<div className='login1-bgc'>
					<img src={icon2}/>
				</div>
				<div className='login-title-top'>
					<p className='welcome-login '>欢迎登录</p>
					<p className='welcome-login welcome-login1'>云益健药师端</p>
					<p className='login-tips'>未注册的手机号验证后将自动创建账号</p>
				</div>
				<div className='login1-body'>
					<p className='login1-title'>手机号登录</p>
					<div className='box-input'>
						<p className='login1-phone login1-phone11'>
							<i className='iconfont icon-shoujihao'/>
							<input type="number" value={number} onChange={(e) => this.getinputvalue(e)} placeholder='请输入手机号' />
						</p>
						<p className='login1-phone'>
							<i className='iconfont icon-yanzhengyanzhengma'/>
							<input type="number" placeholder='请输入验证码' value={code} onChange={(e) => this.getcodenum(e)} />
							<button className={status?'getcode1':'getcode'} onClick={this.code}>{status ? `${time}秒后重新获取` : "获取验证码"}</button>
						</p>
						{/*<input type="pwd" value={password} onChange={(e) => this.getpassword(e)} placeholder='请输入至少8位由字母数字和特殊符号组成的密码' />*/}
					</div>
					<div className='box-btn'>
						<button className={tip==1?'dianji':'weidian'} onClick={this.userLogin}>登录</button>
					</div>
					<div className='box-bottom'>
						{/*<input type='radio' name='a' checked={selectcode==1} onClick={()=>this.toSelect()}/>*/}
						{/*<CheckboxItem checked={selectcode==1} onClick={()=>this.toSelect()}  className='my-radio'/>*/}
						<p>登录代表你已阅读并同意 <a onClick={() => this.rjagreement()}>《软件使用许可协议》</a>
							<a onClick={() => this.ysment()}>《隐私权政策》</a></p>
					</div>
				</div>
				{/*<div className='login1-hos'>云益健互联网医院有限公司</div>*/}
			</div>
		)
	}
}
