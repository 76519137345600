import { axiosInstance } from '../config'
//查询值班人员是否存在
export const dutyIshave = (docterPhone) => {
    return axiosInstance.get(`/api/hosp/hospital-duty/duty-ishave?docterPhone=${docterPhone}`)
}
//h5值班人员账号密码登录
export const loginDuty = (data) => {
    return axiosInstance.post('/api/hosp/wzdocter/login', data)
}
// 、待接问诊和待审处方列表
export const dutyList = (data) => {
    return axiosInstance.post('/api/hosp/hospital-duty/duty-list', data)
}
//待接处方详情
export const dutyConsultationDetails = (data) => {
    return axiosInstance.post('/api/hosp/hospital-duty/duty-consultation-details', data)
}
//查询线下药店问诊单选择的药品信息
export const allmedsNews = (data) => {
    return axiosInstance.post(`/api/hosp/offline-pharmacy/allmeds-news`,data)
}
// 值日查看开方总数
export const allCount = () => {
    return axiosInstance.get(`/api/hosp/hospital-duty/all-count`)
}
//值日查看单量统计
export const dutyStatisticsAll = (data) => {
    return axiosInstance.post(`/api/hosp/hospital-duty/duty-statistics-all`,data)
}
// 值日查看已接问诊
export const allDutyconsultation = (data) => {
    return axiosInstance.post(`/api/hosp/hospital-duty/all-dutyconsultation`,data)
}

// 查询今日值班人员selectDutymenberexport 
export const selectDutymenber  = (data) => {
    return axiosInstance.post(`/api/hosp/hospital-duty/select-duty-menber`,data)
}

// 查询是否中药问诊单isChineseMeds
export const isChineseMeds = (data) => {
    return axiosInstance.post('/api/hosp/wzdocter/is-chinesemeds-consu', data)
}

//查询用法用量chineseMethod
export const chineseMethod = (data) => {
    return axiosInstance.post('/api/hosp/wzdocter/chinese-method', data)
}

export const chineseMethodDoc = (data) => {
    return axiosInstance.post('/api/hosp/wzdocter/chinese-method-doc', data)
}

// 值日查看拒诊问诊列表
export const allDutyRefuseList = (data) => {
    return axiosInstance.post(`/api/hosp/hospital-duty/all-duty-refuse-list`,data)
}