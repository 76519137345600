
import React, { Component } from 'react'
import { ImageUploader,  Picker,SpinLoading,Toast,DatePicker } from "antd-mobile-v5"
import { ImagePicker,Modal, } from "antd-mobile"
import "./style.less"
import qs from 'qs';
import icon1 from "../../static/img/yy/fanhuihei.png"
import icon2 from "../../static/img/yy/cha.png"
import { uploaddoctor, uploadpic } from "../../api/hospitalapi/upload"
import { zipImg } from '../../component/zipImage'
import {queryDepartment,ejDepartment} from '../../api/hospitalapi/department'
import {dealImage, dealParams, getBase64Image, imgToCanvas} from '../../api/config'
import {caAuthentication} from '../../api/hospitalapi/upload'
import {getuserinfo} from "../../api/hospitalapi/login";
import {caUrl,jumpUrl,baseUrl} from "../../publicurl"
import {uploadImg} from "../../api/request/upload";
import getUrl from "../../component/GetUrlParam";
import {addOpenid} from "../../api/hospitalapi/offline";
const { alert } = Modal
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            fileList: [],
            fileList1: [],
            fileList4: [],
            fileList5: [],
            fileList6: [],
            evaluatePic:[],
            evaluatePic1:[],
            evaluatePic4:[],
            evaluatePic5:[],
            evaluatePic6:[],
            docter:'',
            name: "",
            id: "",
            sex:'',
            docterId: localStorage.getItem("pharmacistId"),
            visible: false,
            visible2: false,
            value: "",
            typea:0,
            sexAndAge:'',
            pharmacistOrg:'',
            pharmacistCertificateNum:'',
            code:0,//第一次上传执业资格证数量
            code4:0,
            code5:0,
            code6:0,
            pharmacistCertificateTime:'',
            pharmacistType:0,//药师类型 1、西药药师 2、中药药师
            basicColumns: [
                [
                    {label: '西药药师', value: '西药药师'},
                    {label: '中药药师', value: '中药药师'}
                ]
            ],
            isError: false,
            invalidFields: [],
            scrollTop: 0,
            pic4:false,
            pic3:false,
            pic2:false,
            pic1:false,
            pic0: false
        }
        this.useRef = React.createRef();
    }
    componentDidMount=async ()=>{
        const {docterId,} = this.state
        const docterPhone = localStorage.getItem("pharmacistPhone")
        if (docterPhone) {
            const res = await getuserinfo({
                docterPhone,
            })

            if (res&&res.data[0]&& res.data[0].length) {
                this.setState({
                    docter: res.data[0][0],
                })
            }
            if (res&&res.data[1]&& res.data[1].length) {
                this.setState({
                    list: res.data[1][0],
                    code4:1,
                    code5:1,
                    name:res.data[1][0].pharmacist_name,
                    id:res.data[1][0].pharmacist_card_number,
                    pharmacistOrg:res.data[1][0].pharmacistOrg,
                    department1:res.data[1][0].pharmacist_department,
                    sex: res.data[1][0].pharmacist_sex,
                    pharmacistCertificateTime: res.data[1][0].pharmacistCertificateTime1,
                    pharmacistCertificateNum:res.data[1][0].pharmacistCertificateNum,
                    pharmacistType:res.data[0][0].is_pha,
                    evaluatePic4:[res.data[1][0].pharmacist_id_front],
                    evaluatePic5:[res.data[1][0].pharmacist_id_back],
                    evaluatePic:res.data[1][0].pharmacist_register_id?[res.data[1][0].pharmacist_register_id]:[],
                    code6:res.data[1][0].pharmacist_register_id?1:0,
                    evaluatePic1:[res.data[1][0].qualification_certificate.split(",")[0]],
                    evaluatePic6:res.data[1][0].qualification_certificate.split(",").length==2?[res.data[1][0].qualification_certificate.split(",")[1]]:'',
                    code:res.data[1][0].qualification_certificate.split(",").length
                },()=>{
                    this.analyzeIDCard(res.data[1][0].pharmacist_card_number)
                })
            }
        }
        if (getUrl('openid',window.location.search)) {
            let res=await addOpenid({openid:getUrl('openid', window.location.search),docterId})

        }
    }
    changevalue = (e, type) => {
        const {validateField,id} = this.state
        switch (type) {
            case "name":

                this.setState({
                    name: e.target.value
                },() => {
                    if (type === "name") {
                        this.validateField("name");
                    }
                  })
                break;
            case "id":
                this.setState({
                    id: e.target.value
                },() => {
                    this.analyzeIDCard(id)
                    if (type === "id") {
                        this.validateField("id");
                    }
                })
                break;
            case "sex":
                this.setState({
                    sex: e.target.value,
                },() => {
                    if (type === "sex") {
                        this.validateField("sex");
                    }
                })
                break;
            case "pharmacistOrg":
                this.setState({
                    pharmacistOrg: e.target.value,
                },() => {
                    if (type === "pharmacistOrg") {
                        this.validateField("pharmacistOrg");
                    }
                })
                break;
            case "pharmacistCertificateNum":
                this.setState({
                    pharmacistCertificateNum: e.target.value,
                },() => {
                    if (type === "pharmacistCertificateNum") {
                        this.validateField("pharmacistCertificateNum");
                    }
                })
                break;
            default:
                return
        }
    }

    validateField = (field) => {
        const { invalidFields } = this.state;
        if (invalidFields.includes(field)) {
          const updatedInvalidFields = invalidFields.filter((item) => item !== field);
          this.setState(
            {
              invalidFields: updatedInvalidFields
            },
            () => {
              if (updatedInvalidFields.length === 0) {
                // 如果已经没有错误字段，可以在这里执行一些操作
              }
            }
          );
        }
    }

    onscroll = (e) => {
        const { flag } = this.state;
        e.preventDefault();
        e.stopPropagation();
        this.setState({ flag });
        if (
          e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <
          5
        ) {
        }
    };

    // 点击上传
    uploaddata = async () => {
        const { isPhaNum,invalidFields,evaluatePic,sex,docter,sexAndAge, evaluatePic1,evaluatePic6,pharmacistCertificateTime,
            pharmacistType, evaluatePic3,list, typea,pharmacistCertificateNum,pharmacistOrg,evaluatePic4, evaluatePic5, name, id, hospital, department1, department2, title, docterId } = this.state

        const requiredFields = ["name", "id", "sex", "pharmacistCertificateNum", "pharmacistOrg","pharmacistType","pharmacistCertificateTime"];
        const missingFields = requiredFields.filter((field) => !this.state[field]);
        let p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
        if (evaluatePic4.length && evaluatePic5.length&&pharmacistOrg&& evaluatePic1.length && name && id &&sex &&pharmacistCertificateNum&&pharmacistCertificateTime&&
            pharmacistType) {
            // console.log(fileList, fileList2, fileList3, fileList4, fileList5, name, id, hospital, department1, department2, title);
            if (p.test(id)){
                if (sexAndAge.sex!=sex){
                   
                    Toast.show({
                        content: "身份证号和性别不符",
                        duration: 1000
                    })
                }else{

                    // if (docter.docter_examine=='-1'&&docter.ca_ispass==1){
                        // if(!typea){
                        const res = await uploaddoctor({
                            docterId,
                            docterName: name,
                            idNumber: id,
                            sex: sex,
                            updatecode:list?1:'',
                            docterIdFront: evaluatePic4[0],
                            docterIdBack: evaluatePic5[0],
                            docterLicense: evaluatePic[0],
                            qualificationCertificate: evaluatePic6.length?evaluatePic1[0]+','+evaluatePic6[0]:evaluatePic1[0],
                            pharmacistOrg,
                            pharmacistCertificateNum,
                            pharmacistCertificateTime,
                            isPha:pharmacistType
                        })
     
                    if (res && res.data&&res.data[0]&&res.data[0]&&res.data[0].length) {
                        if (!list&&res.data[0][0].countall==0){
                            Toast.show({
                                content: "基本信息提交成功，等待管理员确认",
                                duration: 1000
                            })
                            this.props.history.replace("/")
                            this.props.history.push('/')
                        }else if(list&&res.data[1]&&res.data[1].length&&res.data[1][0].countall==0){
                            Toast.show({
                                content: "基本信息修改成功",
                                duration: 1000
                            })
                            this.props.history.replace("/")
                            this.props.history.push('/')
                        }else{
                            Toast.show({
                                content: "基本信息已提交，请勿重复提交",
                                duration: 1000
                            })
                            this.props.history.replace("/")
                            this.props.history.push('/')
                        }

                    }
                }
            }else {
                Toast.show({
                    content: "请填写正确的身份证号",
                    duration: 1000
                })
            }
        } else {
            if(!evaluatePic4.length){
                this.setState({
                    pic4:true,
                    
                })
            }
            if(!evaluatePic5.length){
                this.setState({
                  
                    pic0:true,
                })
            }
            if(!evaluatePic1.length){
                this.setState({
                    pic3:true,
                })
            }    
            if(!pharmacistType){
                this.setState({
                    pic2:true,
                })
            }

            if(!pharmacistCertificateTime){
                this.setState({
                    pic1:true,
                })
            }
            if (pharmacistType == 0) {
                // 当 pharmacistType 为 0 时，添加 invalid 样式
                this.setState({ invalid: true });
            } else {
                // 当 pharmacistType 不为 0 时，移除 invalid 样式
                this.setState({ invalid: false });
            }

            // if(!evaluatePic4 || !evaluatePic5 || !evaluatePic1 || !name || !id || !sex || !pharmacistCertificateNum || !pharmacistOrg || pharmacistType == 0){
            //     Toast.show({
            //         content: "请将信息填写完整",
            //         duration: 1000,
            //       });
            //       return;
            // }
            
            // if (missingFields.length > 0) {
            if(!pharmacistCertificateTime || !evaluatePic4.length || !evaluatePic5.length || !evaluatePic1.length || !name || !id || !sex || !pharmacistCertificateNum || !pharmacistOrg || pharmacistType == 0 || !isPhaNum){  
                this.setState({ invalidFields: missingFields }, () => {
                  this.useRef.current.scrollTop = 0;
                  Toast.show({
                    content: "请将信息填写完整",
                    duration: 1000,
                  });
                });
                return;
              }

        }
    }
    // 通过身份证号获取性别年龄
    analyzeIDCard = (IDCard)=> {
        var sexAndAge = {};
        //获取用户身份证号码
        var userCard = IDCard;
        //如果身份证号码为undefind则返回空
        if (!userCard) {
            return sexAndAge;
        }
        //获取到性别
        if (parseInt(userCard.substr(16, 1)) % 2 == 1) {
            sexAndAge.sex = '男'
        } else {
            sexAndAge.sex = '女'
        }
        //获取出生年月日
        //userCard.substring(6,10) + "-" + userCard.substring(10,12) + "-" + userCard.substring(12,14);
        var yearBirth = userCard.substring(6, 10);
        var monthBirth = userCard.substring(10, 12);
        var dayBirth = userCard.substring(12, 14);
        //获取当前年月日并计算年龄
        var myDate = new Date();
        var monthNow = myDate.getMonth() + 1;
        var dayNow = myDate.getDay();
        var yearNow = myDate.getFullYear();
        var age=yearBirth+'-'+monthBirth+'-'+dayBirth
        if (yearNow<yearBirth||((yearNow==yearBirth)&&(monthNow<monthBirth))||((yearNow==yearBirth)&&(monthNow==monthBirth)&&(dayNow<dayBirth))) {
            this.setState({
                sexAndAge:-1
            })
            return;
        }else{
            // var myDate = new Date();
            // var monthNow = myDate.getMonth() + 1;
            // var dayNow = myDate.getDay();
            // var age = myDate.getFullYear() - yearBirth;
            // if (monthNow < monthBirth || (monthNow == monthBirth && dayNow < dayBirth)) {
            //     age--;
            // }
            //获取到年龄
            sexAndAge.age = age;
  
            this.setState({
                sexAndAge
            })
            //返回性别和年龄
            return sexAndAge;

        }
    }
    gobank = () => {
        window.history.back()
    }
    imgChange1 = async (files, type, index) => {
        const {fileList4,compressThreshold = 1 } = this.props;
        let imgList = []
        let pictureQuality = 0.5

        if(type === 'add') {
            let file = files[files.length-1].file
            let fileSize = file.size / 1024 / 1024;
  
            if (file.type=="image/png"||file.type=="image/jpeg"||file.type=="image/jpg") {
                // if (file.size < 1024 * 1024) {
                //当开启图片压缩且图片大小大于等于压缩阈值,进行压缩
                if ((fileSize >= compressThreshold) ) {
                    if (fileSize*0.9<=1){
                            pictureQuality=0.9
                    }else if(fileSize*0.8<=1){
                            pictureQuality=0.8
                    }else if(fileSize*0.7<=1){
                            pictureQuality=0.7
                    }else if(fileSize*0.6<=1){
                            pictureQuality=0.6
                    }else if(fileSize*0.5<=1){
                            pictureQuality=0.5
                    }else if(fileSize*0.4<=1){
                            pictureQuality=0.4
                    }else if(fileSize*0.3<=1){
                            pictureQuality=0.3
                    }else if(fileSize*0.2<=1){
                            pictureQuality=0.2
                    }else if(fileSize*0.1<=1){
                            pictureQuality=0.1
                    }else{
                            pictureQuality=0.5
                    }
       
                    //判断浏览器内核是否支持base64图片压缩
                    if (typeof (FileReader) === 'undefined') {
                        return file;
                    } else {
                        try {
                            this.setState({
                                spinLoading: true
                            });
                            return new Promise(resolve => {
                                //压缩图片再上传
                                let a=getBase64Image(file,pictureQuality,callback1)
                                let that=this
                                function callback1(val) {
                                    //base64转png图片
                                    zipImg(val).then(res1 => {
                          
                                        let formData = new FormData()
                                        formData.append('file', res1)
                                        uploadpic(formData).then(res2 => {
                              
                                            if(res2.code === 2) {
                                                that.setState({
                                                    fileList4: files
                                                })
                                                imgList.push(res2.data)
                                            } else {
                                                Toast.info('上传失败', 1)
                                            }
                                            that.setState({
                                                evaluatePic4: imgList,
                                                pic4:false,
                                                code4:1
                                            },()=>{
                                                // console.log(imgList);
                                            })
                                            // Toast.hide()
                                        }).catch(err => {
                                            // Toast.hide()
                                        })
                                    })
                                }
                            }).then(res => {
                                this.setState({
                                    spinLoading: false
                                });
                                // console.log(res)

                                // return res;
                            }).catch(() => {
                                this.setState({
                                    spinLoading: false
                                });
                                return file;
                            });
                        } catch (e) {
                            this.setState({
                                spinLoading: false
                            });
                            //压缩出错,直接返回原file对象
                            return file;
                        }
                    }
                }else{
                    zipImg(file).then(res => {
                        let formData = new FormData()
                        formData.append('file', res)
                        uploadpic(formData).then(res1 => {
                            // console.log(res1)
                            if(res1.code === 2) {
                                // console.log(files)
                                this.setState({
                                    fileList4: files
                                })
                                imgList.push(res1.data)
                            } else {
                                Toast.info('上传失败', 1)
                            }
                            this.setState({
                                evaluatePic4: imgList,
                                pic4:false,
                                code4:1
                            })
                            // console.log(imgList);
                            // Toast.hide()
                        }).catch(err => {
                            // Toast.hide()
                        })
                    })
                }
                // else{
                //     Toast.show('请上传小于1M的图片', 1)
                // }
            }else{
                Toast.show('请上传png/jpg格式的图片', 1)
            }
        } else {
            imgList.splice(index, 1)
            this.setState({
                fileList4: files,
                evaluatePic4: imgList,
                pic4:false,
                code4:0
            })
            // console.log(files);
            // Toast.hide()
        }
    }
    //身份证反面
    imgChange2 = async (files, type, index) => {
        const {fileList5,compressThreshold = 1} = this.state
        let imgList = []
        let pictureQuality = 0.5
        if(type === 'add') {
            let file = files[files.length-1].file
            let fileSize = file.size / 1024 / 1024;
            // console.log(file);
            if (file.type=="image/png"||file.type=="image/jpeg"||file.type=="image/jpg") {
                if ((fileSize >= compressThreshold) ) {
                    if (fileSize*0.9<=1){
                        pictureQuality=0.9
                    }else if(fileSize*0.8<=1){
                        pictureQuality=0.8
                    }else if(fileSize*0.7<=1){
                        pictureQuality=0.7
                    }else if(fileSize*0.6<=1){
                        pictureQuality=0.6
                    }else if(fileSize*0.5<=1){
                        pictureQuality=0.5
                    }else if(fileSize*0.4<=1){
                        pictureQuality=0.4
                    }else if(fileSize*0.3<=1){
                        pictureQuality=0.3
                    }else if(fileSize*0.2<=1){
                        pictureQuality=0.2
                    }else if(fileSize*0.1<=1){
                        pictureQuality=0.1
                    }else{
                        pictureQuality=0.5
                    }
                    // console.log(pictureQuality)
                    //判断浏览器内核是否支持base64图片压缩
                    if (typeof (FileReader) === 'undefined') {
                        return file;
                    } else {
                        try {
                            this.setState({
                                spinLoading: true
                            });
                            return new Promise(resolve => {
                                //压缩图片再上传
                                let a=getBase64Image(file,pictureQuality,callback1)
                                let that=this
                                function callback1(val) {
                                    //base64转png图片
                                    zipImg(val).then(res1 => {
                                        // console.log(res1)
                                        let formData = new FormData()
                                        formData.append('file', res1)
                                        uploadpic(formData).then(res2 => {
                                            // console.log(res2)
                                            if(res2.code === 2) {
                                                that.setState({
                                                    fileList5: files
                                                })
                                                imgList.push(res2.data)
                                            } else {
                                                Toast.info('上传失败', 1)
                                            }
                                            that.setState({
                                                evaluatePic5: imgList,
                                                pic0:false
                                            },()=>{
                                                // console.log(imgList);
                                            })
                                            // Toast.hide()
                                        }).catch(err => {
                                            // Toast.hide()
                                        })
                                    })
                                }
                            }).then(res => {
                                this.setState({
                                    spinLoading: false
                                });
                                // console.log(res)

                                // return res;
                            }).catch(() => {
                                this.setState({
                                    spinLoading: false
                                });
                                return file;
                            });
                        } catch (e) {
                            this.setState({
                                spinLoading: false
                            });
                            //压缩出错,直接返回原file对象
                            return file;
                        }
                    }
                }else{
                    zipImg(file).then(res => {
                        let formData = new FormData()
                        formData.append('file', res)
                        uploadpic(formData).then(res1 => {
                            if(res1.code === 2) {
                                this.setState({
                                    fileList5: files
                                })
                                imgList.push(res1.data)
                            } else {
                                Toast.info('上传失败', 1)
                            }
                            this.setState({
                                evaluatePic5: imgList,
                                pic0:false
                            })
                            // console.log(imgList);
                            // Toast.hide()
                        }).catch(err => {
                            // Toast.hide()
                        })
                    })
                }
            }else{
                Toast.show('请上传png/jpg格式的图片', 1)
            }
        } else {
            imgList.splice(index, 1)
            this.setState({
                fileList5: files,
                evaluatePic5: imgList,
                pic0:false
            })
            // console.log(files);
            // Toast.hide()
        }
    }
    //执业药师注册证
    imgChange3 = async (files, type, index) => {
        const {fileList,compressThreshold = 1} = this.state
        let imgList = []
        let pictureQuality = 0.5
        if(type === 'add') {
            let file = files[files.length-1].file
            let fileSize = file.size / 1024 / 1024;
            if (file.type=="image/png"||file.type=="image/jpeg"||file.type=="image/jpg") {
                //当开启图片压缩且图片大小大于等于压缩阈值,进行压缩
                if ((fileSize >= compressThreshold) ) {
                    if (fileSize*0.9<=1){
                        pictureQuality=0.9
                    }else if(fileSize*0.8<=1){
                        pictureQuality=0.8
                    }else if(fileSize*0.7<=1){
                        pictureQuality=0.7
                    }else if(fileSize*0.6<=1){
                        pictureQuality=0.6
                    }else if(fileSize*0.5<=1){
                        pictureQuality=0.5
                    }else if(fileSize*0.4<=1){
                        pictureQuality=0.4
                    }else if(fileSize*0.3<=1){
                        pictureQuality=0.3
                    }else if(fileSize*0.2<=1){
                        pictureQuality=0.2
                    }else if(fileSize*0.1<=1){
                        pictureQuality=0.1
                    }else{
                        pictureQuality=0.5
                    }
                    // console.log(pictureQuality)
                    //判断浏览器内核是否支持base64图片压缩
                    if (typeof (FileReader) === 'undefined') {
                        return file;
                    } else {
                        try {
                            this.setState({
                                spinLoading: true
                            });
                            return new Promise(resolve => {
                                //压缩图片再上传
                                let a=getBase64Image(file,pictureQuality,callback1)
                                let that=this
                                function callback1(val) {
                                    //base64转png图片
                                    zipImg(val).then(res1 => {
                                        // console.log(res1)
                                        let formData = new FormData()
                                        formData.append('file', res1)
                                        uploadpic(formData).then(res2 => {
                                            // console.log(res2)
                                            if(res2.code === 2) {
                                                that.setState({
                                                    fileList: files
                                                })
                                                imgList.push(res2.data)
                                            } else {
                                                Toast.info('上传失败', 1)
                                            }
                                            that.setState({
                                                evaluatePic: imgList
                                            },()=>{
                                                // console.log(imgList);
                                            })
                                            // Toast.hide()
                                        }).catch(err => {
                                            // Toast.hide()
                                        })
                                    })
                                }
                            }).then(res => {
                                this.setState({
                                    spinLoading: false
                                });
                                // console.log(res)

                                // return res;
                            }).catch(() => {
                                this.setState({
                                    spinLoading: false
                                });
                                return file;
                            });
                        } catch (e) {
                            this.setState({
                                spinLoading: false
                            });
                            //压缩出错,直接返回原file对象
                            return file;
                        }
                    }
                }else{
                    zipImg(file).then(res => {
                        let formData = new FormData()
                        formData.append('file', res)
                        uploadpic(formData).then(res1 => {
                            if(res1.code === 2) {
                                this.setState({
                                    fileList: files
                                })
                                imgList.push(res1.data)
                            } else {
                                Toast.info('上传失败', 1)
                            }
                            this.setState({
                                evaluatePic: imgList
                            })
                            // console.log(imgList);
                            // Toast.hide()
                        }).catch(err => {
                            // Toast.hide()
                        })
                    })
                }
            }else{
                Toast.show('请上传png/jpg格式的图片', 1)
            }
        } else {
            // console.log(index)
            imgList.splice(index, 1)
            this.setState({
                fileList: files,
                evaluatePic: imgList
            })
            // console.log(files);
            // Toast.hide()
        }
    }
    imgChange8 = async (files, type, index) => {
        const {fileList,compressThreshold = 1 } = this.state
        let imgList = []
        let pictureQuality = 0.5
        // console.log(files)
        if(type === 'add') {
            let file = files[files.length-1].file
            let fileSize = file.size / 1024 / 1024;
            // console.log(file);
            if (file.type=="image/png"||file.type=="image/jpeg"||file.type=="image/jpg") {
                if ((fileSize >= compressThreshold) ) {
                    if (fileSize*0.9<=1){
                        pictureQuality=0.9
                    }else if(fileSize*0.8<=1){
                        pictureQuality=0.8
                    }else if(fileSize*0.7<=1){
                        pictureQuality=0.7
                    }else if(fileSize*0.6<=1){
                        pictureQuality=0.6
                    }else if(fileSize*0.5<=1){
                        pictureQuality=0.5
                    }else if(fileSize*0.4<=1){
                        pictureQuality=0.4
                    }else if(fileSize*0.3<=1){
                        pictureQuality=0.3
                    }else if(fileSize*0.2<=1){
                        pictureQuality=0.2
                    }else if(fileSize*0.1<=1){
                        pictureQuality=0.1
                    }else{
                        pictureQuality=0.5
                    }
                    // console.log(pictureQuality)
                    //判断浏览器内核是否支持base64图片压缩
                    if (typeof (FileReader) === 'undefined') {
                        return file;
                    } else {
                        try {
                            this.setState({
                                spinLoading: true
                            });
                            return new Promise(resolve => {
                                //压缩图片再上传
                                let a=getBase64Image(file,pictureQuality,callback1)
                                let that=this
                                function callback1(val) {
                                    //base64转png图片
                                    zipImg(val).then(res1 => {
                                        // console.log(res1)
                                        let formData = new FormData()
                                        formData.append('file', res1)
                                        uploadpic(formData).then(res2 => {
                                            // console.log(res2)
                                            if(res2.code === 2) {
                                                that.setState({
                                                    fileList1: files
                                                })
                                                imgList.push(res2.data)
                                            } else {
                                                Toast.info('上传失败', 1)
                                            }
                                            that.setState({
                                                evaluatePic1: imgList,
                                                pic3:false
                                            },()=>{
                                                // console.log(imgList);
                                            })
                                            // Toast.hide()
                                        }).catch(err => {
                                            // Toast.hide()
                                        })
                                    })
                                }
                            }).then(res => {
                                this.setState({
                                    spinLoading: false
                                });
                                // console.log(res)

                                // return res;
                            }).catch(() => {
                                this.setState({
                                    spinLoading: false
                                });
                                return file;
                            });
                        } catch (e) {
                            this.setState({
                                spinLoading: false
                            });
                            //压缩出错,直接返回原file对象
                            return file;
                        }
                    }
                }else{
                    zipImg(file).then(res => {
                        let formData = new FormData()
                        formData.append('file', res)
                        uploadpic(formData).then(res1 => {
                            if(res1.code === 2) {
                                this.setState({
                                    fileList1: files
                                })
                                imgList.push(res1.data)
                            } else {
                                Toast.info('上传失败', 1)
                            }
                            this.setState({
                                evaluatePic1: imgList,
                                pic3:false
                            })
                            // console.log(imgList);
                            // Toast.hide()
                        }).catch(err => {
                            // Toast.hide()
                        })
                    })
                }
            }else{
                Toast.show('请上传png/jpg格式的图片', 1)
            }
        } else {
            imgList.splice(index, 1)
            this.setState({
                fileList1: files,
                evaluatePic1: imgList,
                pic3:false,
                code:imgList.length+this.state.evaluatePic6.length?1:0
            })
            // console.log(files);
            // Toast.hide()
        }
    }
    imgChange6 = async (files, type, index) => {
        const {fileList,compressThreshold = 1 } = this.state
        let imgList = []
        let pictureQuality = 0.5
        // console.log(files)
        if(type === 'add') {
            let file = files[files.length-1].file
            let fileSize = file.size / 1024 / 1024;
            // console.log(file);
            if (file.type=="image/png"||file.type=="image/jpeg"||file.type=="image/jpg") {
                if ((fileSize >= compressThreshold) ) {
                    if (fileSize*0.9<=1){
                        pictureQuality=0.9
                    }else if(fileSize*0.8<=1){
                        pictureQuality=0.8
                    }else if(fileSize*0.7<=1){
                        pictureQuality=0.7
                    }else if(fileSize*0.6<=1){
                        pictureQuality=0.6
                    }else if(fileSize*0.5<=1){
                        pictureQuality=0.5
                    }else if(fileSize*0.4<=1){
                        pictureQuality=0.4
                    }else if(fileSize*0.3<=1){
                        pictureQuality=0.3
                    }else if(fileSize*0.2<=1){
                        pictureQuality=0.2
                    }else if(fileSize*0.1<=1){
                        pictureQuality=0.1
                    }else{
                        pictureQuality=0.5
                    }
                    // console.log(pictureQuality)
                    //判断浏览器内核是否支持base64图片压缩
                    if (typeof (FileReader) === 'undefined') {
                        return file;
                    } else {
                        try {
                            this.setState({
                                spinLoading: true
                            });
                            return new Promise(resolve => {
                                //压缩图片再上传
                                let a=getBase64Image(file,pictureQuality,callback1)
                                let that=this
                                function callback1(val) {
                                    //base64转png图片
                                    zipImg(val).then(res1 => {
                                        // console.log(res1)
                                        let formData = new FormData()
                                        formData.append('file', res1)
                                        uploadpic(formData).then(res2 => {
                                            // console.log(res2)
                                            if(res2.code === 2) {
                                                that.setState({
                                                    fileList6: files
                                                })
                                                imgList.push(res2.data)
                                            } else {
                                                Toast.info('上传失败', 1)
                                            }
                                            that.setState({
                                                evaluatePic6: imgList
                                            },()=>{
                                                // console.log(imgList);
                                            })
                                            // Toast.hide()
                                        }).catch(err => {
                                            // Toast.hide()
                                        })
                                    })
                                }
                            }).then(res => {
                                this.setState({
                                    spinLoading: false
                                });
                                // console.log(res)

                                // return res;
                            }).catch(() => {
                                this.setState({
                                    spinLoading: false
                                });
                                return file;
                            });
                        } catch (e) {
                            this.setState({
                                spinLoading: false
                            });
                            //压缩出错,直接返回原file对象
                            return file;
                        }
                    }
                }else{
                    zipImg(file).then(res => {
                        let formData = new FormData()
                        formData.append('file', res)
                        uploadpic(formData).then(res1 => {
                            if(res1.code === 2) {
                                this.setState({
                                    fileList6: files
                                })
                                imgList.push(res1.data)
                            } else {
                                Toast.info('上传失败', 1)
                            }
                            this.setState({
                                evaluatePic6: imgList
                            })
                            // console.log(imgList);
                            // Toast.hide()
                        }).catch(err => {
                            // Toast.hide()
                        })
                    })
                }
            }else{
                Toast.show('请上传png/jpg格式的图片', 1)
            }
        } else {
            imgList.splice(index, 1)
            this.setState({
                fileList6: files,
                evaluatePic6: imgList
            })
            // console.log(files);
            // Toast.hide()
        }
    }
    delOne1= ()=>{
        this.setState({
            evaluatePic1:this.state.evaluatePic6.length?this.state.evaluatePic6: [],
            fileList1:this.state.evaluatePic6.length?this.state.fileList6: [],
            evaluatePic6:[],
            fileList6:[],
            code:this.state.evaluatePic6.length?1:0
        })
    }
    delOne2= ()=>{
        this.setState({
            evaluatePic1: this.state.evaluatePic1.length?this.state.evaluatePic1:[],
            fileList1:this.state.evaluatePic1.length?this.state.fileList1: [],
            evaluatePic6:[],
            fileList6:[],
            code:this.state.evaluatePic1.length?1:0
        })
    }
    delOne4= ()=>{
        this.setState({
            evaluatePic4:[],
            fileList4:[],
            code4:0
        })
    }
    delOne5= ()=>{
        this.setState({
            evaluatePic5:[],
            fileList5:[],
            code5:0
        })
    }
    delOne= ()=>{
        this.setState({
            evaluatePic:[],
            fileList:[],
            code6:0
        })
    }
    changeselect = (val) => {
        // console.log(val[0])
        this.setState({
            pharmacistType: val[0]=='西药药师'?1:2
        })
    }

//执业药师资格证
    imgChange4 = async (files, type, index) => {
        const {compressThreshold = 1 } = this.state

        let imgs = Array.from(files)
        let pictureQuality = 0.5
        if (imgs.length>2){
            Toast.show('最多上传两张执业药师资格证照片')
        }else{

            this.setState({
                code:imgs.length
            })
                // console.log(imgs)
            if(type === 'add') {
                // setTimeout(()=>{
                imgs.forEach( (item,index)=>{
                    if (index==0) {
                        let arr=[]
                        arr.push(item)
                        this.firstOne(arr, 'add', 0,compressThreshold)
                    }else if (index==1){
                        let arr1=[]
                        arr1.push(item)
                        this.secondOne(arr1, 'add', 0,compressThreshold)
                    }
                })

                // },500)


            }

        }
    }
    firstOne=async (files, type, index,compressThreshold)=>{
        let imgList = []
        let pictureQuality = 0.5
        let file = files[0].file
        let item=files[0]
        let fileSize = file.size / 1024 / 1024;
        if (file.type=="image/png"||file.type=="image/jpeg"||file.type=="image/jpg") {
            //当开启图片压缩且图片大小大于等于压缩阈值,进行压缩
            if ((fileSize >= compressThreshold) ) {
                if (fileSize*0.9<=1){
                    pictureQuality=0.9
                }else if(fileSize*0.8<=1){
                    pictureQuality=0.8
                }else if(fileSize*0.7<=1){
                    pictureQuality=0.7
                }else if(fileSize*0.6<=1){
                    pictureQuality=0.6
                }else if(fileSize*0.5<=1){
                    pictureQuality=0.5
                }else if(fileSize*0.4<=1){
                    pictureQuality=0.4
                }else if(fileSize*0.3<=1){
                    pictureQuality=0.3
                }else if(fileSize*0.2<=1){
                    pictureQuality=0.2
                }else if(fileSize*0.1<=1){
                    pictureQuality=0.1
                }else{
                    pictureQuality=0.5
                }
                // console.log(pictureQuality)
                //判断浏览器内核是否支持base64图片压缩
                if (typeof (FileReader) === 'undefined') {
                    return file;
                } else {
                    try {
                        this.setState({
                            spinLoading: true
                        });
                        return new Promise(resolve => {
                            //压缩图片再上传
                            let a=getBase64Image(file,pictureQuality,callback1)
                            let that=this
                            function callback1(val) {
                                //base64转png图片
                                zipImg(val).then(res1 => {
                                    // console.log(res1)
                                    let formData = new FormData()
                                    formData.append('file', res1)
                                    uploadpic(formData).then(res2 => {
                                        // console.log(res2)
                                        if(res2.code === 2) {
                                            that.setState({
                                                fileList1: item
                                            })
                                            imgList.push(res2.data)
                                        } else {
                                            Toast.info('上传失败', 1)
                                        }
                                        let arr0=[]
                                        arr0.push(imgList[0])
                                        that.setState({
                                            evaluatePic1: arr0
                                        })
                                        // Toast.hide()
                                    }).catch(err => {
                                        // Toast.hide()
                                    })
                                })
                            }
                        }).then(res => {
                            this.setState({
                                spinLoading: false
                            });
                            // console.log(res)

                            // return res;
                        }).catch(() => {
                            this.setState({
                                spinLoading: false
                            });
                            return file;
                        });
                    } catch (e) {
                        this.setState({
                            spinLoading: false
                        });
                        //压缩出错,直接返回原file对象
                        return file;
                    }
                }
            }else{
                zipImg(file).then(res => {
                    let formData = new FormData()
                    formData.append('file', res)
                    uploadpic(formData).then(res1 => {
                        if(res1.code === 2) {
                            this.setState({
                                fileList1: item
                            })
                            imgList.push(res1.data)
                        } else {
                            Toast.info('上传失败', 1)
                        }
                        let arr0=[]
                        arr0.push(imgList[0])
                        this.setState({
                            evaluatePic1: arr0
                        })
                        // Toast.hide()
                    }).catch(err => {
                        // Toast.hide()
                    })
                })
            }
        }else{
            Toast.show('请上传png/jpg格式的图片', 1)
        }

    }
    secondOne=async (files, type, index,compressThreshold)=>{
        let imgList = []
        let pictureQuality = 0.5
        let file1 = files[0].file
        let item1=files[0]
        // console.log(imgList)
        let fileSize1 = file1.size / 1024 / 1024;
        if (file1.type=="image/png"||file1.type=="image/jpeg"||file1.type=="image/jpg") {
            //当开启图片压缩且图片大小大于等于压缩阈值,进行压缩
            if ((fileSize1 >= compressThreshold) ) {
                if (fileSize1*0.9<=1){
                    pictureQuality=0.9
                }else if(fileSize1*0.8<=1){
                    pictureQuality=0.8
                }else if(fileSize1*0.7<=1){
                    pictureQuality=0.7
                }else if(fileSize1*0.6<=1){
                    pictureQuality=0.6
                }else if(fileSize1*0.5<=1){
                    pictureQuality=0.5
                }else if(fileSize1*0.4<=1){
                    pictureQuality=0.4
                }else if(fileSize1*0.3<=1){
                    pictureQuality=0.3
                }else if(fileSize1*0.2<=1){
                    pictureQuality=0.2
                }else if(fileSize1*0.1<=1){
                    pictureQuality=0.1
                }else{
                    pictureQuality=0.5
                }
                // console.log(pictureQuality)
                //判断浏览器内核是否支持base64图片压缩
                if (typeof (FileReader) === 'undefined') {
                    return file1;
                } else {
                    try {
                        this.setState({
                            spinLoading: true
                        });
                        return new Promise(resolve => {
                            //压缩图片再上传
                            let a=getBase64Image(file1,pictureQuality,callback1)
                            let that=this
                            function callback1(val) {
                                //base64转png图片
                                zipImg(val).then(res1 => {
                                    // console.log(res1)
                                    let formData = new FormData()
                                    formData.append('file', res1)
                                    uploadpic(formData).then(res2 => {
                                        // console.log(res2)
                                        if(res2.code === 2) {
                                            that.setState({
                                                fileList6: item1
                                            })
                                            imgList.push(res2.data)
                                        } else {
                                            Toast.info('上传失败', 1)
                                        }
                                        let arr0=[]
                                        arr0.push(imgList[0])

                                        that.setState({
                                            evaluatePic6: arr0
                                        })
                                        // Toast.hide()
                                    }).catch(err => {
                                        // Toast.hide()
                                    })
                                })
                            }
                        }).then(res => {
                            this.setState({
                                spinLoading: false
                            });
                            // console.log(res)

                            // return res;
                        }).catch(() => {
                            this.setState({
                                spinLoading: false
                            });
                            return file1;
                        });
                    } catch (e) {
                        this.setState({
                            spinLoading: false
                        });
                        //压缩出错,直接返回原file对象
                        return file1;
                    }
                }
            }else{
                zipImg(file1).then(res => {
                    let formData = new FormData()
                    formData.append('file', res)
                    uploadpic(formData).then(res1 => {
                        if(res1.code === 2) {
                            this.setState({
                                fileList6: item1
                            })
                            imgList.push(res1.data)
                        } else {
                            Toast.info('上传失败', 1)
                        }
                        let arr0=[]
                        arr0.push(imgList[0])
                        this.setState({
                            evaluatePic6: arr0
                        })

                        // Toast.hide()
                    }).catch(err => {
                        // Toast.hide()
                    })
                })
            }
        }else{
            Toast.show('请上传png/jpg格式的图片', 1)
        }
    }
    render() {
        const {  pic0, pic1,pic2,pic3,pic4,invalidFields, code,fileList, typea,evaluatePic1,evaluatePic6,evaluatePic4,fileList1, basicColumns, fileList4, fileList5, name, id,sex,pharmacistOrg,pharmacistCertificateNum,

            list, evaluatePic5,evaluatePic,  fileList6,code4, code5, code6, pharmacistCertificateTime, visible2, pharmacistType,visible3,erjidepartment } = this.state


        const isInvalidName = invalidFields.includes("name");
        const isInvalidId = invalidFields.includes("id");
        const isInvalidSex = invalidFields.includes("sex");
        const isPharmacistOrg = invalidFields.includes("pharmacistOrg");
        // console.log(iSPharmacistType,'1354====');
        const isPhaNum = invalidFields.includes("pharmacistCertificateNum");
        const isPharmacistCertificateTime = invalidFields.includes("pharmacistCertificateTime")
        const now = new Date()
        const now1 = new Date('1950-01-01')
        return (
            <div className='uploadbox' 
                ref={this.useRef}
                style={{ overflowY: 'scroll' }}
                onScroll={this.onscroll}
            >
                {/*<div className='box-top'>*/}
                {/*    <img src={icon1} alt="" onClick={()=>this.props.history.push('/')} /><h1>欢迎入驻</h1>*/}
                {/*</div>*/}
                <div className='content-tips'>
                   您提交的信息和资料，将用于身份、医疗资质等核验
                </div>
                <div className='upload-content'>
                    <p className='upload-content-title'>
                        <span className='yuan'></span>
                        <span>基本信息</span>
                    </p>
                    <div className={isInvalidName ? "invalid" : "content-item"}>
                        <p><span>*</span>姓名： </p>
                        <input type="text" placeholder='请输入真实姓名' value={name} onChange={(e) => this.changevalue(e, "name")} />
                    </div>
                    <div className={isInvalidId ? "invalid" : "content-item"}>
                        <p> <span>*</span>身份证号：</p>
                        <input type="tel" placeholder='请输入身份证号' value={id} onChange={(e) => this.changevalue(e, "id")} />
                    </div>
                    <div className={isInvalidSex ? "invalid" : "content-item"}>
                        <p><span>*</span>性别：</p>
                        <div>
                            <span><input type="radio" name="sex" value="男" checked={sex=='男'?1:0} onChange={e=>this.changevalue(e, "sex")} />男</span>
                            <span><input type="radio" name="sex" value="女" checked={sex=='女'?1:0} onChange={e=>this.changevalue(e, "sex")} />女</span>
                        </div>
                    </div>
                    <div className={isPharmacistOrg ? "invalid" : "content-item"}>
                        <p><span>*</span>执业医院： </p>
                        <input type="text" placeholder='请输入您的执业医院' value={pharmacistOrg} onChange={(e) => this.changevalue(e, "pharmacistOrg")} />
                    </div>
                    <div
                        className={pic2 ? "invalid" : "content-item"}
                        onClick={() => this.setState({
                        visible3:true
                    })}
                    >
                        <p><span>*</span>药师类型： </p>
                        <h2>
                            <span className={pharmacistType?'choseks':'nochoseks'} >{pharmacistType ? pharmacistType==1?'西药药师': "中药药师" : "选择药师类型"}</span>
                            <span>＞</span>
                        </h2>
                    </div>

                    <Picker
                        columns={basicColumns}
                        visible={visible3}
                        onClose={() => {
                            this.setState({
                                visible3: false,
                                pic2:false
                            })
                        }}
                        value={pharmacistType==1?'西药药师': pharmacistType==2?"中药药师":''}
                        onConfirm={(val) => this.changeselect(val)}

                    ></Picker>

                    <div className='box-content'>
                        <p className='upload-content-title'>
                            <span className='yuan'></span>
                            <span>资料信息</span>
                        </p>
                        <div className='upload-pic'>
                            <div className='pic-item'>
                                <p className={pic0||pic4 ? "all-one1" : 'all-one'} style={{marginLeft:'0px'}}>
                                <p><span>*</span>上传身份证正反面：</p>
                                </p>
                               <div className='item-img'>
                                        <ImagePicker
                                        files={fileList4}
                                        length={1}
                                        style={{ width: "100px", height: "100px", display: "inline-block", verticalAlign: "top" }}
                                        // fileTypeLimit={fileTypeList}
                                        onChange={this.imgChange1}
                                        selectable={fileList4.length < 1}
                                        isPictureCompress={true} //是否开启图片压缩
                                        // pictureQuality={0.5}   //图片质量
                                        compressThreshold={1}  //压缩阈值
                                    />
                                    {list&&code4?<img src={evaluatePic4} style={{width:'138px',height:'93px',marginLeft:'-152px',marginTop:'11px'}} alt="" />:null}
                                   {list&&code4?<span style={{
                                       width: "15px",
                                       height: "15px",
                                       position:'absolute',
                                       top:'370px',
                                       borderRadius:'50%',
                                       zIndex:'99',
                                       marginLeft:'-20px',
                                       backgroundColor:'rgba(0,0,0,.3)'
                                   }} onClick={()=>this.delOne4()}>
                                           <img style={{
                                               width: "9px",
                                               height: "9px",
                                               marginLeft:'3px',
                                               marginTop:'3px',
                                           }} src={icon2}/>
                                       </span>:null}
                                       <ImagePicker
                                        files={fileList5}
                                        length={1}
                                        style={{  display: "inline-block", verticalAlign: "top" }}
                                        onChange={this.imgChange2}
                                        selectable={fileList5.length < 1}
                                        accept='image/gif,image/jpeg,image/jpg,image/png' />
                                   {list&&code5?<img src={evaluatePic5} style={{width:'138px',height:'93px',marginLeft:'-152px',marginTop:'11px'}} alt="" />:null}
                                   {list&&code5?<span style={{
                                           width: "15px",
                                           height: "15px",
                                           position:'absolute',
                                           marginTop:'15px',
                                           borderRadius:'50%',
                                           zIndex:'99',
                                           marginLeft:'-20px',
                                           backgroundColor:'rgba(0,0,0,.3)'
                                       }} onClick={()=>this.delOne5()}>
                                                <img style={{
                                                    width: "9px",
                                                    height: "9px",
                                                    marginLeft:'3px',
                                                    marginTop:'3px',
                                                }} src={icon2}/>
                                            </span>:null}
                               </div>
                            </div>

                            <div className='pic-item'>
                                <p className={ isPhaNum ? "all-zyzgzs-invalid" : 'all-zyzgzs'} style={{marginLeft:'0px'}}>
                                    <p><span>*</span>执业药师资格证书：</p>
                                    <p>上传完整资格证书(最多2张)</p>
                                </p>
                                              <div className={isPhaNum ? "certificate-item-inva" : "certificate-item"}>
                                    <input type="text" placeholder='输入执业资格证号' value={pharmacistCertificateNum}
                                           onChange={(e) => this.changevalue(e, "pharmacistCertificateNum")}/>
                                </div>
                                <div className='qc-time'>
                                    <span className={pic3 ? "qc-time-tips1" : "qc-time-tips"}>资格证书发证日期：</span>

                                    <span className={pic1 ? "qc-time-select1" : "qc-time-select"} onClick={()=>this.setState({
                                        visible2:true
                                    })}>
                                   <span className={pharmacistCertificateTime?'time time1':'time'}>{pharmacistCertificateTime?pharmacistCertificateTime:'选择日期'}</span>
                                   <span className='dy'>＞</span>

                               </span>
                            </div>
                            <div className='item-img'>
                                    <ImagePicker
                                        uploadType={'file'}
                                        files={fileList1}
                                        multiple={true}
                                        length={1}
                                        style={{
                                            // width: "100px",
                                            // height: "100px",
                                            // display:'flex',
                                            display: "inline-block",
                                            verticalAlign: "top"
                                        }}
                                        // fileTypeLimit={fileTypeList}
                                        onChange={this.imgChange8}
                                        selectable={fileList1.length < 1}
                                        isPictureCompress={true} //是否开启图片压缩
                                        // pictureQuality={0.5}   //图片质量
                                        compressThreshold={1}  //压缩阈值
                                    />
                                    {list&&code?<img src={evaluatePic1} style={{width:'138px',height:'93px',marginLeft:'-152px',marginTop:'11px'}} alt="" />:null}
                                {list&&code?<span style={{
                                    width: "15px",
                                    height: "15px",
                                    position:'absolute',
                                    marginTop:'15px',
                                    borderRadius:'50%',
                                    zIndex:'99',
                                    marginLeft:'-20px',
                                    backgroundColor:'rgba(0,0,0,.3)'
                                }} onClick={()=>this.delOne1()}>
                                       <img style={{
                                           width: "9px",
                                           height: "9px",
                                           marginLeft:'3px',
                                           marginTop:'3px',
                                       }} src={icon2}/>
                                   </span>:null}
                                {evaluatePic1.length?<>
                                       <ImagePicker
                                           uploadType={'file'}
                                           files={fileList6}
                                           // multiple={true}
                                           length={1}
                                           style={{
                                               // width: "100px",
                                               // height: "100px",
                                               // display:'flex',
                                               display: "inline-block",
                                               verticalAlign: "top"
                                           }}
                                           // fileTypeLimit={fileTypeList}
                                           onChange={this.imgChange6}
                                           selectable={fileList6.length < 1}
                                           isPictureCompress={true} //是否开启图片压缩
                                           // pictureQuality={0.5}   //图片质量
                                           compressThreshold={1}  //压缩阈值
                                       />
                                       {list&&code&&evaluatePic6.length?<img src={evaluatePic6} style={{width:'138px',height:'93px',marginLeft:'-152px',marginTop:'11px'}} alt="" />:null}
                                        {list&&code&&evaluatePic6.length?<span style={{
                                            width: "15px",
                                            height: "15px",
                                            position:'absolute',
                                            marginTop:'15px',
                                            borderRadius:'50%',
                                            zIndex:'99',
                                            marginLeft:'-20px',
                                            backgroundColor:'rgba(0,0,0,.3)'
                                        }} onClick={()=>this.delOne2()}>
                                           <img style={{
                                               width: "9px",
                                               height: "9px",
                                               marginLeft:'3px',
                                               marginTop:'3px',
                                           }} src={icon2}/>
                                       </span>:null}
                                   </>:null}
                                </div>
                            </div>
                            <div className='pic-item'>
                                <p className='all-zyzgzs' style={{marginLeft:'0px'}}>
                                    <p>执业药师注册证（非必填）:</p>
                                    <p></p>
                                </p>
                                <div className='item-img'>
                                        <ImagePicker
                                        files={fileList}
                                        length={1}
                                        style={{ width: "100px", height: "100px", display: "inline-block", verticalAlign: "top" }}
                                        onChange={this.imgChange3}
                                        selectable={fileList.length < 1}
                                        accept='image/gif,image/jpeg,image/jpg,image/png' />
                                {list&&code6?<img src={evaluatePic} style={{width:'138px',height:'93px',marginLeft:'-152px',marginTop:'11px'}} alt="" />:null}

                                {list&&code6?<span style={{
                                            width: "15px",
                                            height: "15px",
                                            // position:'relative',
                                            marginTop:'15px',
                                            borderRadius:'50%',
                                            zIndex:'99',
                                            marginLeft:'-20px',
                                            backgroundColor:'rgba(0,0,0,.3)'
                                        }} onClick={()=>this.delOne()}>
                                            <img style={{
                                            width: "9px",
                                            height: "9px",
                                            marginLeft:'3px',
                                            marginTop:'3px',
                                        }} src={icon2}/>
                                            </span>:null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='content-btn'>
                        <button onClick={this.uploaddata}>下一步</button>
                    </div>
                </div>
                <DatePicker
                    // title='时间选择'
                    visible={visible2}
                    onClose={() => {
                        this.setState({
                            visible2: false,
                        })
                    }}
                    defaultValue={now}
                    max={now}
                    min={now1}
                    precision='day'
                    onConfirm={value => {
                        const num2 = new Date(now).getFullYear()+'-' +((new Date(now).getMonth() + 1) > 9 ? (new Date(now).getMonth() + 1) : '0' + (new Date(now).getMonth() + 1))
                        // if (time20) {
                        const num = new Date(value).getFullYear() +'-'+ ((new Date(value).getMonth() + 1) > 9 ? (new Date(value).getMonth() + 1) : '0' + (new Date(value).getMonth() + 1))
                        this.setState({
                            pharmacistCertificateTime: new Date(value).getFullYear() + '-' + ((new Date(value).getMonth() + 1) > 9 ? (new Date(value).getMonth() + 1) : '0' + (new Date(value).getMonth() + 1))
                                + '-' + (new Date(value).getDate()> 9 ? new Date(value).getDate() : '0' + new Date(value).getDate())
                        })
                    }}
                />
                {typea?<div className='chat-center-outer'>
                    <SpinLoading />
                    <p>loading...</p>
                </div>:null}
            </div>
        )
    }
}

