
import { axiosInstance } from '../config'

//关注药品店铺
export const fllowRequest = (data) => {
    return axiosInstance.post('/api/hosp/follow/follow', data)
}

//查询收藏列表
export const collectlistRequest = (data) => {
    return axiosInstance.get(`/api/hosp/follow/list?type=${data.type}&userId=${data.userId}&page=${data.page}&size=${data.size}`)
}

//删除收藏
export const deletecollectRequest = (data) => {
    return axiosInstance.post('/api/hosp/follow/delete', data)
}
// 加入收藏
export const addcollectRequest = (data) => {
    return axiosInstance.post('/api/hosp/follow/add', data)
}