import React, { Component } from 'react'
import {NavLink} from "react-router-dom"
import icon1 from "../../static/img/icon/user.png";
import './style.less'
export default function Patientnews(props) {
    const { news} = props
    let item=news
    // console.log(item)
    return(
        <div>
            <p className='offaddcf-user-outer'>
                <p className='offaddcf-user-title'>
                    <span></span>
                    <span>患者信息</span>
                </p>
                {item.consultation_stu=='-1'?<p className='dai-jz'>待接诊</p>:null}
            </p>

            <div className='offaddcf-user-news'>
                <p className='offaddcf-news'>
                        <span className='offaddcf-news-left'>
                            <img src={icon1}/>
                            <span>{item.patientName}&nbsp; &nbsp;({item.patientSex == 1 ? '男' : '女'}/{item.patient_age}岁)</span>
                        </span>
                    <span
                        className='offaddcf-news-right'>电话：{item.patient_phone}</span>
                </p>
                <p className='xu-line'></p>
                {/*<p className='offaddcf-news-address'>*/}
                {/*    <span>身份证号：{item.patientIdcardNum?item.patientIdcardNum:'无'}</span>*/}
                {/*    <span></span>*/}
                {/*</p>*/}
                {/*<p className='xu-line'></p>*/}
                <p className='offaddcf-gan-shen'>
                        <span>是否医保统筹：<span
                            className={item.medical_nsurance_coordination == 1 ? '' : 'blue'}>{item.medical_nsurance_coordination == 1 ? '是' : '否'}</span></span>
                    <span>身份证号：<span
                        className={item.patientIdcardNum? '' : 'blue'}>{item.patientIdcardNum ? item.patientIdcardNum:'无'}</span></span>
                </p>
                <p className='xu-line'></p>
                <p className='offaddcf-gan-shen'>
                    <span>肝功能：<span
                        className={item.abnormal_liver_function == 0 ? '' : 'blue'}>{item.abnormal_liver_function == 0 ? '无异常' : '有异常'}</span></span>
                    <span>肾功能：<span
                        className={item.abnormal_renal_function == 0 ? '' : 'blue'}>{item.abnormal_renal_function == 0 ? '无异常' : '有异常'}</span></span>
                </p>
                <p className='xu-line'></p>
                <p className='offaddcf-news-ill'>既往史：<span
                    className={item.past_history == 0 ? '' : 'blue'}>{item.past_history_note}</span></p>
                <p className='xu-line'></p>
                <p className='offaddcf-news-ill'>过敏史：<span
                    className={item.allergic_history == 0 ? '' : 'blue'}>{item.allergic_history_note}</span></p>
                <p className='xu-line'></p>
                <p className='offaddcf-news-ill'>家族遗传史：<span
                    className={item.family_genetic_history == 0 ? '' : 'blue'}>{item.family_genetic_history_note}</span>
                </p>
            </div>
        </div>
    )
}