
import React, { Component } from 'react'
import { Toast } from "antd-mobile"
import { getuserinfo, changePwdRequest, getmd5 } from "../../../../api/request/user"
import icon from "../../../../static/img/mine/fanhui.png"
import "./styless.less"
import icon2 from "../../../../static/img/mine/open.png"
import icon3 from "../../../../static/img/mine/close.png"
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            userAcount: localStorage.type == 1 ? localStorage.userAcount1 : localStorage.type == 2 ? localStorage.userAcount2 : localStorage.userAcount3,
            pharmacyId: localStorage.pharmacyId,
            value1: "",
            value2: "",
            value3: "",
            pass: "",
            code1:false,
            code2:false,
            code3:false
        }
    }
    componentDidMount = async () => {
        const { userAcount } = this.state
        const res = await getuserinfo({
            userAcount: userAcount
        })
        this.setState({
            pass: res.data[0][0].userpassword
        })
    }
    //动态赋值密码
    getpass1 = (e) => {
        //console.log(e.target.value)
        this.setState({
            value1: e.target.value
        })
    }
    getpass2 = (e) => {
        //console.log(e.target.value)
        this.setState({
            value2: e.target.value
        })
    }
    getpass3 = (e) => {
        //console.log(e.target.value)
        this.setState({
            value3: e.target.value
        })
    }
    //控制密码显示隐藏
    changecode1=()=>{
        this.setState({
            code1:!this.state.code1
        })
    }
    changecode2=()=>{
        this.setState({
            code2:!this.state.code2
        })
    }
    changecode3=()=>{
        this.setState({
            code3:!this.state.code3
        })
    }
    changepass = async () => {
        const { value1, value2, value3, pass, userAcount } = this.state
        if (pass) {
            //console.log(value1, value2, value3);
            if (value1!=""&value2!=""&value3!="") {
                const res2 = await getmd5({
                    content: value1
                })
    
                if (pass == res2.data) {
                    if (value2 == value3) {
                        //console.log(value2)
                        const res = await changePwdRequest({
                            userAcount: userAcount,
                            userPwd: value2
                        })
                        //console.log(res)
                        Toast.success("修改成功")
                        this.props.history.push("/mine")
                    } else {
                        Toast.fail("两次新密码不同")
                    }

                } else {
                    Toast.fail("原密码输入错误")
                }
            } else {
                Toast.fail("请将信息输入完整")
            }
        } else {

            if (value1 & value2) {
                if (value1 == value2) {
                    const res = await changePwdRequest({
                        userAcount: userAcount,
                        userPwd: value1
                    })

                    Toast.success("修改成功")
                    this.props.history.push("/mine/set")
                } else {
                    Toast.fail("两次密码不相同")
                }
            } else {
                Toast.fail("请将信息输入完整")
            }
        }
    }
    back = () => {
        window.history.back()
    }
    render() {
        const { value1, value2, value3, pass ,code1,code2,code3} = this.state
        return (
            <div className="passbox">
                <div className="passbox-top">
                    <img src={icon} alt="" onClick={this.back} />
                    <span>{pass ? "修改密码" : "添加密码"}</span>
                </div>
                <div className="passbox-text">
                    <div>
                        <span>{pass ? "原密码" : "新密码"}</span><input type={code1?"text":"password"} placeholder="请填写新密码" value={value1} onChange={(e) => this.getpass1(e)} />
                        <img src={code1?icon2:icon3} alt="" onClick={this.changecode1} />
                    </div>
                </div>
                {pass ? <div className="passbox-text">
                    <div>
                    <img src={code2?icon2:icon3} alt="" onClick={this.changecode2} />
                        <span>新密码</span><input type={code2?"text":"password"} placeholder="请再次输入新密码" value={value3} onChange={(e) => this.getpass3(e)} />
                    </div>
                </div> : null}
                <div className="passbox-text" style={{ marginBottom: "40px" }}>
                    <div>
                    <img src={code3?icon2:icon3} alt="" onClick={this.changecode3} />
                        <span>确认密码</span><input type={code3?"text":"password"} placeholder="请再次输入新密码" value={value2} onChange={(e) => this.getpass2(e)} />
                    </div>
                </div>
                {pass?<span className="Rpass" onClick={()=>this.props.history.push("/login/retrieveone")}>找回密码</span>:null}
                <button className={value1 || value2 ? "passbox-btn2" : "passbox-btn1"} onClick={this.changepass}>确认</button>
            </div>
        )
    }
}

