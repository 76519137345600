import { axiosInstance,postLogin } from '../config'

//获取登录验证码
export const getlocode = (data) => {
    return axiosInstance.post('/api/hosp/doctor_manage/loginver?forward_topic=oplog-v1', data)
}
//获取注册验证码
export const getrecode = (data) => {
    return axiosInstance.post('/api/hosp/doctor_manage/registersms', data)
}
//查看账户是否存在
export const inspectonly = (docterPhone) => {
    return axiosInstance.get(`/api/hosp/doctor_manage/only?docterPhone=${docterPhone}`)
}
//验证码验证
export const verificationcode = (userPhone,smsCode) => {
    return axiosInstance.get(`/api/hosp/doctor_manage/verify?userPhone=${userPhone}&smsCode=${smsCode}`)
}

//药师短信注册
export const register = (data) => {
    return axiosInstance.post('/api/hosp/doctor_manage/smsregister', data)
}
//登录
export const userlogin = (data) => {
    return postLogin('/api/hosp/doctor_manage/smslogin', data)
}


//获取药师信息
export const getuserinfo = (data) => {
    return axiosInstance.post('/api/hosp/wzpharmacist/get-pharmacistinfo', data)
}
//获取医生审核信息
export const getdoctorsh = (docterId) => {
    return axiosInstance.get(`/api/hosp/doctor_manage/one-qualifications?id=${docterId}`)
}
// 更新失效验证码状态 
export const updatesxVerify = (userPhone) => {
    return axiosInstance.get(`/api/hosp/wzpatient/updatesx-verify?userPhone=${userPhone}`)
}
