import React, { Component } from 'react'
import {DatePicker, Popup, Steps, } from "antd-mobile-v5"
import { Tabs,Switch } from 'antd-mobile'
import { Link, withRouter } from 'react-router-dom'
import './style.less'
import getUrl from '../../../../component/GetUrlParam'
import {allCount,allDutyconsultation,allDutyRefuseList} from "../../../../api/hospitalapi/duty";
import {allMedicinal,prescribingDetail} from "../../../../api/hospitalapi/news";
import {caWrite} from "../../../../api/hospitalapi/home";
import {Modal,Toast} from "antd-mobile";
import moment from 'moment'
import Nodata from "../../../../component/Nodata";
import icon1 from '../../../../static/img/yy/fanhuihei.png'
import icon2 from '../../../../static/img/duty/wznews.png'
import icon5 from '../../../../static/img/vedio-icon.png'
import Patientnews from "../../../../component/offline";
const { Step } = Steps
const { alert } = Modal
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            docterId: localStorage.getItem("docterId"),
            code: 1,
            dateList: '',
            browseList: '',
            page: 1,
            size: 20,
            countallNum: 0,//接诊总数
            countNum: 0,
            createTime: '',
            prescribingState: '',
            visible: false,
            flag: false,
            listsum: '',
            content: '',
            initialHeight:localStorage.getItem('initialHeight') ? localStorage.getItem('initialHeight') : '',// 页面初始高度
            finalHheight:localStorage.getItem('finalHheight') ? localStorage.getItem('finalHheight') : '',// 最终传递高度
        }
    }

    componentDidMount = async () => {
        const {size, dateList, docterId,initialHeight,finalHheight,content,createTime} = this.state
        this.getAllList()
        // if(finalHheight>0){
        //     // 获取缓存中的 finalHeight
        //     const cachedFinalHeight = finalHheight;
        //     const newPage= Math.ceil(finalHheight / initialHeight);
        //     console.log('newPage',newPage);
        //     // this.setState({
        //     //     page:newPage
        //     // })

        //     const params = {
        //         size: size,
        //         page:newPage,
        //         content: content,
        //         createTime: createTime ? createTime : ''
        //     }
        //     allDutyconsultation(params).then(res => {
        //         console.log(res,'61')
        //         if (res.code === 1) {
        //             let data1 = []
        //             if (res.data && res.data[0] && res.data[0].length > 0) {
        //                 if (res.data[1] && res.data[1].length) {
        //                     this.setState({
        //                         listsum: res.data[1]
        //                     })
        //                     data1 = res.data[1]
        //                 }
        //                 const data = res.data[0]
        //                 this.resetBrowseList(data, 'limit', data1)
        //                 this.setState({
        //                     page: params.page
        //                 })
        //             }
        //         }
        //     })
        // }else{
            
        // }

        

    }
      
    resetBrowseList = (data, type, data1) => {
        const {page, dateList, browseList, code} = this.state
        //获取日期
        const year = moment().year() + ''
        const month = moment().month() + 1 < 10 ? '0' + (moment().month() + 1) : '' + (moment().month() + 1)
        const date = moment().date() < 10 ? '0' + moment().date() : '' + moment().date()
        const browseDate = year + month + date
        let newData = type === 'get' ? data : [...browseList, ...data]
        let newDateList = [...dateList]
        newData.forEach(item => {
            item.checked = false
            item.createTime0 = item.reception_time.slice(0, 4) + '-' + item.reception_time.slice(5, 7) + '-' + item.reception_time.slice(8, 10)
        })
        for (let i = 0; i < newData.length; i++) {
            for (let j = i + 1; j < newData.length; j++) {
                if (newData[i].reception_time === newData[j].reception_time) {
                    ++i
                }
            }
            newDateList.push({
                createTime: newData[i].createTime0,
                allCount: newData[i].allCount,
                successCount: newData[i].successCount,
                state: 0
            })
        }
        let obj = {}
        newDateList = [...newDateList]
        newDateList = newDateList.filter((x, index, self) => {
            var arrids = []
            newDateList.forEach((item, i) => {
                arrids.push(item.createTime)
            })
            return arrids.indexOf(x.createTime) === index
        })
        newDateList.map(item => {
            data1.map(i => {
                if (item.createTime == i.day) {
                    item.count = i.num
                }
            })
        })
        let arrData = []
        newDateList.map(item => {
            newData.map(j => {
                if (item.createTime == j.createTime0) {
                    item.state = 1
                    arrData.push(item)
                }
            })
        })
        let arrData1 = arrData.filter((currentValue, currentIndex, selfArr) => {
            return selfArr.findIndex(x => x.createTime === currentValue.createTime) === currentIndex
        });
        this.setState({
            dateList: [...arrData1],
            browseList: [...newData],
            flag: false
        })
    }
    getAllList = async () => {
        const {size, page, docterId, content, createTime} = this.state
        const params = {
            page: 1,
            size: size,
            content: content,
            createTime: createTime ? createTime : ''
        }
        let res = await allDutyRefuseList(params)
        // console.log(res)
        let data1 = []
        if (res && res.data && res.data[0] && res.data[0].length) {
            if (res.data[1] && res.data[1].length) {
                this.setState({
                    listsum: res.data[1]
                })
                data1 = res.data[1]
            }
            let data = res.data[0]

            this.resetBrowseList(data, 'get', data1)
        } else {
            this.setState({
                dateList: [],
                browseList: [],
            })
        }

    }
    // 触底更新数据
    onscroll = e => {
        const {flag,page,initialHeight,finalHheight} = this.state
        e.preventDefault()
        e.stopPropagation()

        // this.setState({
        //     initialHeight:page==1 ? e.target.scrollHeight : initialHeight,
        //     finalHheight:e.target.scrollTop,
        // },()=>{
        //     localStorage.setItem('initialHeight',initialHeight)
        //     localStorage.setItem('finalHheight',finalHheight)
        // })

        if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 5) {
            if (!flag) {
                this.pageBrowselist()
            }
        }
        
    }
    // 触底时换页
    pageBrowselist = () => {
        const {size, page, docterId, content, createTime} = this.state
        this.setState({
            flag: true
        }, () => {
            const params = {
                size: size,
                page: page + 1,
                content: content,
                createTime: createTime ? createTime : ''
            }
            allDutyRefuseList(params).then(res => {
                // console.log(res)
                if (res.code === 1) {
                    let data1 = []
                    if (res.data && res.data[0] && res.data[0].length > 0) {
                        if (res.data[1] && res.data[1].length) {
                            this.setState({
                                listsum: res.data[1]
                            })
                            data1 = res.data[1]
                        }
                        const data = res.data[0]
                        this.resetBrowseList(data, 'limit', data1)
                        this.setState({
                            page: params.page
                        })
                    }
                }
            })
        })
    }
    gobank = () => {
        window.history.back()
    }
    changeuserinfo = (e) => {
        this.setState({
            content: e.target.value.replace(/\s*/g, "")
        })
    }
    onSearch = () => {
        this.getAllList()
    }
    setTimenull = () => {
        this.setState({
            createTime: '',
            browseList: [],
            dateList: []
        }, () => {
            this.getAllList()
        })
    }

    render() {
        const {
            code, dateList, browseList, countallNum, visible, createTime,
            countNum,initialHeight,finalHheight
        } = this.state
        const now = new Date()
        const now1 = new Date('2022-01')
        return (
            <>
                <div className='accountbox dutyaccountbox'>
                    <div className='statistics-content11'>
                        <div className='register-headoff'>
                            <i className='iconfont icon-sousuo'></i>
                            <input type="text" placeholder='搜索患者名字' onChange={(e) => this.changeuserinfo(e)}/>
                            <span onClick={() => this.onSearch()}>搜索</span>
                        </div>
                        <p className='statistics-content-bottom-right1' onClick={() => this.setState({
                            visible: true
                        })}
                        >
                            <span>{createTime ? createTime : '日期选择'}</span>
                            <i className='iconfont icon-xiala'/>
                        </p>
                        <p className='dutyaccountbox-null' onClick={() => this.setTimenull()}>重置</p>
                    </div>
                    <div className='duty-bgc'></div>

                    {dateList ? <div className='dutyaccountbox-body' onScroll={(e) => this.onscroll(e)}>
                        {dateList.length > 0 ?
                            <div className='dutystatistics-all-outer'>
                                {dateList.map((item, index) => {
                                    return (
                                        <div key={index} className='dutystatistics-all-details'>
                                            <div className='dutystatistics-all-details-date'>
                                                {item.createTime} 接诊总数：{item.count}
                                            </div>
                                            {browseList.map((v, i) => {
                                                return (item.createTime === v.createTime0 ?
                                                        <div key={i}>
                                                            <div className='dutystatistics-all-details-news'
                                                                 onClick={() => this.props.history.push(`/dutydetail?consultationId=${v.consultation_id}&prescribingId=${v.prescribingId}&dutyconsultation=${2}&dutycode=${2}`)}>
                                                                <p className='dutystatistics-state'>
                                                                    <div>
                                                                        <span
                                                                            className='ill-news'>患者：{v.patientName} ({v.patientSex == 1 ? '男' : '女'}/{v.patient_age}岁)</span>
                                                                        {v.patientIdcardNum ?
                                                                            <i className='iconfont icon-emishenfenzheng'/> : null}
                                                                    </div>
                                                                    {v.prescribingState == 1 ?
                                                                        <span className='dutytips pass1'>开方成功</span> :
                                                                        v.prescribingState == -1 ?
                                                                            <span
                                                                                className='dutytips nopass1'>处方驳回</span> :
                                                                            v.prescribingState == 0 ?
                                                                                <span
                                                                                    className='dutytips await'>等待审核</span> :
                                                                                !v.prescribingState && v.consultation_stu != '3' ?
                                                                                    <span
                                                                                        className='dutytips refuse'>未开方</span> :
                                                                                    v.consultation_stu == '3' ?
                                                                                        <span
                                                                                            className='dutytips refuse'>医生拒诊</span> : null
                                                                    }
                                                                </p>

                                                                <p className='doc-pha-name'>
                                                                    <span className='doc'>
                                                                        <span>医生：{v.docter_name}</span>
                                                                        {v.is_ai == 1 ?
                                                                            <i className='iconfont icon-jiqiren1'/> : null}
                                                                    </span>
                                                                    {v.pharmacist_name ? <span className='doc'>
                                                                        <span>药师：{v.pharmacist_name}</span>
                                                                    </span> : null}
                                                                </p>
                                                                <p className='dutystatistics-pass-date' style={{marginBottom:'5px'}}>
                                                                    门店：{v.pharmacy_name}
                                                                </p>
                                                                <p className='dutystatistics-pass-date-box'>
                                                                    <span>接诊时间：{v.reception_time}</span>
                                                                    {v.consultation_type == '3' ? <img src={icon5} /> : null}
                                                                </p>
                                                            </div>
                                                        </div> : null
                                                )
                                            })}
                                        </div>
                                    )
                                })
                                }
                            </div> : <Nodata type='单量统计'/>
                        }
                        <DatePicker
                            // title='时间选择'
                            visible={visible}
                            onClose={() => {
                                this.setState({
                                    visible: false,
                                })
                            }}
                            defaultValue={now}
                            max={now}
                            min={now1}
                            precision='day'
                            onConfirm={value => {
                                const num2 = new Date(now).getFullYear() + '-' + ((new Date(now).getMonth() + 1) > 9 ? (new Date(now).getMonth() + 1) : '0' + (new Date(now).getMonth() + 1))
                                // if (time20) {
                                const num = new Date(value).getDate() + '-' + ((new Date(value).getMonth() + 1) > 9 ? (new Date(value).getMonth() + 1) : '0' + (new Date(value).getMonth() + 1))
                                this.setState({
                                    page: 1,
                                    dateList: [],
                                    createTime: new Date(value).getFullYear() + '-' + ((new Date(value).getMonth() + 1) > 9 ? (new Date(value).getMonth() + 1) : '0' + (new Date(value).getMonth() + 1)) + '-' + ((new Date(value).getDate()) > 9 ? (new Date(value).getDate()) : '0' + (new Date(value).getDate()))
                                }, () => {
                                    this.getAllList()
                                })
                            }}
                        />
                    </div> : null}
                    <div className='return-fh' onClick={() => this.props.history.push(`/homeduty`)}>
                        <img src={icon1} />
                        <span>返回</span>
                    </div>
                </div>
            </>

        )
    }
}