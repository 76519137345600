
import { axiosInstance } from '../config'
//修改库存
export const changenum = (data) => {
    return axiosInstance.post(`/api/hosp/erp-base/update-stock`,data)
}

//查询订单列表
export const oderlistRequest = (data) => {
    return axiosInstance.post('/api/hosp/order/list', data)
}

//添加订单
export const addorderRequest = (data) => {
    return axiosInstance.post('/api/hosp/order/add', data)
}

//查询订单详情
export const oderdetailRequest = (orderNumber) => {
    return axiosInstance.get(`/api/hosp/order/details?orderNumber=${orderNumber}`)
}

//删除订单
export const delOrderRequest = (orderNumber) => {
    return axiosInstance.get(`/api/hosp/order/delete?orderNumber=${orderNumber}`)
}

//修改订单
export const updateOrderRequest = (data) => {
    return axiosInstance.post('/api/hosp/order/update', data)
}

//添加订单药品
export const addordermedRequest = (data) => {
    return axiosInstance.post('/api/hosp/order/addmed', data)
}

// 更新订单状态
export const upOrderStuRequest = (data) => {
    return axiosInstance.post(`/api/hosp/order/updatestu`,data)
}

// 申请退款
export const applyRefundRequest = (data) => {
    return axiosInstance.post(`/api/hosp/order/apply_refund`, data)
}
// 订单部分药品退款 
export const applyPartRequest = (data) => {
    return axiosInstance.post(`/api/hosp/order/refund_med`, data)
}
// 取消申请退款
export const cancelRefundRequest = (orderNumber) => {
    return axiosInstance.get(`/api/hosp/order/cancel_refund?orderNumber=${orderNumber}`)
}

// 用药人添加
export const addPharmacyUser = (data) => {
    return axiosInstance.post(`/api/hosp/user/adddrug`, data)
}


//中通取消订单
export const cancelZTorder = (data) => {
    return axiosInstance.post(`/faas/hosp/erp-base/zt-quorder`, data)
}

//添加订单汇款凭证
export const addRemittance = (data) => {
    return axiosInstance.post(`/api/hosp/order/add-remittance`, data)
}

//获取订单转账凭证
export const remittanceList = (data) => {
    return axiosInstance.post(`/api/hosp/order/remittance-list`, data)
}

//删除订单汇款凭证
export const delRemittance = (data) => {
    return axiosInstance.post(`/api/hosp/order/del-remittance`, data)
}

//获取默认发票模板
export const getDefaultinvoice = (data) => {
    return axiosInstance.post(`/api/hosp/invoice/get-default-invoice`, data)
}

//获取圆通物流信息
export const ytGetwl = (data) => {
    return axiosInstance.post(`/faas/hosp/erp-base/yt-getwl`, data)
}

//获取京东物流信息
export const jdGetwl = (data) => {
    return axiosInstance.post(`/faas/hosp/erp-base/jd-sjgettrajector`, data)
}

//获取申通物流信息
export const stGetwl = (data) => {
    return axiosInstance.post(`/faas/hosp/erp-base/st-getlogistics`, data)
}
//更新部分商品退款状态
export const updatePartrefundState = (data) => {
    return axiosInstance.post(`/api/hosp/order/update-partrefund-state`, data)
}

//查询蜂鸟订单详情
export const geteleorder = (data) => {
    return axiosInstance.post(`/faas/hosp/erp-base/get-details`, data)
}
// 获取默认发票名称
export const getDefaultname = (data) => {
    return axiosInstance.post(`/api/hosp/invoice/get-defaultname`, data)
}
