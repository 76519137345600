import React, { Component } from 'react'
import "./style.less"
import {Toast,} from "antd-mobile"
// import { TextArea } from "antd-mobile-v5"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/head.png"
import icon3 from "../../../static/img/doctor/right.png"
import { getuserinfo } from "../../../api/hospitalapi/login"
import {changedoctor} from "../../../api/hospitalapi/mine"
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            doctor: {},
            docterProfile:"",
            docterExpert:"",
            docterId:localStorage.getItem("pharmacistId"),
            docterAvatar:localStorage.getItem("pharmacistAvatar")
        }
    }
    componentDidMount = async () => {
        //获取医生信息
        const res = await getuserinfo({
            docterPhone: localStorage.getItem("pharmacistPhone")
        })

        if (res.data[0].length) {
            this.setState({
                doctor: res.data[0][0],
                docterProfile: res.data[0][0].docter_profile,
                docterExpert: res.data[0][0].docter_expert
            })
        }

    }
    gobank = () => {
        window.history.back()
    }
    changevalue=(code,val)=>{
        // console.log(val.target.value);
       if(code==1){
           this.setState({
            docterProfile:val.target.value
           })
       }else{
        this.setState({
            docterExpert:val.target.value
           })
       }
    }
    // 修改资料
    upload=async()=>{
        const {docterProfile,docterExpert,docterId} =this.state
        const res=await changedoctor({
            docterProfile,
            docterExpert,
            docterId
        })
        if(res&&res.code==1){
            Toast.success("修改成功",1)
            this.props.history.push('/mine')
        }
    }
    render() {
        const { doctor,docterProfile,docterExpert,docterAvatar } = this.state
        return (
            <div className='userinfobox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={this.gobank} /><h1>个人信息</h1>
                </div>
                <div className='box-item'>
                    <div>头像</div>
                    <div onClick={()=>this.props.history.push("/mine/userinfo/docterpic")}>
                        {/* <img src={!doctor ? icon2 : doctor.docter_avatar ? doctor.docter_avatar : icon2} alt="" /> */}
                        <img src={docterAvatar!='null'?docterAvatar:icon2} alt="" />
                        <img src={icon3} alt="" />
                    </div>
                </div>
                <div className='box-item'>
                    <div>姓名</div>
                    <div>
                        {!doctor ? "李亚琴" : doctor.docter_name}
                    </div>
                </div>
                <div className='box-item'>
                    <div>身份</div>
                    <div>执业药师</div>
                </div>
            </div>
        )
    }
}
