import React, { Component } from 'react'
import { Popup,ImageViewer,SpinLoading } from "antd-mobile-v5"
import {Toast, Pagination, Modal,} from 'antd-mobile'
import html2canvas from "html2canvas";
// import Vconsole from "../../../api/vconsole";
import icon1 from "../../../static/img/yy/fanhuihei.png"
// import Pdf from '@mikecousins/react-pdf'
import "./style.less"
import {getPdfprescribing,isCapwd,qualificationsMedExamine,addOrderMed,newPrescribing,lsupdatestu,getOrdersate,getOrderisZiti,updateState,medDetails,generateOrder} from "../../../api/hospitalapi/pharmacist";
import {allMedicinal, prescribingDetail,getConsultationType,getnewslist,nowdayAllorder,isChineseMeds,chineseMethod} from "../../../api/hospitalapi/news";
import {getSignature,caWrite} from "../../../api/hospitalapi/home";
import {getuserinfo} from "../../../api/hospitalapi/login";
import { uploadImg } from '../../../api/request/upload'
import {
  prescribingPrice,
  updatephaSignaturetime,
  phaSignature,
  belongStore,
  updateEprescrib,
  selectPharmaIdStaff,
  selectPharmaIdPha,
  phastaffSignature,
  selectFiltering,
  phaSigna,
  isPhaExamine,
  selectPrescPhaSign,
  selectPhaOrStore,
  pendingPrescriptionList,
  pendingPrescriptionListStore,
  selectPlatformPharmacist,
  selectSignaturePic,
  updateAllocate,
  selectExcessNumber,
  selectIsAiLogo
} from "../../../api/hospitalapi/offline";
import getUrl from "../../../component/GetUrlParam";
import PDF from "react-pdf-js";
import { Document, Page, pdfjs } from 'react-pdf';
// eslint-disable-next-line no-undef
import icon2 from "../../../static/img/bh.png"
import icon3 from "../../../static/img/sure1.png"
import icon4 from "../../../static/img/bhimg.png"
import icon5 from "../../../static/img/successimg.png"
import icon6 from "../../../static/img/yy/pt.png"
import icon7 from "../../../static/img/yy/zj-zhang.png"
import { zipImg,zipPdf } from '../../../component/zipImage/index'
import {
    imgToCanvas,
    dealParams,
    getBase64
} from '../../../api/config';
import {
    postCaPdf,postCa,postPDF
} from '../../../api/ca';
import {postSupervise,supervise_data} from '../../../api/config3'
import {caAuthentication,getToken,addToken,tokenSupervision,uploadRecipe,updateEprescribing} from "../../../api/hospitalapi/upload";
import moment from 'moment'
import {caUrl,baseUrl,jumpUrl} from '../../../publicurl'
const { alert } = Modal
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cat.net/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export default class index extends Component {
    constructor() {
        super()
        this.state = {
            visible1: false,
            pharmacistId: localStorage.getItem("pharmacistId"),
            pharmacistName: localStorage.getItem("pharmacistName"),
            docterPhone: localStorage.getItem("pharmacistPhone"),
            pharmacistDetails: '',
            pharmacistzz: '',
            list: [],
            ill: {},
            a: '',
            yktext: '',
            consultationId: localStorage.getItem("consultationId") ? localStorage.getItem("consultationId") : '',
            orderNumber: '',
            gqTime: '',
            visible: false,
            visible2: false,
            prescribingId: "",
            prescribingNumber: "",
            currentPage: 1,
            totalPage: 1,
            docterId: '',
            originalPrescribing: '',
            docterAutograph: '',
            docterAutograph2: '',
            refusereson: '',
            valueno: '',
            reason: '',
            prescribingState: '',
            isRetail: '',
            allmeds: '',
            detail: '',
            phone: '',
            docterAutograph1: '',
            docterAutograph0: '',
            flog: 1,
            fileId: '',
            pdffile: '',
            password: '',
            token: '',
            formData: [],
            upload: [],
            consultationDetails: '',
            ca_id: 0,
            knowcode: false,
            passwordcode: 1,
            phaPrice: '',
            nowDate: {
                year: '',
                month: '',
                day: ''
            },
            countall:0,//当天相同审核通过的处方单数量
            scrollTop:0,
            ischinese:[],//是否中药问诊单
            chinesemethod:[],//用法用量
            phy_staff_id:'',
            phy_pharmacist_id:'',
            staff_img:'',
            pharmacist_img:'',
            filteringArr:[],
            pharmacist_img1:'',
            pharmacyId:localStorage.getItem("pharmacyId"),
            prescringPhaState:'',//药店药师审核状态
            docterAutograph3:'',//药店药师签名
            pha_refu_reson:'',//药店药师拒绝理由
            isPhaExamine:false,
            isPharPha:2,//2：平台药师，1：药店药师
            pharmacistId11:'',

            visibleExcess:false,//超量弹窗
            excessArr:[],//超量JSON数组
            phaStaffIdStoreImg:'', //核对签名
            phaStaffIdStore:'',//如果为null,那么就是自动，存在就是手动
            isShowAiLogo: 2,//2：不展示，1：展示
        }
    }

    componentDidMount = async () => {
        const {pharmacistId, docterPhone, docterId, originalPrescribing,pharmacyId} = this.state
        // console.log(pharmacistId);

        let res20 = await selectPhaOrStore({docterId:pharmacistId})
        if(res20.data && res20.data[0] && res20.data[0][0]){
            this.setState({
                isPharPha:res20.data[0][0].is_phar_pha
            })
        }

        // 查询该账号是否需要展示AI图标
        const res88 = await selectIsAiLogo({
            docterId:pharmacistId
        })

        if(res88.code == 1 && res88.data && res88.data[0] && res88.data[0].length){
            this.setState({
                isShowAiLogo:res88.data[0][0].plat_is_show_ai
            })
        }

        let time = new Date()
        let year = time.getFullYear()
        let month = time.getMonth() + 1 >= 10 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1)
        let day = time.getDate() >= 10 ? time.getDate() : '0' + time.getDate()

        this.setState({
            nowDate: {year, month, day},
            scrollTop:getUrl('scrollTop1', this.props.location.search)?getUrl('scrollTop1', this.props.location.search):0
        })
        if (docterPhone) {
            let res0 = await isCapwd({docterId: pharmacistId})
            // console.log(res0)
            if (res0 && res0.data && res0.data[0] && res0.data[0].length) {
                if (res0.data[0][0].doc_ca_pwd) {
                    this.setState({
                        password: res0.data[0][0].doc_ca_pwd,
                        passwordcode: 2
                    })
                }
            }
            let res = await getuserinfo({docterPhone})
            // console.log(res)
            if (res && res.data && res.data[0]) {
                this.setState({
                    pharmacistDetails: res.data[0][0],
                    ca_id: res.data[0][0].ca_id,
                    pharmacistzz: res.data[1][0],

                })
            }
            let res1 = await prescribingPrice({docterId: pharmacistId})
            // console.log(res1)
            if (res1 && res1.data && res1.data[0] && res1.data[0].length) {
                this.setState({
                    phaPrice: res1.data[0][0].price
                })
            }
        }
        if (getUrl('value', this.props.location.search)) {
            this.setState({
                valueno: getUrl('value', this.props.location.search)
            })
        }


        if (getUrl('prescribingId', this.props.location.search) && getUrl('docterId', this.props.location.search)) {
            this.setState({
                prescribingId: getUrl('prescribingId', this.props.location.search),
                docterId: getUrl('docterId', this.props.location.search)
            }, async () => {
                getPdfprescribing({
                    prescribingId: getUrl('prescribingId', this.props.location.search),
                    docterId: getUrl('docterId', this.props.location.search),
                    pharmacistId:getUrl('pharmacistId', this.props.location.search) != 'null' ? getUrl('pharmacistId', this.props.location.search) : pharmacistId
                }).then(res => {
                    // console.log(res)
                    if (res && res.data && res.data[0]) {
                        // this.getPdf(res.data[0][0].originalPrescribing)
                        this.setState({
                            prescribingState: res.data[0][0].prescribingState,
                            prescringPhaState: res.data[0][0].prescringPhaState
                        })
                        // window.open(res.data[0][0].originalPrescribing)

                        this.setState({
                            originalPrescribing: res.data[0][0].originalPrescribing,
                            reason: res.data[0][0].reason,
                            pha_refu_reson:res.data[0][0].pha_refu_reson,
                            orderNumber: res.data[0][0].orderNumber,
                            isRetail: res.data[0][0].isRetail,
                        }, () => {
                        })
                    }
                    if (res && res.data && res.data[1] && res.data[1][0].docterAutograph) {
                        this.setState({
                            docterAutograph2: res.data[1][0].docterAutograph
                        })
                    }
                    if (res && res.data && res.data[2] && res.data[2][0].docterAutograph0) {
                        this.setState({
                            docterAutograph1: res.data[2][0].docterAutograph0
                        })
                        getBase64(res.data[2][0].docterAutograph0, (value) => {
                            // console.log(value)
                            let data0 = value.split(',')[1]
                            // console.log(data0)
                            this.setState({
                                docterAutograph0: data0
                            })
                        })
                    }

                    // 处方单查询药店药师签名
                    selectPrescPhaSign({
                        docter_id: getUrl('storepharmacistId', this.props.location.search) != 'null' ? getUrl('storepharmacistId', this.props.location.search) : pharmacistId
                    }).then(res2=>{
                        // console.log('处方单签名',res2.data[0][0]);
                        if(res2 && res2.data && res2.data[0] && res2.data[0][0]){
                            this.setState({
                                docterAutograph3:res2.data[0][0].docterAutograph3
                            })
                        }
                    })


                })
                this.allDetails()
            })

            let res06 = await belongStore({prescribingId:getUrl('prescribingId', this.props.location.search)})

            if(res06.data[0][0].pharmacy_id != null){
                const res12 = await selectFiltering({pharmacyId:res06.data[0][0].pharmacy_id})
                // console.log('店铺权限:',res12.data[0]);
                if(res12.code==1 && res12.data && res12.data[0] && res12.data[0].length){
                    this.setState({
                        filteringArr:res12.data[0]
                    })
                }
                if(this.state.filteringArr.some(item => item.label === '药店药师审核' && item.label_type == 4)){
                    this.setState({
                      isPhaExamine: true
                    })
                }

                // 查询处方单店员、药店药师签名图
                let res88 = await selectSignaturePic({prescribingId:getUrl('prescribingId', this.props.location.search)})
                // console.log('签名照',res88.data[0]); 
                
                if(res88.data && res88.data[0] && res88.data[0][0].phaStaffIdStore){
                    this.setState({
                        phaStaffIdStoreImg:res88.data[1][0].phaStaffIdStoreImg,
                        phaStaffIdStore:res88.data[0][0].phaStaffIdStore
                    })
                }else{
                    this.setState({
                        phaStaffIdStoreImg:''
                    })
                }
                
                if (this.state.filteringArr.some(item => item.label === "核对/发药")) {
                    if(res88.data[0][0].phyStaffImg){
                        this.setState({
                            staff_img:res88.data[0][0].phyStaffImg
                        })
                    } 
                }else{
                    this.setState({
                        staff_img:'',
                    })
                }

                if (this.state.filteringArr.some(item => item.label === "药店药师审核" && item.label_type == 3)) {
                    if(res88.data[0][0].phyPharmacistImg){
                        this.setState({
                            pharmacist_img:res88.data[0][0].phyPharmacistImg
                        })
                    }
                }else{
                    this.setState({
                        pharmacist_img:"",
                    })
                }
                
                if (this.state.filteringArr.some(item => item.label === "调配")) {
                    if(res88.data[0][0].phyPharmacistsImg){
                        this.setState({
                            pharmacist_img1:res88.data[0][0].phyPharmacistsImg
                        })
                    }
                }else{
                    this.setState({
                        pharmacist_img1:"",
                    })
                }
            }
        }
    }
    //pdf转base64
    getPdf = () => {
        let that = this
        let {originalPrescribing} = that.state

        postPDF(originalPrescribing).then(data => {
            const blob = new Blob([data.data], {
                type: 'application/pdf;chartset=UTF-8'
            })

            const fileReader = new FileReader();
            fileReader.readAsDataURL(blob);

            fileReader.onload = (e) => {
                base64toFile(e.target.result);
            };
            // readAsDataURL

            fileReader.onerror = () => {
            };

            function base64toFile(dataurl, filename = 'file') {
                let arr = dataurl.split(',');
                let mime = arr[0].match(/:(.*?);/)[1];
                let suffix = mime.split('/')[1];
                let bstr = atob(arr[1]);
                let n = bstr.length;
                let u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                let file = new File([u8arr], `${filename}.${suffix}`, {
                    type: mime,
                });
                that.signaturePDF(file)
            }
        })


    }

    //处方单信息（药品信息和患者信息）
    allDetails = async () => {
        const {docterId, prescribingId,visibleExcess,excessArr,} = this.state

        let res1 = await prescribingDetail({prescribingId: getUrl('prescribingId', this.props.location.search)})
        // console.log(res1);

        // 查询该处方单上超量的药品
        let resExcess = await selectExcessNumber({ consultation_id: res1.data[0][0].consultation_id })
        // console.log('resExcess',resExcess);
        if(resExcess.code= 1 && resExcess.data && resExcess.data[0] && resExcess.data[0][0].excess_med_number != null){
            const checkIds = [109, 110, 114, 118, 111,113,116];
            let excessArrClassify = JSON.parse(resExcess.data[0][0].excess_med_number)
            excessArrClassify.forEach(itemA => {
                if(checkIds.includes(itemA.classifyId)){
                    this.setState({
                        visibleExcess:true,
                        excessArr:JSON.parse(resExcess.data[0][0].excess_med_number)
                    })
                }
            })
            
            
        }

        let res04 = await isChineseMeds({consultation_id: res1.data[0][0].consultation_id})
        let res05 = await chineseMethod({prescribing_id: getUrl('prescribingId', this.props.location.search)})
        this.setState({
            ischinese:res04.data[0],
            chinesemethod:res05.data[0]
        })

        if (res1 && res1.data && res1.data[0]) {

            let time1 = new Date(res1.data[0][0].create_time).getTime()
            this.setState({
                detail: res1.data[0][0],
                phone: res1.data[1].length ? res1.data[1][0].patient_phone : '',
            })
            let res00 = await getnewslist({
                consultationId: res1.data[0][0].consultation_id
            })
            // console.log(res00);
            if (res00 && res00.data && res00.data[0]) {
                this.setState({
                    consultationDetails: res00.data[0][0]
                })
            }
        }
        let res = await allMedicinal({prescribingId: getUrl('prescribingId', this.props.location.search)})
        // console.log(res);
        if (res && res.code == 1 && res.data[0]) {
            let res0 = await caWrite({docterId})
            if (res0 && res0.data && res0.data[0]) {
                getBase64(res0.data[0][0].docterAutograph, (value) => {
                    let data0 = value.split(',')[1]
                    this.setState({
                        docterAutograph: data0
                    })
                })
            }
            let res1 = await prescribingDetail({prescribingId: getUrl('prescribingId', this.props.location.search)})
            let res2 = await getConsultationType({id: res1.data[0][0].consultation_id})
            let res3 = await nowdayAllorder({patientName:res1.data[0][0].patient_name ,
                medAmount:res1.data[0][0].med_amount,summedquantity:res.data[1][0].summedquantity,prescribingId:getUrl('prescribingId', this.props.location.search),
                countmedquantity:res.data[2][0].countmedquantity,pharmacyId:res1.data[0][0].pharmacyId})
            let time1 = new Date(res1.data[0][0].create_time).getTime()
            this.setState({
                list: res.data[0],
                detail: res1.data[0][0],
                consultationType: res2.data[0].length ? res2.data[0][0].consultationType : '',
                gqTime: new Date(time1 + 259200000).toLocaleString(),
                year: res1.data[0][0].year,
                month: res1.data[0][0].month,
                day: res1.data[0][0].day,
                countall:res3.data[0][0].countall
            })
        }
    }

    onChangePage = (page) => {
        this.setState({
            currentPage: page
        })
    }

    onDocumentLoadSuccess = (totalPage) => {
        this.setState({
            totalPage: totalPage
        })
    }

    goback = () => {
        const {scrollTop} = this.state
        this.props.history.push(`/?scrollTop=${scrollTop}`)
    }
    //驳回理由
    bohuiValue = (e) => {
        this.setState({
            refusereson: e.target.value.replace(/\s*/g, "")
        })
    }
    //密码错误，ca验证失败
    failPassword = async () => {
        const {isRetail, pharmacistId, prescribingId, phone, allmeds, ca_id} = this.state
        alert('密码错误', '忘记密码可修改密码', [
            {
                text: '忘记密码', onPress: () => {
                    let data0 = {
                        entityId: ca_id,
                        busiType: '5',
                        redirectUrl: baseUrl + '/faas/hosp/scca/callback',
                        redirectPageUrl: jumpUrl + `/index.html#/pharmacistreviewer?prescribingId=${prescribingId}&docterId=${1}`,
                        province: '四川省',
                        locality: '德阳市',
                        orgName: '云益健互联网医院'
                    }

                    let {toSign, params} = dealParams(data0)
                    // console.log(toSign, params)
                    caAuthentication({
                        url: caUrl,
                        toSign,
                        FormData: params
                    }).then(data => {
                        if (data && data.data && data.data.success == false) {
                            Toast.info(data.data.result_msg)
                        } else if (data && data.data) {
                            window.location.href = data.data.body
                        }
                    });
                }
            },
            {
                text: '重新输入', onPress: () => {
                    this.setState({
                        visible2: true,
                        flog: 1
                    })
                }
            },
        ])
    }
    //确认驳回
    bohuiSure = async () => {
        const {
            refusereson,
            prescribingId,
            pharmacistDetails,
            phaPrice,
            pharmacistId,
            consultationDetails,
            pharmacistName
        } = this.state
        // console.log(refusereson, prescribingId)
        if (refusereson) {

            let phaSignaturetime = Date.parse(new Date()) / 1000
            let res = await phaSignature({
                prescribingId,
                consultationId: consultationDetails.consultation_id,
                docterId: pharmacistId,
                refusereson,
                phaSignaturetime,
                pharmacistId1: pharmacistId
            })
            // console.log(res)
            if (res.data.pha_pharmacist_id) {
                Toast.info('此处方笺已审核',1)
                // Toast.info('此处方单已被审核，无法继续审核',1)
                this.props.history.push('/')
            } else {
                if (res.data.docEdit && res.data.docEdit == 1) {
                    Toast.info('此处方单已被医生修改，为失效处方单，无法继续审核',1)
                    this.props.history.push('/')
                } else {
                    Toast.info('驳回成功',1)
                    this.setState({
                        visible1: false
                    })
                    // 审核通过之后查询处方列表是否有数据，有的话就出现弹窗，没有就到待审处方列表
                    // 查询属于中药药师还是西药药师
                    let res99 = await pendingPrescriptionList({
                        isPha:pharmacistDetails.is_pha
                    })
                    // console.log('页面中待审处方列表',res99);
                    if(res99.data[0].length>0){
                        let phatime = Date.parse(new Date()) / 1000
                        let prescribingId = res99.data[0][0].prescribingId
                        let docterId = res99.data[0][0].docterId

                        alert("审核提醒","有新的处方等待审核，请药师快去审核",[
                            {
                                text:"知道了",
                                onPress:()=>{
                                    this.props.history.push('/')
                                },
                            },
                            {
                                text:"去审核",
                                onPress:()=>{
                                    this.props.history.replace(`/pharmacistreviewer?prescribingId=${prescribingId}&docterId=${docterId}&pharmacistId=${null}&phatime=${phatime}`)
                                    // 刷新页面
                                    window.location.reload();
                                }
                            }
                        ]);
                    }else{
                        this.props.history.push('/')
                    }


                    // this.props.history.push('/')
                }
            }
        } else {
            Toast.info('请输入驳回理由')
        }
    }

    //审核通过
    onSuccess = async () => {
        const {isRetail, passwordcode, detail, phone, allmeds} = this.state
        //互联网医院订单
        if (detail.original_prescribing) {
            if (passwordcode == 1) {
                this.setState({
                    visible2: true
                })
            } else {
                this.passwordSure()
            }
        } else {
            Toast.info('无效处方单',1)
        }
    }

    //药店药师审核通过
    onSuccessPha = async () => {
        const {isRetail, passwordcode, detail, phone, allmeds} = this.state
        //互联网医院订单
        if (detail.original_prescribing) {
            this.phaSure()
        } else {
            Toast.info('无效处方单',1)
        }
    }

    //药店药师点击通过审核
    phaSure = async () => {
        const {
            password, 
            passwordcode, 
            prescribingId, 
            consultationDetails, 
            pharmacistId, 
            knowcode,
            phy_staff_id,
            phy_pharmacist_id,
            pharmacistDetails,
            pharmacistId11
        } = this.state
        let phaSignaturetime = Date.parse(new Date()) / 1000
        let res2 = await isPhaExamine({prescribingId})
        // console.log( '药店药师点击通过审核',res2);
        if(res2.data[0][0].prescring_pha_state === 0){
            // 药店药师审方
            let res1 = await phaSigna({
                prescribingId,
                docterId:pharmacistId,
                consultationId:res2.data[0][0].consultation_id
            })
            if(res1 && res1.code == 1){
                Toast.info('药店药师审核通过',1) 
                // 药店药师审方通过更新调配状态
                let res100 = await updateAllocate({
                    prescribingId
                })
                // 审核通过之后查询处方列表是否有数据，有的话就出现弹窗，没有就到待审处方列表
                // 查询属于中药药师还是西药药师
                let res99 = await pendingPrescriptionListStore({
                    isPha:pharmacistDetails.is_pha,
                    pharmacyId:localStorage.getItem('pharmacyId')
                })

                if(res99.data[0].length>0){
                    let phatime = Date.parse(new Date()) / 1000
                    let prescribingId = res99.data[0][0].prescribingId
                    let docterId = res99.data[0][0].docterId

                    // 查询平台药师审方人员
                    let res16 = await selectPlatformPharmacist({prescribingId: prescribingId})
                    // console.log('平台药师审方人员',res16);
                    if(res16 && res16.data && res16.data[0][0]){
                        this.setState({
                            pharmacistId11:res16.data[0][0].pharmacist_id
                        })
                    }
                    alert("审核提醒","有新的处方等待审核，请药师快去审核",[
                        {
                            text:"知道了",
                            onPress:()=>{
                                this.props.history.push('/')
                            },
                        },
                        {
                            text:"去审核",
                            onPress:()=>{
                                this.props.history.replace(`/pharmacistreviewer?prescribingId=${prescribingId}&docterId=${docterId}&pharmacistId=${this.state.pharmacistId11}&phatime=${phatime}`)
                                // 刷新页面
                                window.location.reload();
                            }
                        }
                    ]);
                }else{
                    this.props.history.push('/')
                }
                // this.props.history.push('/')
            }
        }else if(res2.data[0][0].prescring_pha_state != 0){
            // Toast.info('此处方单已被审核，无法继续审核',1)
            Toast.info('此处方笺已审核',1)
            this.props.history.push('/')
        }else if(res2.data.docEdit && res2.data.docEdit == 1){
            Toast.info('此处方单已被医生修改，为失效处方单，无法继续审核',1)
            this.props.history.push('/')
        } 
    }
    //药店药师驳回
    bohuiSurePha = async () => {
        const {
            refusereson,
            prescribingId,
            pharmacistDetails,
            phaPrice,
            pharmacistId,
            consultationDetails,
            pharmacistName,
            pharmacistId11
        } = this.state
        // console.log('药店药师驳回理由：',refusereson);
        if (refusereson) {
            let res2 = await isPhaExamine({prescribingId})
            // console.log(res2.data[0][0]);
            if(res2.data[0][0].prescring_pha_state === 0 || res2.data[0][0].prescring_pha_state === 1){
                let res = await phaSigna({
                    prescribingId,
                    docterId: pharmacistId,
                    phaRefuReson:refusereson,
                    consultationId:res2.data[0][0].consultation_id
                })
                if(res && res.code == 1){
                    Toast.info('驳回成功',1)
                    this.setState({
                        visible1: false
                    })
                    // 审核通过之后查询处方列表是否有数据，有的话就出现弹窗，没有就到待审处方列表
                    // 查询属于中药药师还是西药药师
                    let res99 = await pendingPrescriptionListStore({
                        isPha:pharmacistDetails.is_pha,
                        pharmacyId:localStorage.getItem('pharmacyId')
                    })
                    // console.log('页面中待审处方列表',res99);
                    if(res99.data[0].length>0){
                        let phatime = Date.parse(new Date()) / 1000
                        let prescribingId = res99.data[0][0].prescribingId
                        let docterId = res99.data[0][0].docterId

                        // 查询平台药师审方人员
                        let res16 = await selectPlatformPharmacist({prescribingId: prescribingId})
                        // console.log('平台药师审方人员',res16);
                        if(res16 && res16.data && res16.data[0][0]){
                            this.setState({
                                pharmacistId11:res16.data[0][0].pharmacist_id
                            })
                        }
                        alert("审核提醒","有新的处方等待审核，请药师快去审核",[
                            {
                                text:"知道了",
                                onPress:()=>{
                                    this.props.history.push('/')
                                },
                            },
                            {
                                text:"去审核",
                                onPress:()=>{
                                    this.props.history.replace(`/pharmacistreviewer?prescribingId=${prescribingId}&docterId=${docterId}&pharmacistId=${this.state.pharmacistId11}&phatime=${phatime}`)
                                    // 刷新页面
                                    window.location.reload();
                                }
                            }
                        ]);
                    }else{
                        this.props.history.push('/')
                    }
                    // this.props.history.push('/')
                }  
            }else if(res2.data.docEdit && res2.data.docEdit == 1){
                Toast.info('此处方单已被医生修改，为失效处方单，无法继续审核',1)
                this.props.history.push('/')
            } 
        } else {
            Toast.info('请输入驳回理由')
        }
    }

    //确认密码
    passwordSure = () => {
        const {password, passwordcode, prescribingId, consultationDetails, pharmacistId, knowcode,phy_staff_id,phy_pharmacist_id,pharmacistDetails,visiblenext} = this.state
        // console.log('consultationDetails',consultationDetails);
        // console.log( password, passwordcode, prescribingId, consultationDetails, pharmacistId, knowcode );
        if (password) {
            this.setState({
                visible2: false,
                flog: 2
            }, async () => {
                // this.getPdf()
                let phaSignaturetime = Date.parse(new Date()) / 1000
                let res1 = await phaSignature({
                    prescribingId,
                    consultationId: consultationDetails.consultation_id,
                    docterId: pharmacistId,
                    password: password,
                    knowcode,
                    phaSignaturetime,
                    pharmacistId1: pharmacistId,
                    phy_staff_id:0,
                    phy_pharmacist_id:0
                })
                if (res1 && res1.code == 1) {
                    if (res1.data.pha_pharmacist_id) {
                        // Toast.info('此处方单已被审核，无法继续审核',1)
                        Toast.info('此处方笺已审核',1)
                        this.props.history.push('/')
                    } else {
                        if (res1.data.docEdit && res1.data.docEdit == 1) {
                            Toast.info('此处方单已被医生修改，为失效处方单，无法继续审核',1)
                            this.props.history.push('/')
                        } else {
                            if (res1.data && res1.data.result_msg) {
                                if (res1.data.result_msg == '云证书获取失败[PIN校验失败]') {
                                    this.setState({
                                        flog: 1,
                                        password: ''
                                    }, () => {
                                        this.failPassword()
                                    })
                                } else {
                                    Toast.info('签章失败，请重新签名后再进行审方')
                                }

                            } else {
                                Toast.info('审核通过',1);
                                // 审核通过之后查询处方列表是否有数据，有的话就出现弹窗，没有就到待审处方列表
                                // 查询属于中药药师还是西药药师
                                let res99 = await pendingPrescriptionList({
                                    isPha:pharmacistDetails.is_pha
                                })
                                // console.log('页面中待审处方列表',res99);
                                if(res99.data[0].length>0){
                                    // this.setState({
                                    //     visiblenext:true
                                    // })
                                    let phatime = Date.parse(new Date()) / 1000
                                    let prescribingId = res99.data[0][0].prescribingId
                                    let docterId = res99.data[0][0].docterId

                                    alert("审核提醒","有新的处方等待审核，请药师快去审核",[
                                        {
                                            text:"知道了",
                                            onPress:()=>{
                                                this.props.history.push('/')
                                            },
                                        },
                                        {
                                            text:"去审核",
                                            onPress:()=>{
                                                this.props.history.replace(`/pharmacistreviewer?prescribingId=${prescribingId}&docterId=${docterId}&pharmacistId=${null}&phatime=${phatime}`)
                                                // 刷新页面
                                                window.location.reload();
                                            }
                                        }
                                    ]);
                                }else{
                                    this.props.history.push('/')
                                }
                                // this.props.history.push('/')

                                let res06 = await belongStore({prescribingId:prescribingId})
                                if(res06.data[0][0].pharmacy_id != null){
                                    const res12 = await selectFiltering({pharmacyId:res06.data[0][0].pharmacy_id})
                                    if(res12.code==1 && res12.data && res12.data[0] && res12.data[0].length){
                                        this.setState({
                                            filteringArr:res12.data[0]
                                        })
                                    }
                                    let res08 = await selectPharmaIdStaff({pharmacy_id:res06.data[0][0].pharmacy_id})
                                    let res09 = await selectPharmaIdPha({pharmacy_id:res06.data[0][0].pharmacy_id})
                                    if(res08.data[0][0]){
                                        this.setState({
                                            phy_staff_id:res08.data[0][0].id,
                                        })
                                    }
                                    if(res09.data[0][0]){
                                        this.setState({
                                            phy_pharmacist_id:res09.data[0][0].id,
                                        })
                                    }

                                }
                            }
                        }
                    }

                }
            })
        } else {
            Toast.info('请先输入密码')
        }

    }


    //互联网医院订单审核通过生成待支付订单
    hosOrder = async () => {
        const {
            isRetail,
            phaPrice,
            consultationDetails,
            pharmacistId,
            ca_id, pharmacistDetails,
            pharmacistName,
            docterId,
            prescribingId,
            detail,
            phone,
            list, refusereson,
            orderNumber
        } = this.state
        if (isRetail == 1) {
            let params = {
                pharmacistId: pharmacistId,
                phaPrice: phaPrice,
                pharmacistName: pharmacistDetails.docter_name,
                prescribingState: 1,
                prescribingId: prescribingId,
                userId: detail.user_id,
                orderShipping: '1',
                phone: phone,
                payMethod: '微信支付',
                orderPrice: detail.med_amount,
                orderAmount: detail.med_amount,
                prescribing_id: detail.prescribing_id,
                user_name: detail.patient_name,
                isorderNumber: 1,
                docterId
            }
            let res = await qualificationsMedExamine(params)
            // console.log(res)
            if (res && res.code == 1) {
                let array = []
                list.forEach(item => {
                    array.push({
                        orderNumber: res.data[2][0].orderNumber,
                        medId: item.e_medicinal_id,
                        medName: item.medicinal_name,
                        medSpec: item.medicinal_spec,
                        medApproval: item.med_approval,
                        medCompany: item.med_company,
                        medPrice: item.medicinal_price,
                        medPacking: item.medicinal_packing,
                        medQuantity: item.medicinal_quantity,
                    })
                })
                addOrderMed({array: array}).then(res1 => {
                    // console.log(res1)
                    if (res1 && res1.code == 1) {
                        Toast.info('审核通过',1)
                        // Toast.info({
                        //     content:'审核通过',
                        //     duration:200  
                        // })
                        this.props.history.push('/')
                        // this.setState({
                        //     flog: 2
                        // })
                        // this.getPdf()
                    }
                })
            }
        } else {
            //    零售订单
            let params = {
                pharmacistId: pharmacistId,
                pharmacistName: pharmacistDetails.docter_name,
                prescribingState: 1,
                prescribingId: prescribingId,
                userId: detail.user_id,
                isorderNumber: 2,
                phaPrice: phaPrice,
                docterId,
                consultationId: consultationDetails.consultation_id
            }
            let res = await qualificationsMedExamine(params)
            // console.log(res)
            if (res && res.code == 1) {
                if (consultationDetails.order_number) {
                    this.lsUpdateState()
                }
                // getOrdersate({orderNumber: orderNumber})
                //     .then(res1 => {
                //         if (res1.data && res1.data.data && res1.data.data[0] && res1.data.data[0][0] && res1.data.data[0][0].orderState == 16) {
                //             lsupdatestu({
                //                 orderState: res1.data.data[0][0].orderShipping == 2 ? '-3' : '8',
                //                 orderNumber: orderNumber
                //             }).then(res2 => {
                //                 Toast.info('审核通过')
                //                 this.props.history.push('/')
                //             })
                //         }
                //     })
            }
        }
    }
    //
    passwordValue = (e) => {
        this.setState({
            password: e.target.value.replace(/\s*/g, "")
        })
    }
    /**
     * @description 签章
     * @params 文件签章
     */
    signaturePDF = (file) => {
        const {pharmacistId, ca_id, docterAutograph0, originalPrescribing, password} = this.state
        let signParams = JSON.stringify([
            {
                llx: 210,
                lly: 125,
                urx: 245,
                ury: 160,
                pageList: [1],
                sealImg: docterAutograph0
            },
        ]);
        let {toSign: toSign} = dealParams({
            userId: ca_id,
            configKey: ca_id,
            signParams,
            cloudCertPass: password,
        });

        if (docterAutograph0 && file) {
            getSignature({toSign: toSign}).then((data) => {
                postCaPdf(
                    '/signature-server/api/open/signature/signPdf',
                    {
                        userId: ca_id,
                        configKey: ca_id,
                        signParams,
                        pdfFile: file,
                        cloudCertPass: password,
                    },
                    data.data,
                ).then((value) => {
                    //"1585145531735109633"
                    // console.log(value.data)
                    if (value && value.data && value.data.body && value.data.body[0].fileId) {
                        this.setState({
                            fileId: value.data.body[0].fileId
                        }, () => {
                            this.downPDF(value.data.body[0].fileId)
                        })
                    } else {
                        this.setState({
                            flog: 1,
                            password: ''
                        }, () => {
                            this.failPassword()
                        })
                    }
                });
            });
        }
    };

    /**
     * @description 下载文件
     */
    downPDF = (fileId) => {
        const {pharmacistId, ca_id, docterAutograph0} = this.state
        let params = {
            fileId: fileId,
            userId: ca_id,
        };
        let {toSign: toSign, params: FormData} = dealParams(params);

        getSignature({toSign: toSign}).then((data) => {
            postCa('/signature-server/api/open/signature/fetchSignedFile', FormData, data.data).then((value) => {
                // let objectUrl = URL.createObjectURL(value.data);
                // let link = document.createElement('a');
                // link.download = '我的pdf文件.pdf';
                // link.href = objectUrl;
                // link.click();
                // link.remove();
                this.getDataPDF(value.data)
                // console.log(value.data)

            });
        });
    }
    //上传pdf文件
    getDataPDF = (value) => {
        const {consultationDetails, knowcode, pharmacistId, password} = this.state
        const {prescribingId, fileId, flog, isRetail, detail, phone, allmeds} = this.state
        const blob = new Blob([value], {
            type: 'application/pdf;chartset=UTF-8'
        })
        const fileReader = new FileReader();
        fileReader.readAsDataURL(blob);
        // console.log(value)

        fileReader.onload = (e) => {
            // console.log(e.target.result)
            base64toFile(e.target.result);
        };
        // readAsDataURL

        fileReader.onerror = () => {
        };
        let that = this

        function base64toFile(dataurl, filename = 'file') {
            let arr = dataurl.split(',');
            let mime = arr[0].match(/:(.*?);/)[1];
            let suffix = mime.split('/')[1];
            let bstr = atob(arr[1]);
            let n = bstr.length;
            let u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            let file = new File([u8arr], `${filename}.${suffix}`, {
                type: mime,
            });
            // console.log(file);
            that.setState({
                pdffile: file
            })
            if (file && flog == 2) {
                let formData = new FormData()
                formData.append('file', file)
                uploadImg(formData).then(res1 => {
                    // console.log(res1)
                    if (res1 && res1.code === 2) {
                        newPrescribing({
                            knowcode: knowcode ? 1 : 0,
                            docterId: pharmacistId,
                            passwordca: password,
                            prescribingId,
                            fileId,
                            originalPrescribing: res1.data
                        }).then(res2 => {
                            // console.log(res2)
                            if (res2 && res2.code == 1) {
                                // that.uploadDataToken()
                                // that.hosOrder()

                            }
                        })
                    }
                    // Toast.hide()
                }).catch(err => {
                    // Toast.hide()
                })
            }
        }
    };
    /**
     * @description 获取token
     */
    uploadDataToken = () => {

        tokenSupervision({})
            .then((data) => {
                if (data.data[0][0]) {
                    // console.log(data)
                    this.setState({
                        token: data.data[0][0].token
                    }, () => {
                        this.uploadData()
                    })
                } else {
                    getToken()
                        .then((data) => {
                            let value = JSON.parse(data);
                            addToken({token: value.accessToken, expiresIn: value.expiresIn}).then((data) => {
                                this.setState({
                                    token: value.accessToken
                                }, () => {

                                    this.uploadData()
                                })
                                Toast.success('成功');
                            });
                        })
                        .catch((e) => console.log(e?.message || '失败'));
                }
            })
            .catch((e) => Toast.fail('请求失败'));
    };
    /**
     * @description 零售订单修改处方单状态
     */
    lsUpdateState = async () => {
        const {consultationDetails} = this.state
        if (consultationDetails.car_buy == 2) {
            let res = await updateState({isAssert: 1, orderNumber: consultationDetails.order_number})
            // console.log(res)
            if (res && res.code == 1) {
                Toast.info('审核通过',1)
                this.props.history.push('/')
            }
        } else {
            //    medDetails,Addall
            let res = await generateOrder({orderNumber: consultationDetails.order_number})
            // console.log(res)
            if (res && res.code == 1) {
                Toast.info('审核通过',1)
                this.props.history.push('/')
            }
        }
    }
    /**
     * @description 上传数据
     */
    uploadData = () => {
        const {
            prescribingId,
            fileId,
            flog,
            pharmacistzz,
            detail,
            token,
            list,
            consultationDetails,
            pharmacistDetails
        } = this.state
        let id = [prescribingId];
        let upload = [];
        let furtherConsultDiagnosis = [];
        JSON.parse(consultationDetails.electronic_icd).forEach((item) => {
            furtherConsultDiagnosis.push(item.icdName);
        });

        let drugName = [],
            drugCode = [],
            drugCommonName = [],
            specification = [],
            frequency = [],
            usage = [],
            doseUnit = [],
            doseEachTime = [],
            medicationDays = [],
            quantity = [],
            drugPackage = [];
        list.forEach((items) => {
            drugName.push(items.medicinal_name);
            drugCode.push(items.med_qrcode || '条形码');
            drugCommonName.push(items.med_com_name);
            specification.push(items.medicinal_spec);
            frequency.push(items.medicinal_frequency);
            usage.push(items.medicinal_method);
            doseUnit.push(items.doseUnit);
            doseEachTime.push(items.medicinal_usage);
            medicationDays.push(items.medicinal_day);
            quantity.push(items.medicinal_quantity);
            drugPackage.push(items.medicinal_packing);
        });
        upload.push({
            accessToken: token,
            clientId: supervise_data.clientId,
            thirdUniqueid: consultationDetails.consultation_id,

            orgName: supervise_data.orgName,
            orgCode: supervise_data.orgCode,

            section: consultationDetails.dep_name,
            sectionCode: consultationDetails.dep_num,

            docName: consultationDetails.docter_name,
            docCertificateNum: consultationDetails.certificateNum,

            pharmacistName: pharmacistDetails.docter_name,
            pharmacistOrg: pharmacistDetails.pharmacistOrg,
            pharmacistCertificateNum: pharmacistzz.pharmacistCertificateNum,

            furtherConsultNo: consultationDetails.consultation_number,
            furtherConsultDiagnosis: furtherConsultDiagnosis.toString().replaceAll(',', '|'),

            patientName: consultationDetails.patientName,
            patientAge: consultationDetails.patient_age,
            patientSex: consultationDetails.patientSex,
            patientIdcardType: 1,
            patientIdcardNum: consultationDetails.patientIdcardNum,

            feeType: 1,
            medicalHistory: consultationDetails.consultation_detail,

            recipeTime: moment(consultationDetails.recipeTime).format('YYYY-MM-DD HH:mm:ss'),
            recipeType: detail.recipe_type,
            reviewTime: moment(consultationDetails.reviewTime).format('YYYY-MM-DD HH:mm:ss'),

            recipeUnitPrice: detail.med_amount / 100,
            recipeAllPrice: detail.med_amount / 100,

            drugName: drugName.toString().replace(',', '|'),
            drugCode: drugCode.toString().replace(',', '|'),
            drugCommonName: drugCommonName.toString().replaceAll(',', '|'),
            specification: specification.toString().replaceAll(',', '|'),
            frequency: frequency.toString().replaceAll(',', '|'),
            usage: usage.toString().replaceAll(',', '|'),
            doseUnit: doseUnit.toString().replaceAll(',', '|'),
            doseEachTime: doseEachTime.toString().replaceAll(',', '|'),
            medicationDays: medicationDays.toString().replaceAll(',', '|'),
            quantity: quantity.toString().replaceAll(',', '|'),
            drugPackage: drugPackage.toString().replaceAll(',', '|'),
            //
            uploadTime: moment().format('YYYY-MM-DD HH:mm:ss'),
            cityId: consultationDetails.city_id,
            recipeNo: detail.prescribing_number,
        });
        // console.log(upload)

        uploadRecipe(upload)
            .then((data) => {
                // console.log(data)
                if (data.data.code) {
                    updateEprescribing({id: prescribingId}).then(data1 => {
                        // console.log(data1)
                    })
                }
            })
            .catch((e) => console.log(e));
    };

    // 关闭超量提示弹窗
    handleExcessSure =()=>{
        const { visibleExcess } = this.state
        this.setState({
            visibleExcess:false
        })
    }

    render() {
        const {
          flog,
          visible2,
          visible,
          docterAutograph,
          docterAutograph2,
          originalPrescribing,
          knowcode,
          visible1,
          password,
          reason,
          prescribingState,
          docterAutograph1,
          detail,
          list,
          year,
          month,
          day,
          totalPage,
          refusereson,
          nowDate,
          countall,
          currentPage,
          ischinese,
          chinesemethod,
          staff_img,
          pharmacist_img,
          pharmacist_img1,
          pharmacyId,
          prescringPhaState,
          docterAutograph3,
          pha_refu_reson,
          isPhaExamine,
          isPharPha,
          visibleExcess,
          excessArr,
          pharmacistDetails,
          phaStaffIdStoreImg,
          phaStaffIdStore,
          isShowAiLogo
        } = this.state;
        // console.log(pharmacistDetails,'917==============');
        // console.log(nowDate.year == year , nowDate.month == month , nowDate.day == day)
        // console.log(pharmacyId);
        // console.log(docterAutograph3);
        // console.log(isPhaExamine,'1175');

        if(list){
            for(let i=0;i<list.length;i++){
                if(list[i].med_frequency_large){
                    let frequency=list[i].medicinal_frequency.slice(0,2) //频率:几日
                    let frequencyTimes=list[i].medicinal_frequency.slice(2) //频率:几次

                    let frequencyLarge=list[i].med_frequency_large.slice(0,2) //最大几日
                    let frequencyLargeTimes=list[i].med_frequency_large.slice(2) //最大几次

                    let medUsage = parseFloat(list[i].medicinal_usage) //每次几片

                    let medAllCount = list[i].med_all_count //药品总量
    
                    let day=0 //频率:几日
                    if (frequency=='一日'||frequency=='一天') {
                        day=1
                    }else if (frequency=='二日'||frequency=='二天') {
                        day=2
                    }else if (frequency=='三日'||frequency=='三天') {
                        day=3
                    }else if (frequency=='四日'||frequency=='四天') {
                        day=4
                    }else if (frequency=='五日'||frequency=='五天') {
                        day=5
                    }else if (frequency=='六日'||frequency=='六天') {
                        day=6
                    }else if (frequency=='七日'||frequency=='七天'||frequency=='一周'||frequency=='每周') {
                        day=7
                    }else if (frequency=='八日'||frequency=='八天') {
                        day=8
                    }else if (frequency=='九日'||frequency=='九天') {
                        day=9
                    }else if (frequency=='十日'||frequency=='十天') {
                        day=10
                    }

                    let dayLarge=0 //最大频率:几日
                    if (frequencyLarge=='一日'||frequencyLarge=='一天') {
                        dayLarge=1
                    }else if (frequencyLarge=='二日'||frequencyLarge=='二天') {
                        dayLarge=2
                    }else if (frequencyLarge=='三日'||frequencyLarge=='三天') {
                        dayLarge=3
                    }else if (frequencyLarge=='四日'||frequencyLarge=='四天') {
                        dayLarge=4
                    }else if (frequencyLarge=='五日'||frequencyLarge=='五天') {
                        dayLarge=5
                    }else if (frequencyLarge=='六日'||frequencyLarge=='六天') {
                        dayLarge=6
                    }else if (frequencyLarge=='七日'||frequencyLarge=='七天'||frequencyLarge=='一周'||frequencyLarge=='每周') {
                        dayLarge=7
                    }else if (frequencyLarge=='八日'||frequencyLarge=='八天') {
                        dayLarge=8
                    }else if (frequencyLarge=='九日'||frequencyLarge=='九天') {
                        dayLarge=9
                    }else if (frequencyLarge=='十日'||frequencyLarge=='十天') {
                        dayLarge=10
                    }
        
                    // 最大量
                    let medLargeUsage = parseFloat(list[i].med_large_usage)  //每次几片最大
                    let medFrequencyLarge=parseFloat(frequencyLargeTimes)   //频率几次最大
                    let largeAllcount=0
                    if(dayLarge && medFrequencyLarge && medLargeUsage && medAllCount){
                        // 用药天数计算：[总量X盒数(用户购买)] / [几次 / 几日 X 每次几片]
                        largeAllcount = Math.ceil((medAllCount * list[i].medicinal_quantity) / (  medFrequencyLarge / dayLarge * medLargeUsage))
                    }

                    list[i].largeAllcount=largeAllcount
        
                    // 最多购买多少盒
                    // 最大购买数量计算：每日每次*每次最大几片*最大用药天数/数量
                    let frequencyLargeCount=parseFloat(frequencyLargeTimes)
                    let largeQuantityCount =Math.ceil((frequencyLargeCount * medLargeUsage * list[i].med_large_day) / medAllCount)
                    list[i].largeQuantityCount=largeQuantityCount
                }   
            }

            list.forEach(itemA => {
                excessArr.forEach(itemExcess => {
                    if (itemA.med_com_name == itemExcess.medComName) {
                        itemExcess.medicinalQuantity = itemA.medicinal_quantity
                    }
                });
            });
        }
        const isExcessArrList = excessArr.map(item => `${item.medComName}最多${item.maxDataLargeSplit}${item.packingUnit}，当前${ item.medicinalQuantity? item.medicinalQuantity : ''}${item.packingUnit}`).join('、');
        return (
            <>
                <Modal
                    className="aa"
                    visible={true}
                >
                    <div className='box-topimg'>
                        <img className='top-img' src={icon1} alt="" onClick={this.goback}/> 
                        <span className='title-middle'>处方详情</span>
                    </div>
                    
                    {/*    <div id='demo'>*/}
                    {/*        <div id="a">*/}
                    <div className='prescription-outer'>
                        {detail.patient_age < 6 ? <p className='customstyle erke'>儿科</p> : null}
                        <img className='zj-zhang1' src={icon7}/>

                        {/* pharmacyId != 'null'表示药店药师 */}
                        {isPharPha == 1 ? (
                            <>
                                {prescribingState == 1 && prescringPhaState == 1 ?
                                    <img className='pass-img1' src={icon5}/> :
                                    prescribingState == -1 || prescringPhaState == 2  ? <img className='pass-img1' src={icon4}/> : null}  
                            </>
                        ) : (
                            <>
                                {isPhaExamine ? (
                                    <>
                                        {prescribingState == 1 && prescringPhaState == 1 ?
                                            <img className='pass-img1' src={icon5}/> :
                                            prescribingState == -1 || prescringPhaState == 2  ? <img className='pass-img1' src={icon4}/> : null}
                                    </>
                                ) : (
                                    <>
                                        {prescribingState == 1 && prescringPhaState != 2 ?
                                            <img className='pass-img1' src={icon5}/> :
                                            prescribingState == -1 || prescringPhaState == 2  ? <img className='pass-img1' src={icon4}/> : null}  
                                    </>
                                )}
                            </>
                        )}

                        
                        <h1 className='title'>中江云益健互联网医院</h1>
                        <p className='prescription-jian'>
                            <h1>{detail.patient_age < 6 ? '儿科' : ''}处方笺</h1>
                            <img src={icon6}/>
                        </p>
                        <p className='customstyle prescription-number'>处方编号：{detail.prescribing_number}</p>
                        {/*<p className='prescription-line'></p>*/}
                        <p className='prescription-one'>
                            <p className='prescription-one-left'>
                                <span className='customstyle'>科室:</span>
                                <span className='customstyle'>{detail.docter_department}</span>
                            </p>
                            <p className='prescription-one-right'>
                                <span className='customstyle'>开具日期:</span>
                                <span className='customstyle linedown'>{year}</span>
                                <span className='customstyle'>年</span>
                                <span className='customstyle linedown'>{month}</span>
                                <span className='customstyle'>月</span>
                                <span className='customstyle linedown'>{day}</span>
                                <span className='customstyle'>日</span>
                            </p>
                        </p>
                        <p className='prescription-one'>
                            <p className='prescription-one-left'>
                                <span className='customstyle'>姓名:</span>
                                <span className='customstyle'>{detail.patient_name}</span>
                            </p>
                            <p className='prescription-one-right2'>
                                <span className='customstyle'>性别:</span>
                                <span
                                    className='customstyle linedown2'>{detail.patient_sex == '1' ? '男' : '女'}</span>
                                <span className='customstyle'>年龄:</span>
                                <span className='customstyle linedown2'>{detail.patient_age} 岁</span>
                            </p>
                        </p>

                        <p className='prescription-one2'>
                            <span className='customstyle'>初步诊断：</span>
                            <span className='customstyle'>{detail.docter_diagnosis}</span>
                        </p>
                        
                        {/*<p className='prescription-line'></p>*/}
                        <p className='prescription-RP'>RP
                            {(detail.is_ai == 1 && isShowAiLogo == 1) ? <span></span> : null}
                        </p>
                        {ischinese.length ? (
                            <>
                                <p className='all-chameds'>
                                    <p className='alllists'>
                                        {list ? list.map((item, index) => (
                                            <p className='prescription-chameds-news'>
                                                <p className='prescription-chameds-left'>
                                                    <span
                                                        className='customstyle'>{item.med_com_name ? item.med_com_name : item.medicinal_name}</span>
                                                    <span
                                                        className='customstyle'>{item.medicinal_quantity}g</span>
                                                </p>
                                            </p>
                                        )) : null}
                                    </p>
                                    <p className="cha-methods">
                                        <p className="mtthod-tit">用法：</p>
                                        <p className="mtthod-con">
                                                {chinesemethod.filter(i => i.consultation_id == detail.consultation_id).map(i=>(
                                                    <span>
                                                        共{i.co_demand}剂，{i.meiday}{i.daily}剂，每剂分{i.each_dose}次使用，
                                                        每次{i.at_time}{i.unit}，{i.med_method}，
                                                        {i.med_time == 1
                                                        ? "饭前用药"
                                                        : i.med_time == 2
                                                        ? "饭后用药"
                                                        : i.med_time == 3
                                                        ? "睡前用药"
                                                        : i.med_time == 4
                                                        ? "晨起用药"
                                                        : ""}
                                                    </span>
                                                ))} 
                                        </p>
                                    </p>
                                    {list.length<39 && <p className="kongbailine">(以下空白)</p>}
                                    {/* <p className="kongbailine">(以下空白)</p> */}
                                </p>
                            </>
                        ) : (
                            <>
                                <p className='all-meds'>
                                    {list ? list.map((item, index) => (<p className='prescription-meds'>
                                        <p className='prescription-meds-news'>
                                            <p className='prescription-meds-left-large'>
                                                <p className={ ((pharmacistDetails.is_phar_pha == 2 && pharmacistDetails.is_excess_count == 1 && item.med_large_day && ((item.med_large_day < item.medicinal_day) || (item.largeQuantityCount < item.medicinal_quantity))) && item.medicinal_quantity!=1) ? 'large-p-no' : 'large-p'}>
                                                    <span>
                                                        {index + 1}、{item.med_com_name ? item.med_com_name : item.medicinal_name}
                                                    </span>
                                                    <span className='customstyle'>
                                                        {item.medicinal_spec} x{item.medicinal_quantity}{item.packing_unit && item.packing_unit != "null" ? item.packing_unit : ''} 
                                                    </span>
                                                </p>
                                            </p>
                                        </p>
                                        <p className='customstyle prescription-meds-details'>用法用量：{item.medicinal_frequency},每次{item.medicinal_usage},用药{item.medicinal_day}天, {item.medicinal_method}</p>
                                        {item.medicinal_supplementary ?
                                            <p className='customstyle prescription-meds-details'>补充说明：{item.medicinal_supplementary}</p> : null}
                                        
                                        {pharmacistDetails.is_excess_count == 1 && pharmacistDetails.is_phar_pha == 2 ? (
                                            <>
                                                <p className='usage-day'>
                                                    <p className='usage-day-left-no'>
                                                        最大天数/数量：
                                                        { item.med_large_day ? (item.med_large_day + '天 / ' + (item.largeQuantityCount ? item.largeQuantityCount : '') + item.packing_unit) : ('--' + '天 / ' + '--' + item.packing_unit)}
                                                    </p>
                                                </p>
                                            </>
                                        ) : null}

                                        {index + 1 == list.length && index + 1 < 5 ? <p className='xu-line'></p> : null}
                                        {index + 1 == list.length && index < 4 ?
                                            <p className='customstyle kongbai'>(以下空白)</p> : null}
                                    </p>)) : null}
                                </p>
                            </>
                        )}
                        
                        {/*<p className='prescription-line'></p>*/}
                        <p className='prescription-doctor'>
                            <p className='prescription-doctor1'>
                                <span className='customstyle'>医师:</span>
                                <p>
                                    {docterAutograph2 && detail.original_prescribing ? <img src={docterAutograph2}/> : null}
                                </p>
                            </p>
                            <p className='prescription-doctor22'>
                                <span className='customstyle'>医院药师:</span>
                                <p>
                                    {prescribingState == 1 ? <img src={docterAutograph1}/> : null}
                                </p>
                            </p>
                            <p className='prescription-doctor11'>
                                <span className='customstyle'>药店药师审核:</span>
                                <p>
                                    {isPhaExamine ? (
                                        <>
                                            {prescringPhaState == 1 && (docterAutograph1 != docterAutograph3) ? <img src={docterAutograph3}/> : <span className='no-line'></span>}
                                        </>
                                    ) : (
                                        <>
                                            {prescringPhaState == 1 && pharmacist_img ? <img src={pharmacist_img}/> : <span className='no-line'></span>}
                                        </>
                                    )}
                                </p>
                            </p>
                        </p>
                        <p className='prescription-doctor2'>
                            <p className='prescription-doctor3'>
                                <span className='customstyle customstyle'>调配:</span>
                                <p>
                                    {prescringPhaState == 1 && (docterAutograph1 != docterAutograph3) ? <img src={docterAutograph3}/> : prescribingState == 1 && pharmacist_img1 ? <img src={pharmacist_img1}/> : <span className='no-line' />}
                                    {/* {prescribingState == 1 && staff_img ? <img src={staff_img}/> : <span className='no-line'></span>} */}
                                </p>
                            </p>
                            <p className='prescription-doctor33'>
                                <span className='customstyle customstyle'>核对/发药:</span> 
                                <p>
                                    {prescringPhaState == 1 && phaStaffIdStore && phaStaffIdStoreImg ? <img src={phaStaffIdStoreImg}/> : 
                                        prescringPhaState == 1 && staff_img ? <img src={staff_img}/> : <span className='no-line'></span>}
                                </p>
                            </p>
                            <p className='prescription-doctor4'>
                                <span className='customstyle customstyle'>金额:</span>
                                <p></p>
                            </p>
                        </p>

                        <p className='prescription-tips'>
                            <span className='customstyle customstyle'>备注：</span>
                            <span className='customstyle customstyle'>{detail.docter_proposal}</span>
                        </p>
                        <p className='customstyle prescription-tips1'>1、医师处方仅开具当日有效（医生注明除外）。</p>
                        <p className='customstyle prescription-tips1'>2、处方超过7日用量为病情需要。</p>
                        <p className='customstyle prescription-tips1'>患者身份证号：{detail.patientIdcardNum ? detail.patientIdcardNum.substr(0,3)+'***********'+detail.patientIdcardNum.substr(-4) : '无'}</p>
                        <p className='customstyle prescription-tips1'>店铺名称：{detail.pharmacy_name}</p>
                        <p className='customstyle prescription-tips1'>病情描述：{detail.patient_illness}</p>
                        <p className='customstyle prescription-tips1'>是否医保统筹：{detail.medical_nsurance_coordination == 1 ? '是' : '否'}</p>
                        {/*{detail.patient_sex == 2 ?<p className='customstyle prescription-tips1'>怀孕情况：{detail.pregnancyStatus == 1 ? '未怀孕' :*/}
                        {/*    detail.pregnancyStatus == 2 ? '备孕中' :*/}
                        {/*        detail.pregnancyStatus == 3 ? '已怀孕' : '哺乳期'}*/}
                        {/*</p>:null}*/}
                        {countall?<p className='customstyle prescription-tips1' style={{color:'#FFB01F'}}>注意：{countall}条重复处方，请仔细核对</p>:null}
                    </div>
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/* {docterAutograph2 && detail.original_prescribing ?
                        <img className='ys-img1' src={docterAutograph2}/> : null} */}
                    {isPharPha == 1 ? (
                        <>
                            {visible1 || visible2 ? null : <>
                                {!getUrl('value', this.props.location.search) ? 
                                    <p className='bottom'>
                                        <span onClick={() => {
                                            this.setState({
                                                visible1: true
                                            })
                                        }}>驳回</span>
                                        {isPharPha == 1 ? 
                                            <span onClick={() => this.onSuccessPha()}>通过</span> : 
                                            <span onClick={() => this.onSuccess()}>通过</span>
                                        }
                                        
                                    </p> : reason || pha_refu_reson ? <p className='bottom-reason'>
                                            {pha_refu_reson ? <>驳回理由：药店药师驳回：{pha_refu_reson}</> : <>驳回理由：{reason}</>}       
                                            </p> :  prescribingState == 1 && prescringPhaState == 1 && nowDate.year == year && nowDate.month == month && nowDate.day == day ?
                                            <p className='bottom1'>
                                                <span className='bohui1' onClick={() => {
                                                    this.setState({
                                                        visible1: true
                                                    })
                                                }}>驳回</span> 
                                            </p> : null}
                            </>}
                        </>
                    ) : (
                        <>
                            {visible1 || visible2 ? null : <>
                                {!getUrl('value', this.props.location.search) ? 
                                    <p className='bottom'>
                                        <span onClick={() => {
                                            this.setState({
                                                visible1: true
                                            })
                                        }}>驳回</span>
                                        {isPharPha == 1 ? 
                                            <span onClick={() => this.onSuccessPha()}>通过</span> : 
                                            <span onClick={() => this.onSuccess()}>通过</span>
                                        }
                                        
                                    </p> : reason || pha_refu_reson ? <p className='bottom-reason'>
                                            {pha_refu_reson ? <>驳回理由：药店药师驳回：{pha_refu_reson}</> : <>驳回理由：{reason}</>}       
                                            </p> :  prescribingState == 1 && prescringPhaState == 0 && nowDate.year == year && nowDate.month == month && nowDate.day == day ?
                                            <p className='bottom1'>
                                                <span className='bohui1' onClick={() => {
                                                    this.setState({
                                                        visible1: true
                                                    })
                                                }}>驳回</span> 
                                            </p> : null}
                            </>}
                        </>
                    )}

                    {/* 超量提醒弹窗  visibleIsExcess */}
                    {(isPharPha == 2 && visibleExcess && prescribingState == 0) ? (
                        <>
                        <div className="cons-type-box-wrap">
                            <div className="cons-type-box">
                                <p className="cons-type-tit">提醒</p>
                                <p className="cons-type-tip">当前问诊：<span className="tip-tuwen">{isExcessArrList}</span>；数量过大，请医生核实后再开方。</p>
                                <p className="cons-type">
                                    <span className="type-three" onClick={() => this.handleExcessSure()}>确认</span>
                                </p>
                            </div>
                        </div>
                        </>
                    ) : null}
                    

                    {visible1 ?
                        <div className='bohui-outer'>
                            <div className='bohui'>
                                <p className='title'>请输入驳回理由</p>
                                <p className='content'>
                                    <span>驳回理由：</span>
                                    <textarea rows={3} placeholder='驳回理由(50字以内)' maxLength={50}
                                              defaultValue={refusereson}
                                              onChange={(e) => this.bohuiValue(e)}/>
                                </p>
                                <p className='button'>
                                <span onClick={() => {
                                    this.setState({
                                        visible1: false,
                                        refusereson: ''
                                    })
                                }}>取消</span>
                                    
                                    {isPharPha == 1 ? 
                                        <span onClick={() => this.bohuiSurePha()}>确认</span> : 
                                        <span onClick={() => this.bohuiSure()}>确认</span>
                                    }
                                </p>
                            </div>
                        </div>
                        : null}
                    {visible2 ?
                        <div className='bohui-outer'>
                            <div className='bohui'>
                                <p className='customstyle title'>请输入密码</p>
                                <p className='content'>
                                    <span className='customstyle'>密码：</span>
                                    <input className='customstyle' id='iosinput' type='password' placeholder='请输入密码'
                                           maxLength={20} defaultValue={password}
                                           onChange={(e) => this.passwordValue(e)}/>
                                </p>
                                <p className='content-jizhu'>
                                    <input type='radio' checked={knowcode} onClick={() => this.setState({
                                        knowcode: !knowcode
                                    })}/>
                                    <span className='customstyle'>记住密码，下次无需输入密码</span>
                                </p>
                                <p className='button'>
                                <span className='customstyle' onClick={() => {
                                    this.setState({
                                        visible2: false,
                                        password: ''
                                    })
                                }}>取消</span>
                                    <span className='customstyle' onClick={() => this.passwordSure()}>确认</span>
                                </p>
                            </div>
                        </div>
                        : null}
                    
                    {flog == 2 ? <p className='chat-center-outer'>
                        <SpinLoading/>
                        {/*<p>跳转中...</p>*/}
                    </p> : null}
                </Modal>

            </>
        )
    }
}
