
import React from 'react'
import cls from 'classnames'

import './style.less'

export default  function Tag(props) {

    const {text='',w=46,type='frame',maincolor='#1F87FF',subcolor='#E0EEFF',active=true}=props
    return(
        <>
            {type==='frame'?
                <div
                className={cls(`base-tab-${type}`,'flex-row')} 
                style={{border:`1px solid ${maincolor}`,background:`${subcolor}`,width:`${w}`}}
                >
                    <span style={{color:`${maincolor}`}}>{text}</span>
                </div>:null
            }
            {type==='radius'?(
                active?
                <div
                className={cls(`base-tab-${type}`,'flex-row',{'tab-active':true,'tab-dark':false})} 
                style={{background:`${maincolor}`,width:`${w}`}}
                >
                    <span>{text}</span>
                </div>
                :
                <div
                className={cls(`base-tab-${type}`,'flex-row',{'tab-active':false,'tab-dark':true})} 
                style={{color:`${maincolor}`,border:`1px solid ${maincolor}`,width:`${w}`}}
                >
                    <span>{text}</span>
                </div>
                ):null
            }
            {type==='vip'||type==='discount'||type==='activity'?
                <div
                className={cls(`base-tab-${type}`,'flex-row')} 
                >
                    <span>{text}</span>
                </div>:null
            }
        </>

        
    )
    
}

