import React, { Component } from 'react'
// import { Modal } from "antd-mobile-v5"
import "./style.less"
import { List, WhiteSpace, Modal, Toast } from 'antd-mobile'
import icon1 from "../../../static/img/yy/fanhuihei.png"
const alert = Modal.alert
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            patientPhone: "",
            boxcode:false,
            doctorId:localStorage.getItem("pharmacistId"),
            value1:"",
            value2:""
        }
    }
    componentDidMount = () => {

        this.setState({
            patientPhone: localStorage.getItem("pharmacistPhone")
        })
    }
    clearuser = () => {
        localStorage.clear()
        this.props.history.push("/login")
    }
    verification=()=>{
        const {value1,value2} =this.state

    }
    changevalue=(e,code)=>{
        if (code==1) {
            this.setState({
                value1:e.target.value
            })
        }else{
            this.setState({
                value2:e.target.value
            })
        }
    }
    outer = () =>{
        alert('确认退出登录吗？', '', [
            { text: '取消', onPress: () => console.log('cancel') },
            {
                text: '确认', onPress: () => {
                    localStorage.removeItem("pharmacistName")
                    localStorage.removeItem("pharmacistAvatar")
                    localStorage.removeItem("pharmacistId")
                    localStorage.removeItem("pharmacistPhone")
                    localStorage.removeItem("item")
                    localStorage.removeItem("caPassTime")
                    this.props.history.push("/login")
                }
            },
        ])
    }
    //清缓存
    clearall=()=>{
        window.localStorage.clear();
        this.props.history.push("/login")
    }
    //账户安全
    toSafe=()=>{
        const {doctorId}=this.state
        if (doctorId){
            this.props.history.push("/mine/set/security")
        }else{
            this.props.history.push("/login")
        }
    }
    render() {
        const { patientPhone,boxcode,value1,value2,doctorId } = this.state
        return (
            <div className='setbox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push("/mine")} />   设置
                </div>
                <div className='box-num'>
                    <div>
                        版本号
                    </div>
                    <div>V1.0.0.0</div>
                </div>
                <div className='box-item'>
                    <div>关于产品</div> <div> <span>＞</span></div>
                </div>
                <div className='box-item'>
                    <div>隐私</div> <div> <span>＞</span></div>
                </div>
                <div className='box-huancun' onClick={() => this.clearall()}>
                    <div>
                        <p>清除缓存</p>
                        <p>包括：图片、视频、搜索历史</p>
                    </div>
                    <div>＞</div>
                </div>
                <div className='box-item box-safe'>
                    <div>账户信息与安全</div> <div onClick={() => this.toSafe()}> <span>＞</span></div>
                </div>
                {/* <div className='box-id'>
                    <div>
                        <span>账号实名认证</span><br />
                        <span>确保未成年人信息和就医安全，应进行账号<br />实名认证。</span>
                    </div>
                    <div> <button onClick={()=>{
                        this.setState({
                            boxcode:true
                        })
                    }}>立即认证</button></div>
                </div> */}
                {boxcode?<div className='abbox'>
                    <div className='abbox-text'>
                        <h2>安全验证</h2>
                        <p className='text-title'>根据国家法律规定，因涉及处理儿童个人信息，请您验证监护人账号资料。</p>
                        <div className='text-input'>
                            <input type="text" placeholder='输入监护人姓名' value={value1} onChange={(e)=>this.changevalue(e,1)}/>
                            <input type="text" placeholder='输入监护人身份证号码' value={value2} onChange={(e)=>this.changevalue(e,2)}/>
                        </div>
                        <div className='text-bottom'>
                            <button onClick={()=>this.setState({
                                boxcode:false
                            })}>取消</button><button onClick={this.verification}>验证</button>
                        </div>
                    </div>
                </div>:null}
                
                {/* <div className='box-item'>
                    <div>手机号</div> <div>{patientPhone}</div>
                </div>
                <div className='box-item'>
                    <div>微信公众号</div> {patientPhone ?
                        <div>已绑定 <span style={{ color: "rgb(72, 226, 72)" }} onClick={this.clearuser}>点击解绑</span></div> :
                        <div>未绑定 <span style={{ color: "rgb(72, 226, 72)" }} onClick={() => this.props.history.push("/login")}>点击绑定</span></div>}
                </div> */}
                {doctorId?<div className='tuichu' onClick={this.outer}>
                    退出
                </div>:null}
            </div>
        )
    }
}
