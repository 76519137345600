import { axiosInstance } from '../config'
//处方单列表
export const prescribingList = (docterId,page,size,isPha) => {
    return axiosInstance.get(`/api/hosp/wzpharmacist/prescribing-list?docterId=${docterId}&page=${page}&size=${size}&isPha=${isPha}`)
}
// 药师审方
export const qualificationsMedExamine = (data) => {
    return axiosInstance.post(`/api/hosp/wzpharmacist/qualifications-med-examine`,data)
}
//查询pdf处方
export const getPdfprescribing = (data) => {
    return axiosInstance.post(`/api/hosp/wzpharmacist/get-pdf-prescribing`,data)
}
//添加订单药品
export const addOrderMed = (data) => {
    return axiosInstance.post(`/api/hosp/order-management/add-order-med`,data)
}
//保存通过的处方单
export const newPrescribing = (data) => {
    return axiosInstance.post(`/api/hosp/wzpharmacist/new-prescribing`,data)
}
//更新零售平台订单状态
export const lsupdatestu = (data) => {
    return axiosInstance.post(`/restful/hosp/wzdocter/updatestu`,data)
}
//查询订单状态
export const getOrdersate = (data) => {
    return axiosInstance.post(`/restful/hosp/wzdocter/get-ordersate`,data)
}
//查询商品是否是自提订单
export const getOrderisZiti = (data) => {
    return axiosInstance.post(`/restful/hosp/wzdocter/is-ziti`,data)
}
//查询某一天审核通过的处方单
export const onedayPrescribingnumber = (data) => {
    return axiosInstance.post(`/api/hosp/wzpharmacist/oneday-prescribingnumber`,data)
}
//处方单状态修改+++++
export const updateState = (data) => {
    return axiosInstance.post(`/restful/hosp/wzdocter/update-state`,data)
}
//查询处方直接购买药品的药品详情
export const medDetails = (data) => {
    return axiosInstance.post(`/restful/hosp/wzdocter/med-details`,data)
}
//处方药审核通过直接生成待支付订单
export const generateOrder = (data) => {
    return axiosInstance.post(`/restful/hosp/wzdocter/generate-order`,data)
}
//查询医生ca认证密码是否保存
export const isCapwd = (data) => {
    return axiosInstance.post(`/api/hosp/offline-pharmacy/is_capwd`,data)
}

// 查询待审处方药品listMeds
export const listMeds = (data) => {
    return axiosInstance.post(`/api/hosp/wzpharmacist/all-lists-meds`,data)
}


//药店处方单列表
export const prescribingListStore = (pharmacyId,page,size,isPha,docterId) => {
    return axiosInstance.get(`/api/hosp/wzpharmacist/prescribing-list-store?pharmacyId=${pharmacyId}&page=${page}&size=${size}&isPha=${isPha}&docterId=${docterId}`)
}

