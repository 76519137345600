

// 合并所有reducer 并且返回
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist';
/* 存储机制 */
import storage from 'redux-persist/lib/storage';

import { user } from './user.redux'
import { reducer as cartReducer } from "../application/cart/store/index";

const reducer= combineReducers({
    user,
    cart: cartReducer
})


const myReducer = persistReducer({
    key: 'root', // 必须有的
    storage, // 缓存机制
    // whitelist: ['loginReducer'], // reducer 里持久化的数据,除此外均为不持久化数据
    // blacklist: ['cart', 'pwd'] // reducer 里不持久化的数据,除此外均为持久化数据
}, reducer);


export default myReducer;