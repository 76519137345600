import React, { Component } from 'react'
import "./style.less"
import {  DatePicker} from "antd-mobile-v5"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/doctor/wen.png"
import icon3 from "../../../static/img/doctor/yang.png"
import icon4 from "../../../static/img/doctor/tixian.png"
import Nodata from '../../../component/Nodata'
import {phastatisticsAll,offphaAllnum} from '../../../api/hospitalapi/offline'
import moment from 'moment'
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            docterId: localStorage.getItem("pharmacistId"),
            code: 1,
            dateList: '',
            browseList: '',
            page: 1,
            size: 20,
            countallNum: 0,
            countNum: 0,
            createTime: '',
            prescribingState: '',
            visible: false,
            flag:false
        }
    }

    componentDidMount = async () => {
        const {size, dateList, docterId} = this.state
        let res = await offphaAllnum({docterId})

        if (res && res.data && res.data[0] && res.data[1]) {
            this.setState({
                countallNum: res.data[0][0].countallNum,
                countNum: res.data[1][0].countNum
            })
        }
        this.getAllList()
    }
    resetBrowseList = (data, type) => {
        const {page, dateList, browseList, code} = this.state
        //获取日期
        const year = moment().year() + ''
        const month = moment().month() + 1 < 10 ? '0' + (moment().month() + 1) : '' + (moment().month() + 1)
        const browseDate = year + month
        let newData = data
        let newDateList = [...dateList]
        newData.forEach(item => {
            item.checked = false
            item.createTime0 = item.createTime.slice(0, 4) + '年' + item.createTime.slice(5, 7) + '月'
        })
        for (let i = 0; i < newData.length; i++) {
            // for (let j = i + 1; j < newData.length; j++) {
            //     if (newData[i].createTime === newData[j].createTime) {
            //         ++i
            //     }
            // }
            newDateList.push({
                createTime: newData[i].createTime0,
                allCount: newData[i].allCount,
                successCount: newData[i].successCount
            })
        }
        let obj = {}
        // newDateList = [...dateList, ...newDateList]
        newDateList = [...newDateList]
        newDateList = newDateList.filter((x, index, self) => {
            var arrids = []
            newDateList.forEach((item, i) => {
                arrids.push(item.createTime)
            })
            return arrids.indexOf(x.createTime) === index
        })
        // setDateList(newDateList)
        // setDateList([...newDateList])
        // console.log(browseList, newData)
        // setBrowseList(type === 'get' ? newData : [...browseList, ...newData])
        this.setState({
            dateList: [...newDateList],
            browseList: type === 'get' ? newData : [...browseList, ...newData],
            flag:false
        })
    }
    getAllList = async () => {
        const {size, page, docterId, prescribingState, createTime} = this.state
        const params = {
            docterId,
            page: 1,
            size: size,
            prescribingState: prescribingState ? prescribingState : '',
            createTime: createTime ? createTime : ''
        }
        let res = await phastatisticsAll(params)

        if (res && res.data && res.data[0] && res.data[0].length) {
            let data = res.data[0]
            this.resetBrowseList(data, 'get')
        } else {
            this.setState({
                dateList: [],
                browseList: [],
            })
        }
    }
    // 触底更新数据
    onscroll = e => {
        const { flag,} = this.state
        // console.log(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight)
        if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <10) {
            if (!flag){
                this.pageBrowselist()
            }
        }
    }
    // 触底时换页
    pageBrowselist = () => {
        const {size, page, docterId, prescribingState, createTime} = this.state

        this.setState({
            flag:true
        },()=>{
            const params = {
                docterId,
                size: size,
                page: page + 1,
                prescribingState: prescribingState ? prescribingState : '',
                createTime: createTime ? createTime : ''
            }
            phastatisticsAll(params).then(res => {
        
                if (res.code === 1) {
                    if (res.data && res.data[0] && res.data[0].length > 0) {
                        const data = res.data[0]
                        this.resetBrowseList(data, 'limit')
                        this.setState({
                            page: params.page,
                        })
                    }
                }
            })
        })
    }
    gobank = () => {
        window.history.back()
    }
    selectOne = (v) => {
        if (v == 'all') {
            this.setState({
                page: 1,
                code: 1,
                dateList: [],
                prescribingState: '',
                createTime: ''
            }, () => {
                this.getAllList()
            })
        }
        if (v == 'success') {
            this.setState({
                page: 1,
                code: 2,
                dateList: [],
                createTime: '',
                prescribingState: 1
            }, () => {
                this.getAllList()
            })
        }
    }
    stop=()=>{
        document.body.style.overflow='hidden';
        document.addEventListener("pointerdown",function(e){e.preventDefault();},false);//禁止页面滑动
        document.addEventListener("pointerup",function(e){e.preventDefault();},false);//禁止页面滑动
    }
    render() {
        const {
            code, dateList, browseList, countallNum, visible, createTime,
            countNum
        } = this.state
   
        const now = new Date()
        const now1 = new Date('2022-01')
        // const now2 = new Date('2200-01-01')
        return (
            <div onClick={(e)=>this.stop(e)}>
                {dateList ? <div className='accountbox' onScroll={this.onscroll}>
                    <div className='box-top'>
                        <img src={icon1} alt="" onClick={this.gobank}/><h1>单量统计</h1>
                    </div>
                    <div className='statistics-content'>
                        <div className='statistics-content-top'>
                            <div className='statistics-top-count'>
                                <p>{countNum}</p>
                                <p>当月审方数</p>
                            </div>
                            <p className='statistics-top-line'></p>
                            <div className='statistics-top-count'>
                                <p>{countallNum}</p>
                                <p>累计审方总数</p>
                            </div>
                        </div>
                        <p className='statistics-content-bottom'>
                            <p className='statistics-content-bottom-left'>
                                <span className={code == 1 ? 'selected' : 'unselect'}
                                      onClick={() => this.selectOne('all')}>全部</span>
                                {/*<span className={code==2?'selected':'unselect'} onClick={()=>this.selectOne('success')}>开方成功</span>*/}
                            </p>
                            <p className='statistics-content-bottom-right' onClick={() => this.setState({
                                visible: true
                            })}
                            >
                                <span>{createTime ? createTime : '日期选择'}</span>
                                <i className='iconfont icon-xiala'/>
                            </p>
                        </p>
                    </div>
                    {dateList.length > 0 ?
                        <div className='statistics-all-outer'>
                            {dateList.map((item, index) => {
                                return (
                                    <div key={index} className='statistics-all-details'>
                                        <div className='statistics-all-details-date'>
                                            <p>{item.createTime}</p>
                                            <p>（当月审方：{item.allCount}）</p>
                                        </div>
                                        {browseList.map((v, i) => {
                                            return (item.createTime === v.createTime0 ?
                                                    <div key={i}>
                                                        <div className='statistics-all-details-news'>
                                                            <p className='name-count'>
                                                                <span>{v.patientName}</span>
                                                                <span className='add'>+1</span>
                                                            </p>
                                                            <p className='pass-date'>
                                                                {v.prescribingState == 1 ?
                                                                    <span className='pass'>【处方审核通过】</span> :
                                                                    v.prescribingState == -1 ?
                                                                        <span className='nopass'>【处方审核驳回】</span> : null
                                                                }
                                                                <span>{v.createTime}</span>
                                                            </p>
                                                        </div>
                                                    </div> : null
                                            )
                                        })}
                                    </div>
                                )
                            })
                            }
                        </div> : <Nodata type='单量统计'/>
                    }
                    <DatePicker
                        // title='时间选择'
                        visible={visible}
                        onClose={() => {
                            this.setState({
                                visible: false,
                            })
                        }}
                        defaultValue={now}
                        max={now}
                        min={now1}
                        precision='month'
                        onConfirm={value => {
                            const num2 = new Date(now).getFullYear() + '-' + ((new Date(now).getMonth() + 1) > 9 ? (new Date(now).getMonth() + 1) : '0' + (new Date(now).getMonth() + 1))
                            // if (time20) {
                            const num = new Date(value).getFullYear() + '-' + ((new Date(value).getMonth() + 1) > 9 ? (new Date(value).getMonth() + 1) : '0' + (new Date(value).getMonth() + 1))
                            this.setState({
                                page: 1,
                                dateList: [],
                                createTime: new Date(value).getFullYear() + '-' + ((new Date(value).getMonth() + 1) > 9 ? (new Date(value).getMonth() + 1) : '0' + (new Date(value).getMonth() + 1))
                            }, () => {
                                this.getAllList()
                            })
                        }}
                    />
                </div> : null}
            </div>

        )
    }
}
