import { axiosInstance } from '../config'
//保存openid
export const addOpenid = (data) => {
    return axiosInstance.post(`/api/hosp/offline-pharmacy/add-openid`,data)
}
//医生取消记住密码
export const removeCapwd = (data) => {
    return axiosInstance.post(`/api/hosp/offline-pharmacy/remove-ca-pwd`,data)
}
//查询医生对应时间段的处方单价格
export const prescribingPrice = (data) => {
    return axiosInstance.post(`/api/hosp/offline-pharmacy/prescribing-price`,data)
}
// 药师单量统计	/api/hosp/erp-base/pha-statistics-all
export const phastatisticsAll = (data) => {
    return axiosInstance.post(`/api/hosp/offline-pharmacy/pha-statistics-all`,data)
}
// 统计线下药师医生审方总数和当月审方总数
export const offphaAllnum = (data) => {
    return axiosInstance.post(`/api/hosp/offline-pharmacy/offpha-allnum`,data)
}
// /查询处方单是否已被修改
export const isEdit = (data) => {
    return axiosInstance.post(`/api/hosp/offline-pharmacy/is-edit`,data)
}
//药师审方
export const phaSignature = (data) => {
    return axiosInstance.post(`/faas/hosp/doctor-prescription/pha-signature`,data)
}
//更新药师审方时的时间戳
export const updatephaSignaturetime = (data) => {
    return axiosInstance.post(`/api/hosp/doctor-prescription/update_pha_signature_time`,data)
}


// 查询审核通过处方所属店铺belongStore
export const belongStore = (data) => {
    return axiosInstance.post(`/api/hosp/wzpatient/belong-store`,data)
}


// 插入店员id和店铺药师id
export const updateEprescrib = (data) => {
    return axiosInstance.post(`/api/hosp/wzpatient/update-epresid`,data)
}

// 查询店铺对应的店员
export const selectPharmaIdStaff = (data) => {
    return axiosInstance.post(`/api/hosp/wzpatient/select-pharmaid-staff`,data)
}

// 查询店铺对应的药店药师
export const selectPharmaIdPha = (data) => {
    return axiosInstance.post(`/api/hosp/wzpatient/select-pharmaid-pha`,data)
}


//店员和药店药师签名
export const phastaffSignature = (data) => {
    return axiosInstance.post(`/faas/hosp/doctor-prescription/staff-signature`,data)
}

// 查询医生ca认证到期是否给出提示
export const caIsNotify = (data) => {
    return axiosInstance.post(`/api/hosp/wzdocter/select-cais-notify`,data)
}

// 更新到期未认证我已知晓状态dealyKnown
export const becomeDue = (data) => {
    return axiosInstance.post(`/api/hosp/wzdocter/become-due`,data)
}

// 查询医生是否知晓ca到期未认证selectBecomeDue
export const selectBecomeDue = (data) => {
    return axiosInstance.post(`/api/hosp/wzdocter/select-become-due`,data)
}

// 修改医生是否知晓ca到期未认证selectBecomeDues
export const selectBecomeDues = (data) => {
    return axiosInstance.post(`/api/hosp/wzdocter/update-become-due`,data)
}

// 查询所有限制级药品 selectLimitMeds
export const selectLimitMeds = (data) => {
    return axiosInstance.post('/api/hosp/wzpharmacist/select-alllimit-meds', data)
}
    
// 查询所有禁用药品 selectForbiddenMeds
export const selectForbiddenMeds = (data) => {
    return axiosInstance.post('/api/hosp/wzpharmacist/select-forbidden-meds', data)
}

// 查询店铺所属权限 selectFiltering
export const selectFiltering = (data) => {
    return axiosInstance.post(`/api/hosp/store-permissions/select-filtering`,data)
}


// 查询药店药师所属店铺 selectPhaStore
export const selectPhaStore = (data) => {
    return axiosInstance.post(`/api/hosp/wzpharmacist/select-pha-store`,data)
}

//药店药师审方
export const phaSigna = (data) => {
    return axiosInstance.post(`/faas/hosp/doctor-prescription/pha-signa`,data)
}

// 查询药店药师是否审核问诊单
export const isPhaExamine = (data) => {
    return axiosInstance.post(`/api/hosp/wzpharmacist/is_pha_examine`,data)
}

// 处方单查询药店药师签名
export const selectPrescPhaSign = (data) => {
    return axiosInstance.post(`/api/hosp/wzpharmacist/select_presc_pha_sign`,data)
}

// 查询平台药师审方人员 
export const selectPlatformPharmacist = (data) => {
    return axiosInstance.post(`/api/hosp/wzpharmacist/select_platform_pharmacist`,data)
}

// 查询属于平台药师还是药店药师  selectPhaOrStore
export const selectPhaOrStore = (data) => {
    return axiosInstance.post(`/api/hosp/wzpharmacist/select_pha_or_store`,data)
}

// 查询药店药师审方数量  getCountPha
export const getCountPha = (data) => {
    return axiosInstance.post('/api/hosp/wzpharmacist/get-count-pha', data)
}


// 查询平台药师待审处方
export const pendingPrescriptionList = (data) => {
    return axiosInstance.post('/api/hosp/wzpharmacist/pending-prescription-list', data)
}

// 查询药店药师待审处方列表
export const pendingPrescriptionListStore = (data) => {
    return axiosInstance.post('/api/hosp/wzpharmacist/pending-prescription-list-store', data)
}


// 查询处方单店员、药店药师签名图
export const selectSignaturePic = (data) => {
    return axiosInstance.post('/api/hosp/wzpharmacist/select-signature-pic', data)
}

// 药店药师审方通过更新调配状态 
export const updateAllocate = (data) => {
    return axiosInstance.post('/api/hosp/wzpharmacist/update-allocate', data)
}

// 查询该账号是否需要展示AI图标 
export const selectIsAiLogo = (data) => {
    return axiosInstance.post('/api/hosp/wzpharmacist/selectIs_ai-logo', data)
}

// 查询该处方单上超量的药品 
export const selectExcessNumber = (data) => {
    return axiosInstance.post('/api/hosp/wzdocter/select-excess-number', data)
}