
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { List, WhiteSpace, Switch,  Toast, InputItem} from 'antd-mobile'
import {Stepper,Modal} from "antd-mobile-v5"

import Head from '../../component/Head'
import StepButon from '../../baseui/stepbutton/index'
import { adressListRequest, updateAdressRequest } from '../../api/request/adress'
import { getDefaultinvoice,addorderRequest, addordermedRequest, delOrderRequest, updateOrderRequest,upOrderStuRequest,changenum,getDefaultname } from '../../api/request/order'
import { updatecartRequest, delCartRequest,pharmacyStorecs ,getStock} from '../../api/request/cart'
import {consumptionWallet,walletBalance} from '../../api/request/wallet'
import {meddetailRequest} from '../../api/request/med'


import './fillorder.less'
import defaultImg from '../../static/img/default.png'
import { zfUrl,code ,code1,code2} from "../../publicurl"
const alert = Modal.alert
const Item = List.Item

function Fillorder(props) {
    //console.log(props);
    const userId = localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3
    const posProvince = localStorage.posProvince ? localStorage.posProvince : '四川省'
    const type=localStorage.type
    const [loading, setLoading] = useState(true)
    const [orderShipping, setorderShipping] = useState('2')
    const [cartArr, setCartArr] = useState(props.location.state)
    const [money, setMoney] = useState(0)
    const [freeFreight, setfreeFreight] = useState(null)
    const [orderFreight, setorderFreight] = useState(null)
    const [address, setAddress] = useState(null)
    const [allAddress, setallAddress] = useState(null)
    const [sumPrice, setSumPrice] = useState(0)
    const [refresh, setRefresh] = useState(false)
    const [pay, setPay] = useState(['微信支付','钱包支付','对公转账'])
    const [pay1, setPay1] = useState('微信支付') //默认选项
    const [balance, setBalance] = useState(0)
    const [visible, setVisible] = useState('none')
    const [visible0, setVisible0] = useState(false)

    const [companyName, setcompanyName] = useState()
    const [bankName, setbankName] = useState()
    const [bankAccount, setbankAccount] = useState()
    const [visible1, setVisible1] = useState('none')
    const [remark, setRemark] = useState('无')
    const [fapiao, setFapiao] = useState(['不要发票','需要发票'])
    const [fapiao1, setFapiao1] = useState('不要发票') //默认选项
    const [invoice, setInvoice] = useState(0)
    const [invoice1, setInvoice1] = useState()
    const [templateName,setTemplateName]=useState()
    const [stockNum, setstockNum] = useState([])

    useEffect(() => {
        if (type=="2") {
            if (props.location.params) {
                setfreeFreight(props.location.params.freeFreight)
                setorderFreight(props.location.params.orderFreight)
            }else{
                props.history.push("/shopcart")
            }
        }
       
        if (cartArr) {
            let companyId=cartArr[0].pharmacy.pharmacyId
            // console.log(cartArr);
            pharmacyStorecs(companyId).then(res=>{
                // console.log(res);
                setcompanyName(res.data[0][0].companyName)
                setbankName(res.data[0][0].bankName)
                setbankAccount(res.data[0][0].bankAccount)
            })
        }
        getDefaultinvoice({userId}).then(res=>{
            if (res.data[0].length>0) {
                setInvoice(1)
                setInvoice1(res.data[0][0])
            }else{
                setInvoice(0)
            }
        })
        getDefaultname({userId}).then(res=>{
            // console.log(res);
            if (res.data[0].length>0) {
                setTemplateName(res.data[0][0].templateName)
            }
        })
    }, [])
    useEffect(() => {
        // console.log(cartArr);
        let params={
            userId:userId
        }
        walletBalance(params).then(res=>{
            //console.log(res)
            let a=res.data[0].length?res.data[0][0].Balance:0
            setBalance(a)
        })
        if (cartArr) {
            // const nowTime=localStorage.setItem('nowTime',cartArr[0].nowTime)
            adressListRequest(userId).then(res => {
               // console.log(res);
                if (res.code === 1 && res.data[0].length > 0) {
                    const data = res.data[0]
                    setallAddress(data)
                    data.forEach((item, index) => {
                        if (item.addressDefault === '1') {
                            setAddress(item)
                        } else if (index === 0) {
                            setAddress(item)
                        }
                    })
                   
                } else  if (res.code === 1 && res.data[0].length == 0) {
                    alert('暂无地址，可选择自提或添加地址', '', [
                        { text: '自取', onPress: () => { setorderShipping('1') } },
                        {
                            text: '去添加', onPress: () => {
                                props.history.push('/mine/adress')
                            }
                        },
                    ])
                }
            })
            
            let sum = 0
            cartArr.forEach(item => {
                setorderShipping(item.pharmacy.orderShipping ? item.pharmacy.orderShipping : '2')
                item.medList.forEach(item1 => {
                    sum += item1.medPrice*item1.medQuantity
                })
            })
            //console.log(sum,cartArr);
            
            if (type=="2") {
                if (sum/100>freeFreight) {
                    setSumPrice(sum)
                }else{
                    setSumPrice(Number(sum) +orderFreight*100)
                    setMoney(orderFreight)
                }
                
            }else{
                setSumPrice(sum)
            }
            
            // if (sum / 100 >= 1000 & sum / 100 < 2000) {
            //     setSumPrice(sum - 20000)
            // } else if (sum >= 200000) {
            //     setSumPrice(sum - 50000)
            // } else {
            //     setSumPrice(sum)
            // }
            //console.log(sum);
            //setSumPrice(sum)
            setLoading(false)
        } else {
            props.history.go(-1)
        }
    }, [cartArr, userId])
    useEffect(() => {
        refresh && setTimeout(() => setRefresh(false))
    }, [refresh])

    const shippingType = (val) => {
        if (val) {
            if (address) {
                setorderShipping('2')
            } else {
                alert('暂无地址，请先添加', '', [
                    { text: '取消', onPress: () => { console.log('取消') } },
                    {
                        text: '去添加', onPress: () => {
                            props.history.push('/mine/adress')
                        }
                    },
                ])
            }
        } else {
            setorderShipping('1')
        }
    }
    const quantity = async(index, val) => {
        const a=await meddetailRequest(cartArr[0].medList[index].medId)
        // console.log(a);
        // setstockNum(a.data[3][0].stockNum)
        // if (val>=parseInt(a.data[3][0].stockNum)) {
        //     Toast.info('库存不足')
        // }else{
            //console.log(index,val);
            let newCartArr = cartArr
            let sumPrice1 = 0
            newCartArr[0].medList[index].medQuantity = val
            // newCartArr[index].cartPrice = val*newCartArr[index].medPrice
            newCartArr[0].medList[index].cartPrice = newCartArr[0].medList[index].medPrice*val
            newCartArr[0].medList.forEach(e => {
                    sumPrice1 += e.medQuantity*e.medPrice
            })
            if (type=="2") {
                if (sumPrice1/100>freeFreight) {
                    setSumPrice(sumPrice1)
                    setMoney(0)
                }else{
                    setSumPrice(Number(sumPrice1) +orderFreight*100)
                    setMoney(orderFreight)
                }
                
            }else{
                setSumPrice(sumPrice1)
            }
            // if (sumPrice1 / 100 >= 1000 & sumPrice1 / 100 < 2000) {
            //     setSumPrice(sumPrice1 - 20000)
            // } else if (sumPrice1 >= 200000) {
            //     setSumPrice(sumPrice1 - 50000)
            // } else {
            //     setSumPrice(sumPrice1)
            // }
            //console.log(sumPrice1);
            //setSumPrice(sumPrice1)
            setCartArr(newCartArr)
            setRefresh(true)
        // }
    }
    useEffect(() => {
        // console.log(cartArr);
        let array=[]
        cartArr[0].medList.map(item=>{
            getStock({medId:item.medId}).then(res=>{
                // console.log(res);
                if (item.medQuantity>parseInt(res.data[0][0].stockNum)) {
                    array.push({name:item.medName,count:parseInt(res.data[0][0].stockNum)})
                }
                setstockNum(array) 
            })
        })
    }, [cartArr])
    // console.log(stockNum);
    const submitOrder =async () => {
        if (stockNum.length>0) {
            setVisible0(true)
        }else{
            const medList = cartArr[0].medList
            const cartIdArr = []
            if (medList[0].cartId) {
                medList.forEach(item => {
                    cartIdArr.push(item.cartId)
                })
            }
            //console.log(invoice==0 && localStorage.type == "2");
            if (invoice==0 && localStorage.type == "2") {
                alert('暂无发票信息，请先添加', '', [
                    { text: '取消', onPress: () => window.history.back() },
                    {
                        text: '去添加', onPress: () => {
                            props.history.push('/mine/storeticket')
                        }
                    },
                ])
            }else{
                    const array=[]
                    cartArr[0].medList.forEach(item=>{
                        array.push({
                            stockNum:-(item.medQuantity),
                            med_id:item.medId
                        })
                    })
                    // console.log(array);
                   
                    const res=await changenum({
                        array
                    })
                    // console.log(res);
                    const params1 = {
                        userId: userId,
                        orderShipping: orderShipping,
                        province: address || orderShipping === '2' ? address.province : null,
                        city: address || orderShipping === '2' ? address.city : null,
                        district: address || orderShipping === '2' ? address.district : null,
                        address: address || orderShipping === '2' ? address.address : null,
                        addressee: address || orderShipping === '2' ? address.addressee : null,
                        phone: address || orderShipping === '2' ? address.phone : null,
                        latitude: address || orderShipping === '2' ? address.latitude : null,
                        longitude: address || orderShipping === '2' ? address.longitude : null,
                        pharmacyId: cartArr[0].pharmacy.pharmacyId,
                        pharmacyName: cartArr[0].pharmacy.pharmacyName,
                        // payMethod: '在线支付',
                        payMethod: localStorage.type == "1"?'微信支付':pay1,
                        orderPrice: sumPrice,
                        orderFreight: 0,
                        orderCoupon: 0,
                        orderIntegral: 0,
                        orderAmount: sumPrice,
                        orderReceipt:localStorage.type == "2"?1:0,
                        order_user_remarks:remark,
                        invoiceId:invoice1?invoice1.invoiceId:null
                    }
                    let params2 = { array: [] }
                    Toast.loading('请稍等...')
                    //console.log(cartArr[0]);
                    if (cartArr[0].pharmacy.orderNumber) {
                        const params = {
                            orderNumber: cartArr[0].pharmacy.orderNumber,
                            orderShipping: orderShipping,
                            province: address || orderShipping === '2' ? address.province : null,
                            city: address || orderShipping === '2' ? address.city : null,
                            district: address || orderShipping === '2' ? address.district : null,
                            address: address || orderShipping === '2' ? address.address : null,
                            addressee: address || orderShipping === '2' ? address.addressee : null,
                            phone: address || orderShipping === '2' ? address.phone : null,
                            latitude: address || orderShipping === '2' ? address.latitude : null,
                            longitude: address || orderShipping === '2' ? address.longitude : null,
                            // payMethod: '在线支付',
                            payMethod: localStorage.type == "1"?'微信支付':pay1,
                            orderPrice: sumPrice,
                            orderFreight: cartArr[0].pharmacy.freight,
                            orderCoupon: 0,
                            orderIntegral: 0,
                            orderAmount: sumPrice,
                            orderReceipt:localStorage.type == "2"?1:0,
                            order_user_remarks:remark,
                            invoiceId: invoice1?invoice1.invoiceId:null,
                            array: []
                        }
                        const orderName = `${medList[0].medName}等`
                        medList.forEach(item => {
                            params.array.push({
                                orderMedId: item.orderMedId,
                                medQuantity: item.medQuantity
                            })
                        })
                        if (pay1=='钱包支付'){
                            if (balance==0) {
                                Toast.info('钱包余额为0，请重新选择支付方式')
                            }else if(sumPrice>balance){
                                alert('钱包余额不足？', '选择钱包支付超出部分将使用微信支付', [
                                    { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
                                    {
                                        text: '确认', onPress: () => {
                                            addordermedRequest(params).then(res => {
                                                //console.log(res);
                                                if (res.code === 1) {
                                                    let params4={
                                                        userId: userId,
                                                        Tag:'药品支出',
                                                        Comments:'钱包支付',
                                                        amount:sumPrice>balance?balance:sumPrice,
                                                        status:sumPrice>balance?0:1,
                                                        tradeno:params.orderNumber
                                                    }
                                                    consumptionWallet(params4).then(data=>{
                                                        //console.log(data);
                                                        if (data.code===1) {
                                                            if (sumPrice>balance) {
                                                                delCartRequest({ cartId: cartIdArr }).then(res => {
                                                                    Toast.hide()
                                                                }).catch(err => {
                                                                    Toast.hide()
                                                                })
                                                                window.location.href = `${zfUrl}/route?client_id=${code}&channel=&client_trade_no=${params.orderNumber}&total_fee=${sumPrice-balance}&timestamp=1599471368819&order_name=${orderName}&sign=&order_extra=`
                    
                                                            }else{
                                                                props.history.push('/mine/order')
                                                            }
                                                        }
                                                    })
                                                }
                                            }).catch(err => {
                                                Toast.hide()
                                            })
                                        }
                                    },
                                ])
        
                            }else{
                                addordermedRequest(params).then(res => {
                                    //console.log(res);
                                    if (res.code === 1) {
                                        let params4={
                                            userId: userId,
                                            Tag:'药品支出',
                                            Comments:'钱包支付',
                                            amount:sumPrice,
                                            status:1,
                                            tradeno:params.orderNumber
                                        }
                                        consumptionWallet(params4).then(data=>{
                                            //console.log(data);
                                            if (data.code===1) {
                                                props.history.push('/mine/order')
                                            }
                                        })
                                    }
                                }).catch(err => {
                                    Toast.hide()
                                })
                            }
                        }else {
                        updateOrderRequest(params).then(res => {
                            //Toast.success(code2)
                           // console.log(res);
                            Toast.hide()
                            if (res.code === 1) {
                                if (pay1=='微信支付') {
                                    delCartRequest({ cartId: cartIdArr }).then(res => {
                                        Toast.hide()
                                    }).catch(err => {
                                        Toast.hide()
                                    })
                                    window.location.href = `${zfUrl}/route?client_id=${code}&channel=&client_trade_no=${params.orderNumber}&total_fee=${sumPrice}&timestamp=1599471368819&order_name=${orderName}&sign=&order_extra=`
                                } else if (pay1=='对公转账'){
                                    // setVisible('block')
                                    upOrderStuRequest({ orderState: '-2', orderNumber: params.orderNumber }).then(res => {
                                        if (res.code==1) {
                                            props.history.push('/mine/order')
                                        }
                                    })
                                }
                            }
                        }).catch(err => {
                            Toast.hide()
                        })
                        }
                    } else {
                        addorderRequest(params1).then(res => {
                            //console.log(res);
                            Toast.hide()
                            if (res.code === 1 && res.data[1]) {
                                const orderNumber = res.data[1][0].orderNumber
                                const orderName = `${medList[0].medName}等`
                                medList.forEach(item => {
                                    params2.array.push({
                                        orderNumber: orderNumber,
                                        medId: item.medId,
                                        medName: item.medName,
                                        medPhoto: item.medPhoto,
                                        medSpec: item.medSpec,
                                        medPacking: item.medPacking,
                                        medApproval: item.medApproval,
                                        medCompany: item.medCompany,
                                        medPrice: item.medPrice,
                                        medQuantity: item.medQuantity
                                    })
                                })
                                if (pay1=='钱包支付'){
                                    if (balance==0) {
                                        Toast.info('钱包余额为0，请重新选择支付方式')
                                    }else if(sumPrice>balance){
                                        alert('钱包余额不足？', '选择钱包支付超出部分将使用微信支付', [
                                            { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
                                            {
                                                text: '确认', onPress: () => {
                                                    addordermedRequest(params2).then(res => {
                                                        //console.log(res);
                                                        if (res.code === 1) {
                                                            let params4={
                                                                userId: userId,
                                                                Tag:'药品支出',
                                                                Comments:'钱包支付',
                                                                amount:sumPrice>balance?balance:sumPrice,
                                                                status:sumPrice>balance?0:1,
                                                                tradeno:orderNumber
                                                            }
                                                            //console.log(sumPrice,balance,params4);
                                                            consumptionWallet(params4).then(data=>{
                                                               // console.log(data);
                                                                if (data.code===1) {
                                                                    if (sumPrice>balance) {
                                                                        delCartRequest({ cartId: cartIdArr }).then(res => {
                                                                            Toast.hide()
                                                                        }).catch(err => {
                                                                            Toast.hide()
                                                                        })
                                                                        window.location.href = `${zfUrl}/route?client_id=${code}&channel=&client_trade_no=${orderNumber}&total_fee=${sumPrice-balance}&timestamp=1599471368819&order_name=${orderName}&sign=&order_extra=`
                            
                                                                    }else{
                                                                        props.history.push('/mine/order')
                                                                    }
                                                                }
                                                            })
                                                            if (cartIdArr.length > 0) {
                                                                delCartRequest({ cartId: cartIdArr }).then(res => {
                                                                    Toast.hide()
                                                                }).catch(err => {
                                                                    Toast.hide()
                                                                })
                                                            }
                                                        } else {
                                                            delOrderRequest(orderNumber).then(res => {
                                                                Toast.hide()
                                                            }).catch(err => {
                                                                Toast.hide()
                                                            })
                                                        }
                                                    }).catch(err => {
                                                        Toast.hide()
                                                    })
                                                }
                                            },
                                        ])
        
                                    }else{
                                        addordermedRequest(params2).then(res => {
                                            //console.log(res);
                                            if (res.code === 1) {
                                                let params4={
                                                    userId: userId,
                                                    Tag:'药品支出',
                                                    Comments:'钱包支付',
                                                    amount:sumPrice,
                                                    status:1,
                                                    tradeno:orderNumber
                                                }
                                                //console.log(sumPrice,balance,params4);
                                                consumptionWallet(params4).then(data=>{
                                                   // console.log(data);
                                                    if (data.code==1) {
                                                        props.history.push('/mine/order')
                                                    }
                                                })
                                                if (cartIdArr.length > 0) {
                                                    delCartRequest({ cartId: cartIdArr }).then(res => {
                                                        Toast.hide()
                                                    }).catch(err => {
                                                        Toast.hide()
                                                    })
                                                }
                                            } else {
                                                delOrderRequest(orderNumber).then(res => {
                                                    Toast.hide()
                                                }).catch(err => {
                                                    Toast.hide()
                                                })
                                            }
                                        }).catch(err => {
                                            Toast.hide()
                                        })
                                    }
                                }else {
                                addordermedRequest(params2).then(res => {
                                    //console.log(res);
                                    if (res.code === 1) {
                                       // console.log(1);
                                        if (pay1=='微信支付') {
                                            delCartRequest({ cartId: cartIdArr }).then(res => {
                                                Toast.hide()
                                            }).catch(err => {
                                                Toast.hide()
                                            })
                                            window.location.href = `${zfUrl}/route?client_id=${code}&channel=&client_trade_no=${orderNumber}&total_fee=${sumPrice}&timestamp=1599471368819&order_name=${orderName}&sign=&order_extra=`
                                        }else if (pay1=='对公转账'){
                                            // setVisible('block')
                                            upOrderStuRequest({ orderState: '-2', orderNumber: orderNumber }).then(res => {
                                                if (res.code==1) {
                                                    props.history.push('/mine/order')
                                                }
                                            })
                                        }
                                        if (cartIdArr.length > 0) {
                                            delCartRequest({ cartId: cartIdArr }).then(res => {
                                                Toast.hide()
                                            }).catch(err => {
                                                Toast.hide()
                                            })
                                        }
                                    } else {
                                        delOrderRequest(orderNumber).then(res => {
                                            Toast.hide()
                                        }).catch(err => {
                                            Toast.hide()
                                        })
                                    }
                                }).catch(err => {
                                    Toast.hide()
                                })}
                            }
                        }).catch(err => {
                            Toast.hide()
                        })
                    }
            }

        }
        // shippingType();
    }
 
    const payWay=(e)=>{
        setPay1(e.target.value)
    }
    useEffect(() => {
        if (pay1=='对公转账') {
            setVisible('block')
        }else{
            setVisible('none')
        }
    }, [pay1])
    const fapiaoUse=(e)=>{
        setFapiao1(e.target.value)
    }
    const fapiaoUse1=()=>{
        localStorage.setItem("cartstatus","1")
        props.history.push('/mine/storeticket')
    }
    //   选择地址
    const changeaddress = (e, item,index) => {
        let medList = allAddress
        let newCartArr = cartArr
        medList[index].checked = e.target.checked
        //console.log(e.target,index,medList[index].checked);
        if(e.target.checked) {
            newCartArr.push({
                medList: [{
                  address: item.address,
                  addressDefault:item.addressDefault,
                    addressee:item.addressee,
                    phone: item.phone,
                    addressId:item.addressId,
                    province:item.province,
                    district:item.district,
                    city:item.city,
                    longitude:item.longitude,
                    latitude:item.latitude,
                    checked: true
                }]
            })
        } else {
            newCartArr.push({
                medList: [{
                  address: item.address,
                  addressDefault:item.addressDefault,
                    addressee:item.addressee,
                    phone: item.phone,
                    addressId:item.addressId,
                    province:item.province,
                    district:item.district,
                    city:item.city,
                    longitude:item.longitude,
                    latitude:item.latitude,
                    checked: false
                }]
            })
        }
        let a=newCartArr[newCartArr.length-1].medList[0]
      setAddress(a)
      setVisible1('none')
    }
    const handleChange=(val)=>{
        setRemark(val)
    }
    const quxiao=()=>{
        setVisible1('none')
    }
    const quxiao1=(e)=>{
        e.stopPropagation()
    }
    return(loading ? null :
        <>
            <Head title='填写订单'></Head>
            <div className='screen fill-screen'>
                <div className='set-adress flexr'>
                    {address?<>
                        {orderShipping === '1'? <div className='no-adress'>自取订单</div> :
                            <div  onClick={()=> setVisible1('block')}>
                                <div className='adress-area flexr'>
                                    {address.addressDefault=='1'?<div>默认</div>:null}
                                    <span>{address.province + address.city + address.district}</span>
                                </div>
                                <span className='adress-street'>{address.address}</span>
                                <div className='adress-person-info'>
                                    <span>{address.addressee}</span>
                                    <span>{address.phone}</span>
                                </div>
                            </div>
                        }
                        {visible1=='block'?
                        <div className='all-address-outer' onClick={()=>quxiao()}>
                            <div className='all-address1'  onClick={(e)=>quxiao1(e)}>
                            {allAddress.map((item, index) => (
                                <div key={index}>
                                    <div className='all-address-in' onClick={(v)=>changeaddress(v,item, index)}>
                                        <input type="radio" name='b' />
                                        <p className='all-address-phone'>
                                            <p className='address'>{item.address}</p>
                                            <p className='name-phone'>
                                                <span>{item.addressee}</span>
                                                <span className='phone'>{item.phone.toString().substr(0,3)}****{item.phone.toString().substr(7,11)}</span>
                                            </p>
                                        </p>
                                    </div>
                                </div>
                                ))}
                            </div>
                        </div>
                        :null}
                    </>:null}
                    <div className='adress-switch'>
                        <div className='flexr'>
                            <div className={orderShipping === '1' ? 'adress-checked' : ''}>自取</div>
                            <div className={orderShipping === '2' ? 'adress-checked' : ''}>外送</div>
                        </div>
                        <Switch
                            className='adress-send-switch'
                            color='#1F87FF'
                            checked={orderShipping === '2'}
                            platform='android'
                            onChange={shippingType} />
                    </div>
                </div>
                <WhiteSpace></WhiteSpace>
                {localStorage.type == "1"?<Item extra={'微信支付'}>支付方式</Item>:
                <div className='pifa-pay'>
                    <p>支付方式</p>
                    <select value={pay1} onChange={(e)=>payWay(e)}>
                        {pay.map((item,index)=>{
                            return(
                                <option key={index}>{item}</option>
                            )
                        })}
                    </select>
                </div>}
                <WhiteSpace></WhiteSpace>
                {/* {cartArr.map((item, i) => { */}
                    {/* return ( */}
                        <div className='cart-part fill-order' 
                            style={{ margin: 0, borderRadius: 0, background: 'white',marginBottom:'10px' }}>
                            {visible=='block'?
                            <div className='dgzz'>
                                <h2>商家对公账户信息</h2>
                                <p>账户名：{companyName}</p>
                                <p>账号：{bankAccount}</p>
                                <p>开户银行：{bankName}</p>
                            </div>:null}
                            {/* <p>{item.pharmacy.pharmacyName}</p> */}
                            {cartArr[0].medList.map((v, index) => {
                                return (
                                    <div key={index} className='fill-order-med'>
                                        <div className='cart-med flex-row'>
                                            <div className='flex-row'>
                                                <img className='fill-order-img' src={v.medPhoto ? v.medPhoto : defaultImg} alt='' onError={(e) => { e.target.onerror = null; e.target.src = defaultImg }} />
                                                <div className='cart-med-detail'>
                                                    <div className='fill-order-name flexc'>
                                                        <p>{v.medName}</p>
                                                        <span>{v.medSpec}</span>
                                                    </div>
                                                    <p className='fill-order-price'>￥{v.medPrice / 100}</p>
                                                </div>
                                            </div>
                                            <span>x{v.medQuantity}</span>
                                            {/* <Stepper
                                                style={{
                                                    '--border-inner': 'none',
                                                    '--border':"none",
                                                    '--button-background-color': '#F8F9FA',
                                                    '--height': '30px',
                                                    '--input-width': '40px',
                                                    '--active-border': '1px solid #1677ff',
                                                    '--button-text-color': type == "2" ? "red" : "blue"
                                                }}
                                                showNumber={true}
                                                min={1}
                                                defaultValue={v.medQuantity}
                                                onChange={(value) => { quantity(index, value) }} 
                                                /> */}
                                        </div>
                                        {index !== cartArr[0].medList.length - 1 ?
                                            <div style={{
                                                height: '1px',
                                                width: '100%',
                                                backgroundColor: '#DDDFE5'
                                            }}></div>
                                            : null}
                                    </div>
                                )
                            })}
                            <Item extra='未使用' arrow='horizontal' onClick={() => { }}>积分</Item>
                            <WhiteSpace></WhiteSpace>
                            {orderShipping === '1' || address === null ? null :
                                // <Item className='price-red'
                                //     extra={`+ ¥${posProvince === address.province ? cartArr[0].pharmacy.freight / 100 : (cartArr[0].pharmacy.freight + 6) / 100}`}>运费</Item>
                                <Item className='price-red'
                                    extra={`+ ¥${money}`}>运费</Item>
                            }
                            <Item className='price-red' extra={'- ¥0'}>优惠券</Item>
                            {localStorage.type != "2"?
                            <div className='pifa-fapiao'>
                                <p>发票</p>
                                <select value={fapiao1} onChange={(e)=>fapiaoUse(e)}>
                                    {fapiao.map((item,index)=>{
                                        return(
                                            <option key={index}>{item}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            :<>
                                {invoice==0?<Item  extra='去选择' arrow='horizontal' onClick={() => fapiaoUse1()}>发票</Item>:
                                <Item  extra={templateName} arrow='horizontal' onClick={() => fapiaoUse1()}>发票</Item>}
                            </>}
                            <InputItem 
                                placeholder='请填写备注信息'
                                onChange={v => handleChange(v)}
                            >订单备注</InputItem>
                            {/* <Item extra={`¥${sumPrice / 100}`}>商品金额</Item> */}
                        </div>
                        {/* ) */}
                {/* })} */}
                {/* <Item extra='无' onClick={() => { }}>优惠券</Item>
                <Item extra='未使用' arrow='horizontal' onClick={() => { }}>积分</Item>
                <WhiteSpace></WhiteSpace>
                <Item extra={`¥${sumPrice / 100}`}>商品金额</Item>
                {orderShipping === '1' || address === null ? null :
                    <Item className='price-red'
                        extra={`+ ¥${posProvince === address.province ? cartArr[0].pharmacy.freight / 100 : (cartArr[0].pharmacy.freight + 6) / 100}`}>运费</Item>
                }
                <Item className='price-red' extra={'- ¥0'}>优惠券</Item>
                <Item extra={<Switch />}>电子发票</Item> */}
            </div>
            <div className='fillorder-warn'>发货后非质量问题不予退货</div>
            <StepButon name='提交预约' onclick={submitOrder}>
                <span>合计：￥{sumPrice / 100}</span>
            </StepButon>
            <Modal
                visible={visible0}
                content={stockNum.map(item=>{
                    return(
                        <p>{item.name+'库存仅剩'+item.count}</p>
                    )
                })}
                closeOnAction
                onClose={() => {
                setVisible0(false)
                }}
                actions={[
                {
                    key: 'confirm',
                    text: '确认',
                },
                ]}
            />
        </>
    )
}

export default withRouter(Fillorder)