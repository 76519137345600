
import React, { Component } from 'react'
import icon1 from "../../../static/img/yy/fanhuihei.png"
import { Badge, SideBar } from 'antd-mobile-v5'
import { getdepartmemntlist } from "../../../api/hospitalapi/department"
import "./style.less"
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            list: [
            ],
            activeKey: "1"
        }
    }
    componentDidMount = async () => {
        const res = await getdepartmemntlist()
        let list = res.data[0]
        list = list.map(item => {
            return {
                key: item.dep_id+"",
                title: item.dep_name
            }
        })
        //console.log(list);
        this.setState({
            list,
            activeKey: list[0].key+""
        })
    }
    setActiveKey = (activeKey) => {
        this.setState({
            activeKey
        })
    }
    render() {
        const { list, activeKey } = this.state
        return (
            <div className='typebox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push("/")} /><h1>全部科室</h1>
                </div>
                <div style={{ display: 'flex' }}>
                    <div>
                        <SideBar activeKey={activeKey} onChange={(activeKey) => this.setActiveKey(activeKey)}>
                            {list.map(item => (
                                <SideBar.Item key={item.key} title={item.title} />
                            ))}
                        </SideBar>
                    </div>
                    <div>
                        <div>A</div>
                    </div>
                </div>
            </div>
        )
    }
}

