
import produce from "immer"

import * as actionTypes from './constants';

export default produce((draft=[],action)=> {
    switch(action.type){
        case actionTypes.GET_USER_CART:  // 获取用户购物车商品
            return {...action.userCartData};
        case actionTypes.TOGGLE_SHOP:// 选中或取消选中单个商品
            let alltrue=true
            let shopalltrue=true
            let shopindex=-1
            for(var i=0;i<draft.cartlist.length;i++){
                //循环每个药店
                for(var j=0;j<draft.cartlist[i].medlist.length;j++){
                    //循环每个药店商品
                    const shop=draft.cartlist[i]
                    const med=draft.cartlist[i].medlist[j]
                    if(draft.cartlist[i].medlist[j].medid===action.shopId){
                        med.buy=!med.buy
                        if(med.buy===false){
                            shop.fee=shop.fee-med.cost*med.num
                            shop.sum--
                            draft.fee=draft.fee-med.cost*med.num
                            draft.sum--
                            shop.buy=false
                            draft.buy=false
                            alltrue=alltrue&&false
                        }
                        else{
                            shopindex=i
                            shop.fee=shop.fee+med.cost*med.num
                            shop.sum++
                            draft.fee=draft.fee+med.cost*med.num
                            draft.sum++
                            alltrue=alltrue&&true
                        } 
                    }
                    else{
                        alltrue=alltrue&&med.buy
                    }
                }
            }
            //判断全选以及店铺全选
            if(alltrue){draft.buy=true}
            if(shopindex!==-1){
                draft.cartlist[shopindex].medlist.map(item=>{
                    shopalltrue=shopalltrue&&item.buy
                })
                if(shopalltrue===true){draft.cartlist[shopindex].buy=true}
            }
            return draft;
        case actionTypes.SELECT_ALL_SHOP:// 全选中或取消选中所有商品
            if(!action.seleStatus){
                let fee=0
                let sum=0
                draft.buy=!draft.buy
                draft.cartlist.map(item=>{
                    let shopfee=0
                    let shopsum=0
                    item.buy=draft.buy
                    item.medlist.map(v=>{
                        shopfee=shopfee+v.cost*v.num
                        shopsum++
                        v.buy=draft.buy
                    })
                    item.fee=shopfee
                    item.sum=shopsum
                    fee=fee+shopfee
                    sum=sum+shopsum
                })
                draft.fee=fee
                draft.sum=sum
            }else{
                draft.buy=!draft.buy
                draft.fee=0
                draft.sum=0
                draft.cartlist.map(item=>{
                    item.buy=draft.buy
                    item.sum=0
                    item.fee=0
                    item.medlist.map(v=>{
                        v.buy=draft.buy
                    })
                })
            }
            return draft;
        case actionTypes.SELECT_PART_SHOP://全选或者取消选择店铺所有商品
            let alltrue2=true
            draft.cartlist.map(item=>{
                if(action.shopid===item.shopid){
                    item.buy=!item.buy
                    alltrue2=alltrue2&&item.buy
                    let shopfee=0
                    let shopsum=0
                    if(item.buy){
                        item.medlist.map(v=>{
                            if(!v.buy){
                                shopfee=shopfee+v.cost*v.num
                                shopsum++
                            }
                            v.buy=true
                        })
                        item.fee=item.fee+shopfee
                        item.sum=item.sum+shopsum
                        draft.fee=draft.fee+shopfee
                        draft.sum=draft.sum+shopsum
                    }
                    else{
                        draft.buy=false
                        item.medlist.map(v=>{
                            if(v.buy){
                                shopfee=shopfee+v.cost*v.num
                                shopsum++
                            }
                            v.buy=false
                        })
                        item.fee=item.fee-shopfee
                        item.sum=item.sum-shopsum
                        draft.fee=draft.fee-shopfee
                        draft.sum=draft.sum-shopsum
                    }
                }
                else{
                    alltrue2=alltrue2&&item.buy
                }
            })
            if(alltrue2){draft.buy=true}
            return draft;
        case actionTypes.SETNUM_SHOP://商品数量变更
            draft.cartlist.map(item=>{
                item.medlist.map(v=>{
                    if(v.medid===action.payload.medid){
                        if(v.buy){
                            item.fee=item.fee+(action.payload.num-v.num)*v.cost
                            draft.fee=draft.fee+(action.payload.num-v.num)*v.cost
                        }
                        v.num=action.payload.num
                    }
                })
            })
            return draft;
        case actionTypes.REMOVE_SHOP:
            return draft;
        default:
            return draft;
    }
})

  