
import { axiosInstance } from '../config'

//浏览记录列表
export const browseListRequest = (data) => {
    console.log(data)
    return axiosInstance.get(`/api/hosp/browsing/list?userId=${data.userId}${data.pharmacyId?'&pharmacyId='+data.pharmacyId:''}&page=${data.page}&size=${data.size}`)
}

//删除浏览记录
export const browseDelRequest = (data) => {
    return axiosInstance.post('/api/hosp/browsing/delete', data)
}

//添加浏览记录
export const addBrowsingRequest = (data) => {
    return axiosInstance.post('/api/hosp/browsing/add', data)
}
