
import React from 'react'


export default function FunctionButton(props) {
    const {w=79,h=29,FS=14,basecolor='#353A42',name,onClick}=props
    return(
        <div onClick={onClick}
        className="flex-row"
        style={{width:`${w}px`,height:`${h}px`,fontSize:`${FS}px`,border:`1px solid ${basecolor}`,color:`${basecolor}`,borderRadius:`${h/2}px`}}
        >
            {name}    
        </div>
    )
}