import React, { Component } from 'react'
import FootNav from '../../component/FootNav'
import "./style.less"
import icon1 from "../../static/img/head.png"
import icon2 from "../../static/img/doctor/minepic.png"
import icon3 from "../../static/img/doctor/right.png"
import icon5 from "../../static/img/doctor/books.png"
import icon7 from "../../static/img/doctor/set.png"
import icon8 from "../../static/img/doctor/kefu.png"
import icon4 from "../../static/img/doctor/money.png"
import icon9 from "../../static/img/doctor/suo.png"
import icon10 from "../../static/img/mine/resign.png"
import filingsicon from "../../static/img/filings-icon.png"
import {getuserinfo} from "../../api/hospitalapi/login"
import {Toast,Image } from "antd-mobile-v5"
import {Modal, } from "antd-mobile"
import getUrl from "../../component/GetUrlParam";
import {caAuthentication, caPasstime} from "../../api/hospitalapi/upload";
import moment from "moment";
import {removeCapwd,selectBecomeDue,caIsNotify,selectPhaStore} from "../../api/hospitalapi/offline";
import {dealParams} from "../../api/config";
import {jumpUrl,caUrl,baseUrl} from "../../publicurl";
import '../../static/fonts/font_3834206_aqlrpvgvb1c/iconfont.css'
const { alert } = Modal


export default class index extends Component {
    constructor() {
        super()
        this.state = {
            docterPhone:localStorage.getItem("pharmacistPhone"),
            docterAvatar:localStorage.getItem("pharmacistAvatar"),
            docterName:localStorage.getItem("pharmacistName"),
            docterId:localStorage.getItem("pharmacistId"),
            doctor:'',
            caPassTime: localStorage.getItem("caPassTime")?localStorage.getItem("caPassTime"):'',
            becomeduestate:'',
            visible11:false,
            pharmacyId:localStorage.getItem('pharmacyId'),
            pharmacyName:''
        }
    }
    componentDidMount =async () => {
        const {docterId,docterPhone,caPassTime,pharmacyId,pharmacyName} =this.state
            // 查询药店药师所属店铺

            const res15 = await selectPhaStore({pharmacyId:pharmacyId})
            if(res15 && res15.data && res15.data[0].length){
                this.setState({
                    pharmacyName:res15.data[0][0].abbreviation
                })
            }
            
        let resdue = await selectBecomeDue({docterId:docterId})

        if(resdue.data && resdue.data[0]){
            this.setState({
                becomeduestate:resdue.data[0][0].is_become_due
            })
        }

        if (docterPhone){
            const res=await getuserinfo({
                docterPhone:docterPhone
            })

            if (res&&res.data&&res.data[0]) {
                this.setState({
                    doctor:res.data[0][0]
                })
            }

            // 查询医生ca认证到期是否给出提示
            let res10 = await caIsNotify({
                docterId:docterId
            });
            if (
                res10.data[0][0].is_notify == 1 &&
                caPassTime &&
                caPassTime <= 0
            ) {
                this.setState({
                  visible11:true,
                })  
            }

        }
        if (getUrl('ca', this.props.location.search)&&docterId){
            let res=await caPasstime(docterId)

            if (res&&res.data&&res.data[0]&&res.data[0][0].caPassTime){
                let timeca=moment(res.data[0][0].caPassTime).add(1, 'year').diff( moment(), 'day')
                localStorage.setItem("caPassTime", timeca)
                this.setState({
                    caPassTime:timeca
                })
            }
        }
        // if (docterId) {
        //     if (caPassTime&&caPassTime <= 0) {
        //         alert('身份信息更新', '身份认证有限期已到期，请及时更新', [
        //             {
        //                 text: '忘记密码', onPress: () => {
        //                     let data0 = {
        //                         entityId: docterId,
        //                         busiType: '5',
        //                         redirectUrl: baseUrl+'/faas/hosp/scca/callback',
        //                         redirectPageUrl: jumpUrl+`/index.html#/mine`,
        //                         province: '四川省',
        //                         locality: '德阳市',
        //                         orgName: '云益健互联网医院'
        //                     }
        //                     let {toSign, params} = dealParams(data0)
        //                     console.log(toSign, params)
        //                     caAuthentication({
        //                         url: caUrl,
        //                         toSign,
        //                         FormData: params
        //                     }).then(data => {
        //                         if (data && data.data && data.data.success == false) {
        //                             Toast.show({
        //                                 content:data.data.result_msg,
        //                                 duration:1000
        //                             })
        //                         } else if (data && data.data) {
        //                             window.location.href =data.data.body
        //                         }
        //                     });
        //                 }
        //             },
        //             {
        //                 text: '确认', onPress: async () => {
        //                     let data0 = {
        //                         entityId: docterId,
        //                         busiType: '3',
        //                         redirectUrl: baseUrl+'/faas/hosp/scca/callback',
        //                         redirectPageUrl: jumpUrl+`/index.html#/mine?ca=${1}`,
        //                         province: '四川省',
        //                         locality: '德阳市',
        //                         orgName: '云益健互联网医院'
        //                     }
        //                     let {toSign, params} = dealParams(data0)
        //                     console.log(toSign, params)
        //                     caAuthentication({
        //                         url: caUrl,
        //                         toSign,
        //                         FormData: params
        //                     }).then(data => {
        //                         if (data && data.data && data.data.success == false) {
        //                             Toast.show({
        //                                 content:data.data.result_msg,
        //                                 duration:1000
        //                             })
        //                         } else if (data && data.data) {
        //                             window.location.href =data.data.body
        //                         }
        //                     });
        //                 }
        //             }
        //         ])
        //     }
        // }
    }
    // outer = () =>{
    //     window.localStorage.clear()
    //     window.location.reload()
    // }
    //个人信息
    toOwn=()=>{
        const { docterId,doctor,becomeduestate,caPassTime }=this.state

        if(caPassTime<=0 && docterId.is_phar_pha == 2){
            Toast.show('您的身份信息已过期，请联系客服解决后使用')
            return
        }
        if (docterId){
            if (doctor.pharmacist_examine=='1'){
                this.props.history.push("/mine/userinfo")
            }else{
                Toast.show({
                    content:"药师尚未入驻成功，无法继续操作",
                    duration:1000
                })
            }
        }else{
            Toast.show({
                content:"当前未登录，请先登录",
                duration:1000
            })
            this.props.history.push("/login")
        }
    }

    //证书管理
    toBook=()=>{
        const { docterId,doctor }=this.state
        if (docterId){
            if (doctor.pharmacist_examine=='1'){
                this.props.history.push("/mine/certificatemanagement")
            }else{
                Toast.show({
                    content:"药师尚未入驻成功，无法继续操作",
                    duration:1000
                })
            }
        }else{
            Toast.show({
                content:"当前未登录，请先登录",
                duration:1000
            })
            this.props.history.push("/login")
        }
    }

//联系客服
    toKefu=()=>{
        const { docterId,doctor }=this.state
        if (docterId){
            // Toast.show({
            //     content:"此功能暂未开通",
            //     duration:1000
            // })
            window.location.href = 'tel://19048012565';
        }else{
            Toast.show({
                content:"当前未登录，请先登录",
                duration:1000
            })
            this.props.history.push("/login")
        }
    }
    //开方密码管理
    toPwd=()=>{
        const { docterId,doctor,becomeduestate,caPassTime }=this.state
        
        if(caPassTime<=0 && docterId.is_phar_pha == 2){
            Toast.show('您的身份信息已过期，请联系客服解决后使用')
            return
        }

        if (doctor.doc_ca_pwd){
            this.setState({
                visible:true,
                code:1
            })
        }else{
            this.setState({
                visible:true,
                code:2
            })
        }
    }
    toSure=async ()=>{
        const { docterId,docterPhone }=this.state
        let res0=await removeCapwd({docterId})

        if (res0.code==1){
            const res=await getuserinfo({
                docterPhone:docterPhone
            })
  
            if (res.data[0].length) {
                this.setState({
                    doctor:res.data[0][0],
                    visible:false,
                })
            }
        }
    }
    //单量统计
    toAccount=()=>{
        const { docterId,doctor }=this.state
        if (docterId){
            if (doctor.pharmacist_examine =='1'){
                this.props.history.push("/mine/account")
            }else{
                Toast.show({
                    content:"药师尚未入驻成功，无法继续操作",
                    duration:1000
                })
            }

        }else{
            Toast.show({
                content:"当前未登录，请先登录",
                duration:1000
            })
            this.props.history.push("/login")
        }
    }
    //重新签名--平台药师
    toResign=()=>{
        const { docterId,doctor,becomeduestate,caPassTime }=this.state

        if(caPassTime<=0 && docterId.is_phar_pha == 2){
            Toast.show('您的身份信息已过期，请联系客服解决后使用')
            return
        }


        if (docterId){
            if (doctor.pharmacist_examine =='1'){
                this.props.history.push("/mine/resign")
            }else{
                Toast.show({
                    content:"药师尚未入驻成功，无法继续操作",
                    duration:1000
                })
            }

        }else{
            Toast.show({
                content:"当前未登录，请先登录",
                duration:1000
            })
            this.props.history.push("/login")
        }
    }
    //重新签名--药店药师
    toResign1=()=>{
        const { docterId,doctor,becomeduestate,caPassTime }=this.state

 
        if (docterId){
            if (doctor.pharmacist_examine =='1'){
                this.props.history.push("/mine/resignpha")
            }else{
                Toast.show({
                    content:"药师尚未入驻成功，无法继续操作",
                    duration:1000
                })
            }

        }else{
            Toast.show({
                content:"当前未登录，请先登录",
                duration:1000
            })
            this.props.history.push("/login")
        }
    }

    defultPwd=(e)=>{
        // e.preventDefault()
        // e.stopPropagation()
        this.setState({
            visible:false,
        })
    }

    // 去人脸认证
    sureBtn=async()=>{
        const {docterPhone,docterId,count}=this.state
        const res = await getuserinfo({
        docterPhone,
        });
        this.setState({
        visible11:false,
        })

        let data0 = {
        entityId: docterId,
        busiType: '3',
        redirectUrl: baseUrl + '/faas/hosp/scca/callback',
        redirectPageUrl: jumpUrl + `/index.html#/news?ca=${1}`,
        province: '四川省',
        locality: '德阳市',
        orgName: '云益健互联网医院'
        }
        let {toSign, params} = dealParams(data0)
 
        caAuthentication({url: caUrl, toSign, FormData: params}).then(data => {
        if (data && data.data && data.data.success == false) {
            Toast.show(data.data.result_msg)
        } else if (data && data.data) {
            window.location.href = (data.data.body)
        }
        });
    }


    render() {
        const {docterPhone,docterAvatar,visible,code,docterId,doctor,visible11,pharmacyId,pharmacyName}=this.state
  
        let tel='19048012565'
        return (
            <div className='minebox'>
                {visible11 && (
                    <>
                    <div className='tipbox'>
                        <div className='alertbox'>
                            <div className='top-box'>身份信息更新提示</div>
                            {/* <p className='tipmsg'>实名认证已到期</p> */}
                            {/* <p className='tipmsg'>请进行人脸认证后使用</p> */}
                            <p className='tipmsg'>您的实名认证已到期，</p>
                            <p className='tipmsg'>请您尽快前往重新进行人脸认证</p>
                            <p onClick={()=> {
                        
                            this.sureBtn()
                            }} className='gourl-two'>去人脸认证</p>
                        </div>
                    </div>
                    </>
                )}
                <div className='box-top'>
                    {/* <img src={!doctor?icon1:doctor.docter_avatar?doctor.docter_avatar:icon1} alt="" /> */}
                    <img src={docterAvatar!=null?docterAvatar:icon1} alt="" />
                    {!doctor?<div onClick={()=>this.props.history.push("/login")}>
                        <h1>
                        请先登录
                        </h1>
                        <p>请先登录</p>
                    </div>:
                    <div>
                        {doctor.docter_name?<>
                            <h1>
                            {doctor.docter_name} <span>/药师</span>
                            {pharmacyId && pharmacyName ? <p className=''>{pharmacyName}</p> : ""}
                            </h1>
                        </>:<h1 style={{marginTop:'20px'}}>{doctor.docter_phone}{pharmacyId && pharmacyName ? <p className=''>{pharmacyName}</p> : ""}</h1>}
                    </div>}
                </div>
                <div className='box-item'  onClick={()=>this.toOwn()}>
                    <img style={{width:"19px",height:"20px"}} src={icon2} alt="" /><p>个人信息</p> <img src={icon3} alt=""  className='item-right'/>
                </div>
                {doctor.is_phar_pha == 1 ? (
                    <div className='box-item'  style={{marginBottom:"20px"}} onClick={()=>this.toResign1()}>
                        <img style={{width:"19px",height:"20px"}} src={icon10} alt="" /><p>重新签名</p> <img src={icon3} alt=""  className='item-right'/>
                    </div>
                ) : (
                    <div className='box-item'  style={{marginBottom:"20px"}} onClick={()=>this.toResign()}>
                        <img style={{width:"19px",height:"20px"}} src={icon10} alt="" /><p>重新签名</p> <img src={icon3} alt=""  className='item-right'/>
                    </div>
                )}
                
                {doctor.is_phar_pha == 1 ? "":  <div className='box-item' style={{marginBottom:"20px"}} onClick={()=>this.toPwd()}>
                    <img style={{width:"19px",height:"20px"}} src={icon9} alt="" /><p>取消记住密码</p> <img src={icon3} alt=""  className='item-right'/>
                </div>
                }
                {doctor.is_phar_pha == 1 ? "":    <div className='box-item' onClick={()=>this.toAccount()}>
                    <img style={{width:"19px",height:"20px"}} src={icon4} alt="" /><p>我的单量统计</p> <img src={icon3} alt=""  className='item-right'/>
                </div>
                }
                <div className='box-item' onClick={()=>this.toBook()}>
                    <img style={{width:"19px",height:"20px"}} src={icon5} alt="" /><p>证书管理</p> <img src={icon3} alt=""  className='item-right'/>
                </div>
                <div className='box-item' onClick={()=>{this.props.history.push("/mine/set")}}>
                    <img style={{width:"19px",height:"20px"}} src={icon7} alt=""/><p>设置</p> <img src={icon3} alt=""  className='item-right'/>
                </div>
                <div className='box-item'>
                    <a href={`tel:19048012565`}>
                    <img style={{width:"19px",height:"20px"}} src={icon8} alt="" /><span>联系客服</span> <img src={icon3} alt=""  className='item-right'/>
                    </a>
                </div>
                {visible?<div className='ca-pwd-outer' onClick={(e)=>this.defultPwd(e)}>
                    <div className='ca-pwd-inner' onClick={(e)=>e.stopPropagation()}>
                        <p className='ca-pwd-tips'>提示</p>
                        {code==1?<>
                                <p>是否取消记住密码？</p>
                                <p>
                                    取消后，开方时需要输入密码，若需要记住密码，请输入密码时勾选【记住密码】。
                                </p>
                                <p className='ca-pwd-button'>
                                    <span onClick={()=>this.setState({visible:false})}>取消</span>
                                    <span onClick={()=>this.toSure()}>确定</span>
                                </p>
                            </>:
                            <>
                                <p>暂未记住密码</p>
                                <p>若需要记住密码，请在输入密码时勾选【记住密码】。</p>
                            </>}
                    </div>
                </div>:null}

                <div className='filings-box'>
                    <div className='filings-first-div'><Image src={filingsicon} width={16} style={{ marginRight:'3px'}} />蜀ICP备2022000233号</div>
                    <div>川公网安备51062302000214</div>
                    <div>《医疗机构执业许可证》登记号：PDY50745151062317A1002</div>
                    <div>电信业务经营许可证号：川B2-20220303</div>
                    <div>《互联网药品信息服务资格证书》：（川）-非经营性-2023-0044</div>
                </div>

                <FootNav></FootNav>
            </div>
        )
    }
}
