import React, { Component } from 'react'
import icon1 from "../../../static/img/yy/fanhuihei.png"
import { Badge, SideBar } from 'antd-mobile-v5'

import { getdepartmemntlist } from "../../../api/hospitalapi/department"
import { videoOrder } from "../../../api/hospitalapi/news"
import "./style.less"

export default class index extends Component {
    constructor() {
        super()
        this.state = {
            docterId: localStorage.getItem("docterId"),
            list:[],
            name:''
        }
    }
    componentDidMount = async () => {
        const {docterId}=this.state
        const res1=await videoOrder({
            docterId,
            consultationType:'3',
            consultationStu:'-1'
        })
        // console.log(res1)
        if (res1&&res1.data){

            this.setState({
                list:res1.data[0]
            })
        }
    }
    docName=(e)=>{
        // console.log(e.target.value)
        this.setState({
            name:e.target.value
        })
    }
    onSearch =async () => {
        const {docterId,name}=this.state
        const res1=await videoOrder({
            docterId,
            consultationType:'3',
            consultationStu:'-1',
            name
        })
        // console.log(res1)
        if (res1&&res1.data){

            this.setState({
                list:res1.data[0]
            })
        }
    }
    toPrescribing=(item)=>{
        // console.log(item)
        localStorage.setItem("item",JSON.stringify(item))
        this.props.history.push('/prescribing/addcf')
    }
    render() {
        const { list, activeKey } = this.state
        return (
            <div className='typebox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push("/")} /><h1>视频开方</h1>
                </div>
                <div className='register-head'>
                    <i className='iconfont icon-suosou' onClick={()=>this.onSearch()}></i>
                    <input type="text" placeholder='搜索用药人姓名、手机号等' onChange={(e)=>this.docName(e)}/>
                </div>
                <div className='videoprescription-body'>
                    {list.length?list.map(item=>(
                        <div className='videoprescription-outer' onClick={()=>this.toPrescribing(item)}>
                            <p className='videoprescription-outer-top'>
                                <span>
                                    <img src={item.patient_avatar}/>
                                    <span>{item.patient_name}</span>
                                </span>
                                <span>{item.create_time}</span>
                            </p>
                            <p className='videoprescription-outer-bottom'>
                                <span>患者手机号：{item.patient_phone}</span>
                            </p>
                        </div>

                    )):null}
                </div>
            </div>
        )
    }
}
