

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk'
import myReducer from './reducer'
const { composeWithDevTools } = require('redux-devtools-extension');
// import { composeWithDevTools } from 'redux-devtools-extension';
const composeEnhancers =
    typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;
 
const enhancer = composeEnhancers(
    applyMiddleware(thunk),
);
 
const store = createStore(myReducer, enhancer);
 

export default store