import { axiosInstance } from '../config'

//医生获取信息列表
export const getnewslist = (data) => {
    return axiosInstance.post('/api/hosp/Consultation/list', data)
}

//医生获取单个病人消息
export const getonenews= (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/get-chatnote', data)
}

//医生获取问诊信息
export const getstatus= (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/get-chatinfo', data)
}

//更改聊天状态
export const changestatus= (data) => {
    return axiosInstance.post('/api/hosp/Consultation/updatestu', data)
}

//添加聊天内容
export const addnews= (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/add-chatnote', data)
}

//获取问诊管理列表
export const getlist = (data) => {
    return axiosInstance.post('/api/hosp/Consultation/list', data)
}

//搜索商品
export const searchgoods = (data) => {
    return axiosInstance.post('/api/hosp/wzdocter/medicine-search', data)
}

//新增电子处方
export const addPrescribing = (data) => {
    return axiosInstance.post('/api/hosp/wzdocter/add-prescribing', data)
}
// 获取用药人信息
export const getPatientMember = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/get-patient-member', data)
}
// 查询处方药品详情
export const allMedicinal = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/all-medicinal', data)
}
// 查询医生判断详情
export const prescribingDetail = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/prescribing-detail', data)
}
// 获取医生头像
export const docAvater = (data) => {
    return axiosInstance.post('/api/hosp/wzdocter/doc-avater', data)
}
// 获取患者病史信息
export const patientxq = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/patientxq', data)
}
// 修改医生接诊状态		
export const updateDoctype = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/update-doctype', data)
}
// 删除问诊消息	
export const delWznews = (consultationId) => {
    return axiosInstance.get(`/api/hosp/wzdocter/del-wznews?consultationId=${consultationId}`)
}
// 查询订单药品详情	
export const getLsdetails = (orderNumber) => {
    return axiosInstance.get(`/restful/hosp/wzdocter/get-lsdetails?orderNumber=${orderNumber}`)
}
//获取当前问诊单状态
export const getStatus = (data) => {
    return axiosInstance.post('/api/hosp/wzdocter/get-status', data)
}
//微信退款
export const refundApply = (data) => {
    return axiosInstance.post('/faas/hosp/register-record/refund-apply', data)
}
//查询视频问诊可开方订单
export const videoOrder = (data) => {
    return axiosInstance.post('/api/hosp/Consultation/video-order', data)
}
//查询问诊类型
export const getConsultationType = (data) => {
    return axiosInstance.post('/api/hosp/Consultation/get-consultation-type', data)
}
//查询当天当前店铺患者提交的问诊
export const nowdayAllorder = (data) => {
    return axiosInstance.post('/api/hosp/offline-pharmacy/nowday-allorder', data)
}

// 查询是否中药问诊单isChineseMeds
export const isChineseMeds = (data) => {
    return axiosInstance.post('/api/hosp/wzdocter/is-chinesemeds-consu', data)
}

//查询用法用量chineseMethod
export const chineseMethod = (data) => {
    return axiosInstance.post('/api/hosp/wzdocter/chinese-method', data)
}


// 查询审核通过处方所属店铺belongStore
export const belongStore = (data) => {
    return axiosInstance.post(`/api/hosp/wzpatient/belong-store`,data)
}

// 插入店员id和店铺药师id
export const updateEprescrib = (data) => {
    return axiosInstance.post(`/api/hosp/wzpatient/update-epresid`,data)
}

// 查询店铺对应的店员
export const selectPharmaIdStaff = (data) => {
    return axiosInstance.post(`/api/hosp/wzpatient/select-pharmaid-staff`,data)
}

// 查询店铺对应的药店药师
export const selectPharmaIdPha = (data) => {
    return axiosInstance.post(`/api/hosp/wzpatient/select-pharmaid-pha`,data)
}

// 查询店铺所属权限 selectFiltering
export const selectFiltering = (data) => {
    return axiosInstance.post(`/api/hosp/store-permissions/select-filtering`,data)
}

// 处方单查询药店药师签名
export const selectPrescPhaSign = (data) => {
    return axiosInstance.post(`/api/hosp/wzpharmacist/select_presc_pha_sign`,data)
}


