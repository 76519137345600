import React, { Component } from 'react'
import "./style.less"
import {Toast,} from "antd-mobile"
import { TextArea,ImageViewer } from "antd-mobile-v5"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/head.png"
import icon3 from "../../../static/img/doctor/right.png"
import { getuserinfo } from "../../../api/hospitalapi/login"
import {changedoctor} from "../../../api/hospitalapi/mine"
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            doctor: {},
            docterProfile:"",
            docterExpert:"",
            list:'',
            docterId:localStorage.getItem("pharmacistId"),
            docterAvatar:localStorage.getItem("pharmacistAvatar"),
            image: {
                url: '',
                visible: false
            },
        }
    }
    componentDidMount = async () => {
        //获取医生信息
        const res = await getuserinfo({
            docterPhone: localStorage.getItem("pharmacistPhone")
        })

        this.setState({
            list:res.data[1][0] ? res.data[1][0].qualification_certificate : res.data[0][0].qualificationcertificateNum
            // list:res.data[1][0] ? res.data[1][0] : res.data[0][0].qualification_certificate
        })
    }
    gobank = () => {
        window.history.back()
    }

    render() {
        const { list,image } = this.state

        const img =list.split(',');
 
        // 获取第一张图片链接
        const image1 = img[0];

        // 获取第二张图片链接
        const image2 = img[1];


        return (
            <div className='userinfobox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={this.gobank} /><h1>证书管理</h1>
                </div>
                <p className='certificatemanagement-text'>执业药师注册证</p>
                <div className='certificatemanagement-img'>
                    
                    {/* <img src={list.pharmacist_register_id} onClick={() => {
                        this.setState({
                            image: {
                                url: list.pharmacist_register_id,
                                visible: true
                            }
                        })
                    }}/> */}

                    <img src={image1} onClick={() => {
                        this.setState({
                            image: {
                                url: image1,
                                visible: true
                            }
                        })
                    }}/>
                    <p></p>
                    <img src={image2} onClick={() => {
                        this.setState({
                            image: {
                                url: image2,
                                visible: true
                            }
                        })
                    }}/>

                </div>
                <ImageViewer
                    image={image.url}
                    visible={image.visible}
                    onClose={() => {
                        this.setState({
                            image: {
                                url: '',
                                visible: false
                            }
                        })
                    }}
                />
            </div>
        )
    }
}
