import React, { Component } from 'react'
import "./style1.less"
import icon1 from "../../static/img/duty/loginduty.png"
import { Toast } from "antd-mobile-v5"
import getUrl from '../../component/GetUrlParam'
import { dutyIshave,loginDuty } from "../../api/hospitalapi/duty"
import { getmd5 } from "../../api/request/user"
import moment, { duration } from 'moment'
import localStorage from 'redux-persist/es/storage'
import {jumpUrl} from '../../publicurl'
import md5 from 'js-md5';

// React.Component.prototype.$md5 = md5;
export default class Loginduty extends Component {
    constructor() {
        super()
        this.state = {
            number: "",
            password: "",

        }
    }
    componentDidMount =async () => {
        const {number,code,caPassTime} =this.state

    }
    getinputvalue = (e) => {
        if (e.target.value.length>11) {
            this.setState({
                number: e.target.value.toString().substr(0,11)
            })
        }else{
            this.setState({
                number: e.target.value
            })
        }
    }
    getcodenum = (e) => {
        this.setState({
            password: e.target.value,

        })
    }

    //点击登录或注册
    dutyLogin= async()=>{
        const {  number, password } = this.state

        if (password) {
            localStorage.setItem("jwtTokenys",'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjIsInVzZXJOYW1lIjpudWxsLCJ1c2VyQWNvdW50IjoiMTIzNDU2IiwiYWNvdW50VHlwZSI6IjEiLCJ1c2VyQXZhdGFyIjpudWxsLCJ1c2VyVmlwIjoiMCIsInByb3ZpbmNlIjpudWxsLCJjaXR5IjpudWxsLCJkaXN0cmljdCI6bnVsbCwiYWRkcmVzcyI6bnVsbCwidXNlckludGVncmFsIjowLCJpYXQiOjE2Nzg3NjQ1NzgsImV4cCI6NDgzMjM2NDU3OH0.cGZ1k4qQx7cE2dEvdDA8NGcDc1ECsb_opz7raNJtnEc');
            //查询值班人员是否存在
            const res0 = await dutyIshave(number)

            if (res0.code == 1) {
                if (res0.data[0].length&&res0.data[0][0].login_type==1) {
                    if (res0.data[0][0].docter_state==1){
                        loginDuty({phone:number,password:md5(password)}).then(res1=>{

                            if (res1&&res1.data&&res1.data[0].length) {
                                localStorage.setItem("dutyPhone", res1.data[0][0].docterPhone)
                                localStorage.setItem("dutyAvatar", res1.data[0][0].docterAvatar)
                                localStorage.setItem("dutyName", res1.data[0][0].docterName)
                                localStorage.setItem("dutyId", res1.data[0][0].docterId)
                                // if (!res0.data[0][0].openid) {
                                    this.props.history.replace("/homeduty")
                                    // window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=` + jumpUrl + `/index.html#/homeduty`
                                // } else {
                                //     this.props.history.replace("/homeduty")
                                //     this.props.history.push("/homeduty")
                                // }
                            }else{
                                Toast.show({
                                    content: "账号或密码错误",
                                    duration: 800
                                })
                            }
                        })
                    }else{
                        Toast.show({
                            content: "该账号已下架，无法继续登录",
                            duration: 800
                        })
                    }
                }else if (res0.data[0].length&&res0.data[0][0].loginType!=1) {
                    Toast.show({
                        content: "该号码已在药师端或医生端注册，无法登录",
                        duration: 800
                    })
                } else{
                    Toast.show({
                        content: "该号码未注册，请联系后台管理员注册后再登录",
                        duration: 800
                    })
                }
            }
        } else {
            Toast.show({
                content: "请输入密码",
                duration: 800
            })
        }
    }

    render() {
        const { number, password } = this.state
        return (
            <div className='Loginduty-outer'>
                <div className='Loginduty-bgc'>
                    <img src={icon1}/>
                </div>
                <div className='loginduty-title-top'>
                    值班人员登录
                </div>
                <div className='loginduty-body'>
                    <p className='loginduty-title'>账号登录</p>
                    <p className='loginduty-phone'>
                        <i className='iconfont icon-shoujihao'/>
                        <input type="number" value={number} onChange={(e) => this.getinputvalue(e)} placeholder='请输入手机号' />
                    </p>
                    <p className='loginduty-phone'>
                        <i className='iconfont icon-mimaguanli'/>
                        <input type="text" placeholder='请输入密码' value={password} onChange={(e) => this.getcodenum(e)} />
                    </p>
                    <button className='loginduty-btn' onClick={this.dutyLogin}>登录</button>
                </div>
            </div>
        )
    }
}
