
import wx from 'weixin-js-sdk'
import { Toast } from 'antd-mobile'
import BMap from 'BMap'

import { getWXconfig } from '../../api/request/weixin'
import { qrcodeRequest } from '../../api/request/med'

const scan = () => {
    wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ['barCode'], // 可以指定扫二维码还是一维码，默认二者都有
        success: function(res) {
            Toast.loading('', 0)
            var result=res.resultStr.split(',')
            qrcodeRequest(result[1]).then(res => {
                Toast.hide()
                // alert(result[1])
                if(res.code === 1 && res.data[0].length > 0) {
                    // alert(res.data[0][0])
                    window.location.href = `//h5.sczyzk.com/index.html#/med/${res.data[0][0].medId}`
                } else {
                    Toast.info('没有查找到药品', 2)
                }
            }).catch(err => {
                Toast.hide()
                Toast.info('请求失败', 1)
            })
        },
        error: function(err) {
            alert('扫描失败::扫描码=' + err)
        }
    })
}

const share = (param) => {

    wx.onMenuShareTimeline({
        title: param.title, // 分享标题
        link: param.link, // 分享链接
        imgUrl: param.imgUrl, // 分享图标
        success: function () { 
            // 用户确认分享后执行的回调函数
        },
        cancel: function () { 
            // 用户取消分享后执行的回调函数
        }
    })
    wx.onMenuShareAppMessage({
        title: param.title, // 分享标题
        desc: param.desc, // 分享描述
        link: param.link, // 分享链接
        imgUrl: param.imgUrl, // 分享图标
        success: function () { 
            // 用户确认分享后执行的回调函数
        },
        cancel: function () { 
            // 用户取消分享后执行的回调函数
        }
    })
}

const getPositon = () => {
    wx.getLocation({
        type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: function (res) {
            var latitude = res.latitude // 纬度，浮点数，范围为90 ~ -90
            var longitude = res.longitude // 经度，浮点数，范围为180 ~ -180。
            var accuracy = res.accuracy // 位置精度
            // console.log(latitude, longitude, accuracy)

            const translateCallback = function (data){
                if(data.status === 0) {
                    var position = data.points[0]
                    // alert(position.lng+" "+position.lat)
                    // 创建地理编码实例      
                    var myGeo = new BMap.Geocoder()
                    // 根据坐标得到地址描述    
                    myGeo.getLocation(new BMap.Point(position.lng, position.lat), function(result){
                        if (result){
                            // alert(result.address)
                            // console.log(result.address)
                            localStorage.posProvince = result.addressComponents.province
                            localStorage.posCity = result.addressComponents.city
                            localStorage.posDistrict = result.addressComponents.district
                            localStorage.longitude = position.lng
                            localStorage.latitude = position.lat
                        }
                    })
                }
            }
            var convertor = new BMap.Convertor()
            var pointArr = []
            pointArr.push(new BMap.Point(longitude,latitude))
            convertor.translate(pointArr, 1, 5, translateCallback)
        }
    })
}

const appLocation = (longitude, latitude) => {
    const translateCallback = function (data){
        if(data.status === 0) {
            var position = data.points[0]
            // alert(position.lng+" "+position.lat)
            // 创建地理编码实例
            var myGeo = new BMap.Geocoder()
            // 根据坐标得到地址描述    
            myGeo.getLocation(new BMap.Point(position.lng, position.lat), function(result){
                if (result){
                    // alert(result.address)
                    // console.log(result.address)
                    localStorage.posProvince = result.addressComponents.province
                    localStorage.posCity = result.addressComponents.city
                    localStorage.posDistrict = result.addressComponents.district
                    localStorage.longitude = position.lng
                    localStorage.latitude = position.lat
                }
            })
        }
    }
    var convertor = new BMap.Convertor()
    var pointArr = []
    pointArr.push(new BMap.Point(longitude,latitude))
    convertor.translate(pointArr, 1, 5, translateCallback)
}

export const wxConfig = (type='scan', param={}, position={lng:116.331398, lat:39.897445}) => {
    const url = `https://pay.sczyzk.com/pay/weixin/h5/config?client_id=1000&url=${encodeURIComponent(document.URL)}`
    const ua = navigator.userAgent.toLowerCase()
    const testUa = regexp => regexp.test(ua)
    const testVs = regexp => ua.match(regexp).toString().replace(/[^0-9|_.]/g, '').replace(/_/g, '.')
    let shell = 'none'
    let shellVs = 'unknow'
    if (testUa(/micromessenger/g)) {
        shell = 'wechat' // 微信浏览器
        shellVs = testVs(/micromessenger\/[\d._]+/g)

        getWXconfig(url).then(res => {
            const data = JSON.parse(res)
            wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来
                appId: data.appId, // 必填，公众号的唯一标识
                timestamp: data.timestamp, // 必填，生成签名的时间戳
                nonceStr: data.nonceStr, // 必填，生成签名的随机串
                signature: data.signature,// 必填，签名，见附录1
                jsApiList: ['scanQRCode','onMenuShareTimeline','onMenuShareAppMessage'] // 必填，需要使用的JS接口列表，
            })
            
            wx.ready(function() {
                if(type === 'scan') {
                    scan()
                } else if(type === 'position') {
                    getPositon()
                } else if(type === 'share') {
                    share(param)
                } else {}
            })
            
            wx.error(function (res) {
                // console.log('res',res)
            })
        })
    } else if (testUa(/qqbrowser/g)) {
        shell = 'qq' // QQ浏览器
        shellVs = testVs(/qqbrowser\/[\d._]+/g)
    } else if (testUa(/ucbrowser/g)) {
        shell = 'uc' // UC浏览器
        shellVs = testVs(/ucbrowser\/[\d._]+/g)
    } else if (testUa(/alipayclient/g)) {
        alert('支付宝浏览器') //支付宝浏览器
    } else if (type === 'appPosition') {
        appLocation(position.lng, position.lat)
    }
}