import React from 'react'

import './style.less'

import img1 from '../../static/img/nodata/1.png'
import xiaoxi from '../../static/img/nodata/xiaoxi.png'
import dingdan from '../../static/img/nodata/dingdan.png'
import xinxi from '../../static/img/nodata/xinxi.png'

const Nodata = ({ type }) => (
  <>
    { (()=>{
      switch(type){
        case '收藏':return (
          <div className='no-data'>
            <img src={img1} alt=''/>
            <p>暂时没有收藏</p>
            
          </div>
          )
        case '网络':return <img src={img1} alt=''/>
        case '购物车':return (
          <div className='no-data'>
            <img src={img1} alt=''/>
            <p>您的购物车太干净了~</p>
            <div className='flex-row'>去逛逛</div>
          </div>
          )
        case '接诊':return (
          <div className='no-data'
          style={{paddingTop:100}}>
            <img src={dingdan} alt=''/>
            <p>亲，还没接诊记录呢~</p>
          </div>
          )
        case '处方':return (
            <div className='no-data'
                 style={{paddingTop:100}}>
              <img src={dingdan} alt=''/>
              <p>亲，还没处方记录呢~</p>
            </div>
        )

        case '消息':return (
          <div className='no-data'>
            <img src={xiaoxi} alt=''/>
            <p>亲，还没消息呢~</p>
          </div>
          )

        case '审方':return (
            <div className='no-data'
                 style={{paddingTop:80,paddingBottom:80}}>
              <img src={dingdan} alt=''/>
              <p>亲，还没处方信息呢~</p>
            </div>
        )
        case '问诊':return (
            <div className='no-data'
                 style={{paddingTop:80,paddingBottom:80}}>
              <img src={dingdan} alt=''/>
              <p>亲，还没问诊信息呢~</p>
            </div>
        )
        case '信息':return (
          <div className='no-data'>
            <img src={xinxi} alt=''/>
            <p>抱歉，暂无相关信息~</p>
          </div>
          )
        case '单量统计':return (
            <div className='no-data'
                 style={{marginTop:'-100px'}}>
              <img src={dingdan} alt=''/>
              <p>亲，还没相关统计信息呢~</p>
            </div>
        )
        default:return null
      }
    }
    )()}
  </>
)

export default Nodata