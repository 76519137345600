
import { axiosInstance } from '../config'

//购物车添加
export const addcartRequest = (data) => {
    return axiosInstance.post('/api/hosp/cart/add', data)
}

//修改购物车列表状态
export const changeState = (data) => {
    return axiosInstance.post('/api/hosp/cart/check-status', data)
}

//修改购物车一个店铺所有商品状态
export const changestoreallState = (data) => {
    return axiosInstance.post('/api/hosp/cart/checkall-status', data)
}

//相同商品加入购物车
export const addcartidentical = (data) => {
    return axiosInstance.post('/api/hosp/cart/addgood', data)
}
//购物车列表
export const cartlistRequest = (userId, pharmacyId=0, medId=0) => {
    
    if (localStorage.type==='2') {
        return axiosInstance.get(`/api/hosp/cart/list?userId=${userId}&pharmacyId=${pharmacyId}`)
    }else{
        if(pharmacyId === 0) {
            console.log(pharmacyId);
            return axiosInstance.get(`/api/hosp/cart/list?userId=${userId}&medId=${medId}`)
        } else {
            return axiosInstance.get(`/api/hosp/cart/goodslist?userId=${userId}&medId=${medId}&pharmacyId=${pharmacyId}`)
        }
    }
}

//购物车商品
export const cartGoodslist = (userId, pharmacyId=1, medId=0) => {
    return axiosInstance.get(`/api/hosp/cart/goodslist?userId=${userId}&medId=${medId}&pharmacyId=${pharmacyId}`)
}

//购物车删除
export const delCartRequest = (data) => {
    return axiosInstance.post('/api/hosp/cart/delete', data)
}

//购物车修改
export const updatecartRequest = (medQuantity, cartId) => {
    return axiosInstance.get(`/api/hosp/cart/update?medQuantity=${medQuantity}&cartId=${cartId}`)
}

//查询购物车
export const querycartRequest = (userId, medId) => {
    return axiosInstance.get(`/api/hosp/cart/query?userId=${userId}&medId=${medId}`)
}
//店铺管理-查询详情
export const pharmacyStorecs = (companyId=1) => {
    return axiosInstance.get(`/api/hosp/pharmacy/storecs?companyId=${companyId}`)
}
//我的店铺-基本信息
export const pharmacyBaseinfo = (pharmacyId) => {
    return axiosInstance.get(`/api/hosp/pharmacy/baseinfo?pharmacyId=${pharmacyId}`)
}

//获取店铺起送金额
export const getstorestartmoney = (data) => {
    return axiosInstance.post(`/api/hosp/pharmacy/get-freight`,data)
}

//获取店铺运费模板
export const getstoreTemplate = (pharmacyId,freightType) => {
    return axiosInstance.get(`/api/hosp/pharmacy/freightlist?pharmacyId=${pharmacyId}&freightType=${freightType}`)
}
// 查询商品库存数
export const getStock = (data) => {
    return axiosInstance.post(`/api/hosp/cart/get-stock`,data)
}