import axios from "axios";
// axios的实例及拦截器配置
export const supervise_url = 'https://202.61.88.184:19200';

export const supervise_data = {
    // 测试
    clientId: '测试',
    appSecret: '测试',
    orgName: '测试',
    orgCode: '测试',
    channelName: '测试',
};

const handleHeader = {'content-type': 'application/json'}


const axiosInstanceSup = axios.create({
    baseURL: supervise_url
});

axiosInstanceSup.interceptors.response.use(
    res => res,
    err => {
        console.log(err, "网络错误");
    }
);

//监管平台
// post获取token方法
export async function postSupervise(url, params) {
    return await new Promise((resolve, reject) => {
        axiosInstanceSup(url, {
            method: 'post',
            data: params,
            header: handleHeader

        }).then(res => {
            if (res.status === 0) {
                reject(res)//失败
            } else {
                resolve(res)//成功
            }
        }).catch(err => reject(err));
    });
}


export {axiosInstanceSup};

