
import icon1 from '../../static/img/med/med01.png'
import icon2 from '../../static/img/med/med02.png'
import icon3 from '../../static/img/med/med03.png'
import icon4 from '../../static/img/med/med04.png'

export const cartlistdata=[
    {
        shopid:'s0001',
        name:'东方大药房',
        medlist:[
            {medid:'m0001',belong:'s0001',title:'复方甘草片',src:icon1,mark:'0.5g*100片',cost:28,num:3},
            {medid:'m0002',belong:'s0001',title:'莲花清瘟胶囊',src:icon2,mark:'0.5g*100片',cost:47,num:5},
        ]
    },
    {
        shopid:'s0002',
        name:'西方大药房',
        medlist:[
            {medid:'m0003',belong:'s0002',title:'复方阿胶浆',src:icon3,mark:'0.5g*100片',cost:40,num:6},
            {medid:'m0004',belong:'s0002',title:'感冒灵颗粒',src:icon4,mark:'0.5g*100片',cost:30,num:2},
        ]
    }

]